import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject } from 'rxjs'
import { AuthorizationRoles, User, UserResponse } from './types/user'
import { LSTime } from 'src/app/services/ls-time.service'

const LOCAL_USER = 'user'
const LOCAL_TOKEN = 'token'

@Injectable({
	providedIn: 'root',
})
export class UserService {
	private _redirectURL: string | null = null
	private userSubject = new BehaviorSubject<User | null>(this.getLocalStorage('user'))
	public user$ = this.userSubject.asObservable()

	constructor(private router: Router, private lsTime: LSTime) {
		this.userSubject.next(this.getUser)
	}

	setRedirectURL(url: string) {
		this._redirectURL = url
	}

	useRedirectURL() {
		const aux = this._redirectURL
		this._redirectURL = null
		return aux
	}

	get isLoggedIn() {
		return Boolean(this.getToken)
	}

	get isAdmin(): boolean {
		const user = this.userSubject.value
		if (!user) {
			return false
		}
		return user.role === AuthorizationRoles.Admin
	}
	get isVenueAdmin() {
		const user = this.userSubject.value
		if (!user) {
			return false
		}
		return user.role === AuthorizationRoles.VenueAdmin
	}

	get isConcierge() {
		const user = this.userSubject.value
		if (!user) {
			return false
		}
		return user.role === AuthorizationRoles.Concierge
	}

	get goHome() {
		const user = this.userSubject.value
		switch (user?.role) {
			case AuthorizationRoles.Admin:
			case AuthorizationRoles.Concierge:
				return '/home'

			case AuthorizationRoles.VenueAdmin:
				if (user.venueIds) {
					return `/venue/${user.venueIds[0]}`
				}
				return ''

			default:
				throw new Error(`Não existe home para esse role ${user?.role}`)
		}
	}

	get getToken(): string | null {
		return this.getLocalStorage('token')
	}

	get getUser(): User | null {
		return this.getLocalStorage('user')
	}

	isVenueEligible(venueId: string | null): boolean {
		if (!venueId) {
			return false
		}
		const user = this.userSubject.value
		return Boolean(user?.venueIds?.some(id => id === venueId))
	}

	setUser(userResponse: UserResponse) {
		const { access_token, ...user } = userResponse
		this.userSubject.next(user)
		this.lsTime.setTime(LOCAL_USER, JSON.stringify(user))
		this.lsTime.setTime(LOCAL_TOKEN, JSON.stringify(access_token.token))
		// localStorage.setItem(LOCAL_USER, JSON.stringify(user))
		// localStorage.setItem(LOCAL_TOKEN, JSON.stringify(access_token.token))
	}

	logout(): void {
		this.userSubject.next(null)
		// localStorage.removeItem(LOCAL_USER)
		// localStorage.removeItem(LOCAL_TOKEN)
		this.lsTime.deleteTime(LOCAL_USER)
		this.lsTime.deleteTime(LOCAL_TOKEN)
		this.router.navigate(['/login'])
		// window.location.reload()
	}

	private getLocalStorage(key: typeof LOCAL_USER | typeof LOCAL_TOKEN) {
		// const value = localStorage.getItem(key)
		const value = this.lsTime.getTime(key)
		if (value) {
			return JSON.parse(value)
		}
		return null
	}
}
