<!-- Add checkin-validated or checkin-rejected class on wrapper div to change its style  -->
<div class="checkin">
	<div class="checkin__col">
		<div class="checkin__row">
			<p class="checkin__name">{{ checkinOrder.client.name }} {{ checkinOrder.client.lastName }}</p>
			<div class="checkin__box" *ngIf="template">{{ text[template] }}</div>
		</div>
		<div class="checkin__row">
			<p class="checkin__text">{{ partySize }}</p>
			<p class="checkin__text">{{ checkinOrder.reservationTime }}</p>
		</div>
		<div class="checkin__origin">
			<img class="checkin__icon" src="assets/icons/internet-mini.svg" />
			Origem: {{ checkinOrder.partner.name }}
		</div>
	</div>
	<div class="checkin__col checkin__col--push-right">
		<div class="checkin__benefits">
			<ng-container *ngFor="let benefit of benefits">
				{{ benefit.name }}
				<ng-container *ngIf="benefit.value.trim()">- {{ benefit.value }} </ng-container>
				<br />
			</ng-container>
		</div>
	</div>
	<div class="checkin__col checkin__col--btns">
		<button
			class="checkin__btn checkin__btn--validated"
			(click)="clickButton('VALIDATED')"
			[disabled]="template"
		>
			Validar
		</button>
		<button
			class="checkin__btn checkin__btn--rejected"
			(click)="clickButton('CHK_CANCEL')"
			[disabled]="template"
		>
			Recusar
		</button>
	</div>
</div>
