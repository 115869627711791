<div class="concierge001">
	<div class="concierge001__infos">
		<p class="concierge001__name">
			{{ userName }}
		</p>
		<p class="concierge001__phone">
			{{ userTel }}
		</p>
		<p class="concierge001__email">
			{{ userEmail }}
		</p>
		<p class="concierge001__password">
			<span class="concierge001__password__label">senha: </span>
			{{ userPass }}
		</p>
	</div>
	<div class="concierge001__edit">
		<button class="concierge001__button button" (click)="editUser(userId)">Editar</button>
	</div>
</div>
