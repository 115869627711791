import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { CreateConciergeAdminService } from './create-concierge-admin.service'

@Component({
	selector: 'app-create-concierge-admin',
	templateUrl: './create-concierge-admin.component.html',
	styleUrls: ['./create-concierge-admin.component.scss'],
})
export class CreateConciergeAdminComponent implements OnInit {
	public buttonDisabled: boolean = true
	public erroPassword: boolean = false
	public loading: boolean = true
	public getIdEdit: any = ''
	public newConcierge: boolean = true
	public titleHeader: string = ''
	public dataEditConcierge: any

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private createConciergeAdminService: CreateConciergeAdminService
	) {
		if (Object.keys(this.activatedRoute.snapshot.params).length == 0) {
			this.newConcierge = false
		} else {
			this.getIdEdit = this.activatedRoute.snapshot.params
			this.getIdEdit = this.getIdEdit.id
			this.newConcierge = true
		}
	}

	ngOnInit(): void {
		if (this.newConcierge) {
			this.titleHeader = 'Editar concierge'
			this.getInfoConciergeAdm(this.getIdEdit)
		} else {
			this.titleHeader = 'Adicionar novo concierge'
		}
	}

	public async getInfoConciergeAdm(id: any) {
		this.buttonDisabled = false
		await this.createConciergeAdminService.getInfoConciergeAdmin(id).subscribe(res => {
			this.dataEditConcierge = res
			this.loading = false
		})
	}

	public infosConciergeAdmin(data: any) {
		if (
			data.email.includes('@') &&
			data.password == data.passwordAgain &&
			data.password != '' &&
			Object.values(data).filter(item => item === '').length == 0
		) {
			this.buttonDisabled = false
		} else {
			this.buttonDisabled = true
		}
	}

	public async save(data: any) {
		if (this.getIdEdit) {
			await this.sendInfosToEditCategory(data, this.getIdEdit)
		} else {
			await this.sendInfosToCreateCategory(data)
		}
		this.router.navigate(['conciergeAdm'])
	}

	public async sendInfosToCreateCategory(data: any) {
		await this.createConciergeAdminService.postInfoConciergeAdmin(data).subscribe(res => {
			this.loading = false
		})
	}

	public async sendInfosToEditCategory(data: any, id: any) {
		await this.createConciergeAdminService.patchInfoConciergeAdminToEdit(data, id).subscribe(res => {
			this.loading = false
		})
	}

	public async remove() {
		await this.removeConcierge(this.getIdEdit)
		setTimeout(() => {
			this.router.navigate(['concierge-admin'])
		}, 300)
	}

	public async removeConcierge(conciergeToRemove: any) {
		await this.createConciergeAdminService.removeInfoConciergeAdmin(conciergeToRemove).subscribe(res => {
			this.loading = false
		})
	}
}
