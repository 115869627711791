import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment as env } from 'src/environments/environment' //ALTERAR VARIÁVEIS PARA PARCEIRO ITAÚ
import { Observable, delay, of } from 'rxjs'
import {
	ReportGiftItau,
	ReportGiftItauConverted,
	ReportItauRedemptionsConverted,
	ReportItauRedemptionsResponse,
} from './report.interface'
import { formatUtil } from 'src/app/services/format.util'
import { formatDateToDDMMYYYY } from 'src/app/services/date.util'

export type Option = {
	icon: string
	name: string
	slug: string
	preview?: boolean
	sendEmail?: boolean
}

export type Sector = {
	title: string
	options: Option[]
}
@Injectable({ providedIn: 'root' })
export class ReportsItauService {
	public sectors: Sector[] = [
		{
			title: 'Canais',
			options: [
				{
					icon: 'tagmeicon tagmeicon-presente',
					name: 'Reservas com vouchers',
					slug: 'reservas-itau',
				},

				// {
				// 	icon: 'tagmeicon tagmeicon-presente',
				// 	name: 'Reservas Itaú Shop (sem vouchers)',
				// 	slug: 'reservas-itau-shop',
				// },
			],
		},
		{
			title: 'Itaú Shop',
			options: [
				{
					icon: 'tagmeicon tagmeicon-presente',
					name: 'Reservas Itaú Shop (sem vouchers)',
					slug: 'reservas-itau-shop',
				},
			],
		},
		{
			title: 'Financeiro',
			options: [
				{
					icon: 'tagmeicon tagmeicon-voucher-mini',
					name: 'Nota fiscal consolidado por restaurante (vouchers, resgate itau shop e gift)',
					slug: 'nota-fiscal-venue',
				},
			],
		},
	]

	public options = this.sectors.flatMap(sector => sector.options)

	constructor(private http: HttpClient) {}

	chooseReportToCall(
		slug: string,
		query: {
			startDate: string
			endDate: string
			partnerId?: string
			filterDate?: string
			venue?: string
		},
		format: 'csv' | 'json' = 'csv'
	): Observable<any> {
		const responseType = (format === 'csv' ? 'blob' : 'json') as any
		switch (slug) {
			case 'reservas-itau':
				return this.http.get(
					`${env.channelsAPI.baseURL}reports/menuPerson/benefits?startDate=${query.startDate}&endDate=${query.endDate}&format=${format}`,
					{ responseType }
				)
			case 'reservas-itau-shop':
				return this.http.get(
					`${env.channelsAPI.baseURL}reports/partner/menu-personnalite?startDate=${query.startDate}&endDate=${query.endDate}&format=${format}`,
					{ responseType }
				)

			case 'nota-fiscal-venue':
				return this.http.get(
					`${env.channelsAPI.baseURL}reports/menuPerson/financial?startDate=${query.startDate}&endDate=${query.endDate}&format=${format}&group=${query.filterDate}`,
					{ responseType }
				)
			default:
				console.warn('Nenhum slug encontrado')
				return of([])
		}
	}

	sendFinancialEmail(body: {
		monthYear: string
		previewDateLimit: string
		notaFiscalDateLimit: string
		venuesToSend: {
			venueId: string
			venueName: string
			qtdWine: number
			qtdMenu: number
			totalValue: string // R$ 100,00 (formatado)
		}[]
	}) {
		const sendgridTemplateId = 'd-4c0993c4ca194cedade8cc7f274858dd '
		return this.http.post(`${env.channelsAPI.baseURL}email/menuPerson/send/financial`, {
			...body,
			sendgridTemplateId,
		})
		return of({
			success: [
				{
					venueId: '12b',
					venueName: 'Bistro',
					message: 'E-mail enviado para: teste1@mail.com, teste2@email.com',
				},
				{
					venueId: '13b',
					venueName: 'Jappa',
					message: 'E-mail enviado para: teste1@mail.com, teste2@email.com',
				},
			],
			failures: [
				{
					venueId: '14b',
					venueName: 'Jappa',
					message: 'Não tem e-mail cadastrado. Sendgrid deu erro.',
					retry: true,
				},
				{
					venueId: '15b',
					venueName: 'Jappa 2',
					message: 'Não tem e-mail cadastrado. Sendgrid deu erro.',
				},
			],
		}).pipe(delay(2000))
	}

	reportsItauGiftAdapter(data: ReportGiftItau): ReportGiftItauConverted {
		return {
			Código: data.code,
			Nome: data.customerFullName,
			email: data.customerEmail,
			//Telefone: data.phone,
			'Valor do Gift': data.giftNominalValue ? formatUtil.formatCurrency(data.giftNominalValue) : '',
			'Nome do Gift': data.gift.name,
			//'Número do Lote': data.lotNumber,
			'Data inicial': formatDateToDDMMYYYY(new Date(data.startDate)),
			'Data de Validade': formatDateToDDMMYYYY(new Date(data.expirationDate)),
			'Valor Bruto Recebido': data.tagmeGrossRevenueValue
				? formatUtil.formatCurrency(data.tagmeGrossRevenueValue)
				: '',
			'Valor Líquido Recebido': data.tagmeNetRevenueValue
				? formatUtil.formatCurrency(data.tagmeNetRevenueValue)
				: '',
			'Valor de Repasse': data.venueTransferValue
				? formatUtil.formatCurrency(data.venueTransferValue)
				: '',
		}
	}

	reportsItauRedemptionAdapter(data: ReportItauRedemptionsResponse): ReportItauRedemptionsConverted {
		const statusMap = {
			CHK_DONE: 'Confirmado',
			VALIDATED: 'Validado',
			CHK_CANCEL: 'Cancelado',
		}
		const intentMap = {
			reservation: 'Reserva',
			checkin: 'Check-In',
		}
		return {
			'ID do Pedido': data._id,
			'ID da Venue': data.venueId,
			Venue: data.venueName,
			Nome: data.name,
			Email: data.email,
			Telefone: data.phone,
			'Quantidade de pessoas': data.partySize,
			'Data do pedido': data.orderDay,
			'Hora do pedido': data.orderTime,
			Origem: intentMap[data.intent],
			Status: statusMap[data.status] || 'Desconhecido',
			'Data da validação': data.validatedDay,
			'Hora da Validação': data.validatedTime,

			'Quantidade de Vouchers': data.menuQuantityRedemption,

			'Código do voucher': data.voucherCode,
			'Preço do Menu': data.menuPrice ? formatUtil.formatCurrency(data.menuPrice) : '',
			'Quantidade do Menu': data.menuQuantity,
			'Valor de repasse do Menu': data.menuTransferValue
				? formatUtil.formatCurrency(data.menuTransferValue)
				: '',

			'Quantidade do Menu Vegetariano': data.menuVegetarianQuantity,
			'Preço do Menu Vegetariano': data.menuVegetarianPrice
				? formatUtil.formatCurrency(data.menuVegetarianPrice)
				: '',
			'Valor de repasse do Menu Vegetariano': data.menuVegetarianTransferValue
				? formatUtil.formatCurrency(data.menuVegetarianTransferValue)
				: '',

			'Quantidade do Vinho': data.wineQuantity,
			'Preço do Vinho': data.winePrice ? formatUtil.formatCurrency(data.winePrice) : '',
			'Valor de Repasse do Vinho': data.wineTransferValue
				? formatUtil.formatCurrency(data.wineTransferValue)
				: '',

			'Valor total de repasse': data.totalTransferValue
				? formatUtil.formatCurrency(data.totalTransferValue)
				: '',
		}
	}
}
