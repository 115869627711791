<div class="page">
	<app-header-master></app-header-master>
	<div class="page__body">
		<div class="wrapper">
			<div class="venue-payment-body__breadcrumb">
				<app-breadcrumb></app-breadcrumb>
			</div>
			<div class="venue-payment-body">
				<app-payment-filter-info></app-payment-filter-info>
				<hr class="venue-payment-body__separator payment-body__separator--full-width" />
				<app-payment-infos [general]="true"></app-payment-infos>
				<hr class="venue-payment-body__separator" />
				<app-payment-infos partner="bradesco"></app-payment-infos>
				<app-payment-infos partner="itau"></app-payment-infos>
				<app-payment-infos partner="santander"></app-payment-infos>
				<app-payment-infos partner="latam"></app-payment-infos>
			</div>
		</div>
	</div>
</div>
