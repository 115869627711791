<div class="modal__content">
	<div class="modal__close" (click)="closeModal()">
		<i class="modal__close__icon tagmeicon tagmeicon-close"></i>
	</div>
	<p class="modal__text">Selecione um estabelecimento (Venue)</p>
	<form [formGroup]="searchForm" (ngSubmit)="handleSearch()">
		<div class="modal__select" [ngClass]="{ opened: hasVenues }">
			<label class="modal__label">
				<input
					class="modal__input"
					type="text"
					name="selectedVenue"
					formControlName="name"
					placeholder="Digite o nome aqui"
				/>
				<i class="modal__icon tagmeicon tagmeicon-buscar" (click)="handleSearch()"></i>
			</label>
			<ul class="modal__select__list">
				<li class="modal__select__item" *ngFor="let item of venues" (click)="clickLastVenues(item)">
					{{ item.name }}
				</li>
			</ul>
		</div>
	</form>
	<ng-container *ngIf="lastVenues">
		<div class="modal__last-viewed" *ngIf="lastVenues.length">
			<i
				class="modal__last-viewed__erase tagmeicon tagmeicon-close"
				(click)="eraseHistory()"
				title="Apagar histórico"
			></i>
			<p class="modal__last-viewed__title">Últimos visualizados</p>
			<ul class="tab001" *ngFor="let item of lastVenues">
				<li class="tab001__item" (click)="clickLastVenues(item)">
					{{ item.name }}
				</li>
			</ul>
		</div>
	</ng-container>
</div>
