import { Injectable } from '@angular/core'
import { BehaviorSubject, forkJoin, map, mergeMap, of, tap } from 'rxjs'
import { Venue } from '../venue-management/venues.interface'
import { VenuesApi } from '../../services/venues.service'

@Injectable({ providedIn: 'root' })
export class VenuesCacheService {
	private venuesCacheSub = new BehaviorSubject<Record<string, Venue>>([])
	public venuesCache$ = this.venuesCacheSub.asObservable().pipe(map(obj => Object.values(obj)))
	constructor(private venuesService: VenuesApi) {}

	selectVenueById(venueId: string) {
		return of(this.venuesCacheSub.getValue()[venueId])
	}

	getVenueById(venueId: string) {
		const foundVenue = this.venuesCacheSub.getValue()[venueId]
		if (foundVenue) {
			return of(foundVenue)
		}

		return this.venuesService.getVenueByIdWithoutPartners(venueId).pipe(
			tap(res => {
				const currentVenues = this.venuesCacheSub.getValue()
				this.venuesCacheSub.next({ ...currentVenues, [res._id]: res })
			})
		)
	}

	addVenuesId(venuesIds: Array<string>) {
		forkJoin(venuesIds.map(venueId => this.venuesService.getVenueByIdWithoutPartners(venueId))).subscribe(
			{
				next: responseArray => {
					for (const venueResponse of responseArray) {
						const currentVenues = this.venuesCacheSub.getValue()
						this.venuesCacheSub.next({ ...currentVenues, [venueResponse._id]: venueResponse })
					}
				},
			}
		)
	}
}
