import { Injectable } from '@angular/core'
import { environment as env } from 'src/environments/environment'
import { HttpClient } from '@angular/common/http'

@Injectable({ providedIn: 'root' })
export class VouchersApi {
	private root = 'vouchers'
	private baseURL = `${env.channelsAPI.baseURL}${this.root}`
	constructor(private readonly http: HttpClient) {}

	importVocuhersJson(importVouchers: ImportIuppVouchers) {
		return this.http.post<any>(`${this.baseURL}/iupp/import/json`, importVouchers)
	}

	importGiftJson(importVouchers: importGiftVoucher) {
		return this.http.post<any>(`${env.giftURL}/voucher/import/json`, importVouchers)
	}

	importVouchersBBI(importVouchers: ImportVouchersBBI) {
		return this.http.post<any>(`${this.baseURL}/bbi/import/json`, importVouchers)
	}

	createIuppVoucher(createVouchers: CreateIuppVoucherDto) {
		return this.http.post<any>(`${this.baseURL}/iupp/create`, createVouchers)
	}
}

export interface ImportIuppVouchers {
	clientsVoucher: {
		name: string
		email: string
		phone: string
		venueId: string
		venueName: string
		price: number
		quantity: number
		bccEmails: string[]
		type: string
	}[]
}

export interface importGiftVoucher extends ImportIuppVouchers {
	giftId: string
}

export interface ImportVouchersBBI {
	vouchersBBI: {
		code: string
		type: string
	}[]
	filename: string
}
export interface CreateIuppVoucherDto {
	name: string
	email: string
	phone: string
	venueId: string
	price: number
	note?: string
}
