import { Component } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { Observable, of } from 'rxjs'
import { DateUtils } from 'src/app/services/date.util'
import { VenuesCacheService } from '../../payment/venue-cache.service'
import { ReportsVenueService } from 'src/app/services/reports-venue-service/reports-venue.service'
import { ReservationProgMenuConverted } from 'src/app/services/reports-venue-service/reports-venue.interfaces'
import { DownloadCsvFromJson } from 'src/app/services/download-csv.service'

@Component({
	templateUrl: './report-orders.page.html',
	styleUrls: ['./report-orders.page.scss'],
})
export class VenueReportsOrdersBradescoPage {
	public venue$!: Observable<any>
	public venueId!: string
	reservations!: ReservationProgMenuConverted[]

	form = this.fb.nonNullable.group({
		startDate: [
			DateUtils.getPreviousMonthDates().startDate.toISOString().substring(0, 10),
			[Validators.required],
		],
		endDate: [
			DateUtils.getPreviousMonthDates().endDate.toISOString().substring(0, 10),
			[Validators.required],
		],
	})

	constructor(
		private activatedRoute: ActivatedRoute,
		private venuesCacheService: VenuesCacheService,
		private fb: FormBuilder,
		private reportsVenueService: ReportsVenueService,
		private downloadCsvFromJson: DownloadCsvFromJson
	) {
		this.venueId = this.activatedRoute.snapshot.paramMap.get('venueId') ?? ''
		this.venue$ = this.venuesCacheService.getVenueById(this.venueId)
	}

	filter() {
		const startDate = this.form.controls.startDate.value
		const endDate = DateUtils.addDay(this.form.controls.endDate.value, 1)

		this.reportsVenueService
			.getReportsProgMenuVenueOrders({
				startDate,
				endDate,
				venueId: this.venueId,
			})
			.subscribe(data => {
				if (data instanceof ArrayBuffer) {
					return
				}
				this.reservations = data
			})
	}

	downloadCSV() {
		const startDate = this.form.controls.startDate.value
		const endDate = this.form.controls.endDate.value
		const timestamp = new Date().getTime()
		this.downloadCsvFromJson.downloadCSV(
			this.reservations,
			`reservas-progmenu-${startDate}-${endDate}-${timestamp}`
		)
	}
}
