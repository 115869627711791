<div cdkDropList (cdkDropListDropped)="drop($event)">
	<ng-container *ngFor="let config of imagesConfig | sortArray : 'order' : 'asc'; let idx = index">
		<div cdkDrag cdkDragRootElement=".drag-handle" [cdkDragDisabled]="uploadedFileCount < 2">
			<div class="container-image">
				<div class="item-image">
					<h1>Original</h1>
					<image-cropper
						*ngIf="config.imageUrl"
						[imageChangedEvent]="config.imageChangedEvent"
						(imageCropped)="imageCropped($event, idx)"
						(loadImageFailed)="loadImageFailed()"
						[imageURL]="config.imageUrl | absolutePath"
						[maintainAspectRatio]="true"
						[aspectRatio]="aspectRatio"
						[resizeToHeight]="heightPartner"
						[resizeToWidth]="widthPartner"
					>
					</image-cropper>
					<image-cropper
						*ngIf="!config.imageUrl"
						[imageChangedEvent]="config.imageChangedEvent"
						(imageCropped)="imageCropped($event, idx)"
						[imageURL]="config.imageUrl | absolutePath"
						(loadImageFailed)="loadImageFailed()"
						[maintainAspectRatio]="true"
						[aspectRatio]="aspectRatio"
						[resizeToHeight]="heightPartner"
						[resizeToWidth]="widthPartner"
						(imageLoaded)="imageLoaded($event, idx)"
					>
					</image-cropper>
				</div>
				<div class="item-image" *ngIf="config.optimizedUrl">
					<h1>Otimizada</h1>
					<img
						*ngIf="config.optimizedUrl"
						[src]="(config.optimizedUrl | absolutePath) + '?timestamp=' + timestamp"
					/>
				</div>
			</div>

			<!-- Ícone de arrasto. Mantenha-o presente mas oculto se não for necessário. -->
			<div
				class="drag-handle"
				cdkDragHandle
				[style.display]="
					config.loadImage && type != 'logo' && uploadedFileCount >= 2 ? 'block' : 'none'
				"
			>
				<i class="tagmeicon tagmeicon-subir-prioridade-bold" style="font-size: 30px"></i>
			</div>

			<div class="box-upload">
				<label style="cursor: pointer">
					<div class="upload" *ngIf="!config.loadImage">
						<i class="tagmeicon tagmeicon-foto upload__icon"></i>
						<input
							style="cursor: pointer"
							type="file"
							(change)="loadImageFromFile($event, idx)"
							class="upload__input"
						/>
					</div>
				</label>
			</div>
			<div style="display: flex; gap: 10px">
				<button
					(click)="clearImage(config.imageUrl)"
					*ngIf="config.loadImage && config.imageUrl"
					class="delete"
				>
					Deletar Imagem
				</button>
				<button (click)="clickCropped(idx)" *ngIf="config.loadImage" class="delete">
					{{
						config.optimizedUrl ? 'Atualizar imagem otimizada' : 'Criar uma nova imagem otimizada'
					}}
				</button>
			</div>
			<br />
		</div>
	</ng-container>
</div>
<button
	(click)="uploadMore()"
	*ngIf="type !== 'logo' && lastImageSelected()"
	class="button button__primary"
	style="margin-right: 20px"
>
	Adicionar mais
</button>
<button class="button button__primary" *ngIf="type !== 'logo'" (click)="sendNewOrder()">Salvar ordem</button>
