import { format } from 'path'

//money
function formatCurrency(value) {
	return value.toLocaleString('pt-BR', {
		style: 'currency',
		currency: 'BRL',
	})
}

//phone
function formatPhone(phone: string) {
	const codArea = phone.substring(2, 4)
	const numberPhone = phone.substring(4)

	return `(${codArea}) ${numberPhone}`
}

export const formatUtil = {
	formatCurrency,
	formatPhone,
}
