<div class="report">
	<div class="page">
		<app-header-master></app-header-master>

		<div class="page__header">
			<div class="page__wrapper wrapper">
				<div class="report__header">
					<div class="report__header__logos">
						<i class="tagmeicon tagmeicon-dinheiro"></i>
					</div>
					<p class="report__header__text">Relatórios Menu Personnalité</p>
				</div>
			</div>
		</div>

		<div class="page__body">
			<div class="page__wrapper wrapper">
				<div class="report__body">
					<div class="report__body__column" *ngFor="let item of sectors">
						<ul class="report__body__list">
							<p class="report__body__list__title">{{ item.title }}</p>
							<li class="report__body__list__item" *ngFor="let itensOptions of item.options">
								<a
									class="report__body__list__item__link"
									(click)="selectSectors(itensOptions.slug)"
								>
									<i class="report__body__list__item__icon {{ itensOptions.icon }}"></i>
									<p class="report__body__list__item__text">
										{{ itensOptions.name }}
									</p>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
