import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'

@Injectable({
	providedIn: 'root',
})
export class CategoryService {
	constructor(private http: HttpClient) {}
	private env = environment

	public getCategorysData(partnerId: string) {
		return this.http.get<any>(`${this.env.channelsAPI.baseURL}categories`, { headers: { partnerId } })
	}
}
