import { Component, HostBinding, Input } from '@angular/core'

type TemplateState = 'rejected' | 'validated'

@Component({
	selector: 'app-checkin-popup',
	templateUrl: './checkin-popup.component.html',
	styleUrls: ['./checkin-popup.component.scss'],
})
export class CheckInPopUp {
	@Input() template: TemplateState = 'rejected'

	text: Record<TemplateState, string> = {
		rejected: 'Check-in recusado com sucesso!',
		validated: 'Check-in validado com sucesso!',
	}

	@HostBinding('style')
	get addProps() {
		const config: Record<TemplateState, any> = {
			rejected: {
				'--bg-color': 'var(--red)',
			},
			validated: {
				'--bg-color': 'var(--green)',
			},
		}

		return config[this.template]
	}
}
