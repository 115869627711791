import { Component, OnInit } from '@angular/core'

@Component({
	selector: 'app-tab001',
	templateUrl: './tab001.component.html',
	styleUrls: ['./tab001.component.scss'],
})
export class Tab001Component implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
