import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { PartnersId } from 'src/shared/util'
import { BenefitService } from '../benefit.service'
import { BenefitDto } from './general-benefit.dto'
import { VenuesManagementService } from '../../venue-management/venues-management.service'
import { ModalService } from 'src/app/components/modalChannels/modal.service'

@Component({
	selector: 'app-general-benefit',
	templateUrl: './general-benefit.component.html',
	styleUrls: ['./general-benefit.component.scss'],
})
export class GeneralBenefitComponent implements OnInit, OnDestroy, AfterViewInit {
	imageSrc: any = ''
	loading = false
	benefitTitle = 'Criar um novo benefício'
	benefitSwitchText = 'Benefício (ligado)'
	benefitId: string | null = null
	venueId: string | null = null
	saved: string = ''
	savedModal = 'savedModal_id'
	loadingModal = 'loading_Id'
	loadingMessage = ''

	benefitForm!: UntypedFormGroup

	subs = new Subscription()

	constructor(
		private benefitService: BenefitService,
		private route: ActivatedRoute,
		private venuesManagementService: VenuesManagementService,
		private router: Router,
		private fb: UntypedFormBuilder,
		private cb: ChangeDetectorRef,
		public modalService: ModalService
	) {}

	ngOnInit(): void {
		this.benefitForm = this.fb.group({
			enabled: [false, Validators.required],
			name: ['', Validators.required],
			description: [''],
			descriptionLong: [''],
			order: [0],
			value: [''],
			transferValue: [''],
			revenueValue: [''],
		})

		this.subs.add(
			this.benefitForm.get('enabled')?.valueChanges.subscribe(bool => {
				this.benefitSwitchText = bool ? 'Benefício (ligado)' : 'Benefício (desligado)'
			})
		)

		this.subs.add(
			this.benefitForm.get('transferValue')?.valueChanges.subscribe(value => {
				if (value && value.indexOf(',') !== -1) {
					this.benefitForm.get('transferValue')?.setValue(value.replace(',', '.'))
				}
			})
		)

		this.subs.add(
			this.benefitForm.get('revenueValue')?.valueChanges.subscribe(value => {
				if (value && value.indexOf(',') !== -1) {
					this.benefitForm.get('revenueValue')?.setValue(value.replace(',', '.'))
				}
			})
		)

		const segments = this.route.snapshot.url
		if (segments.length > 0 && segments[1].path !== 'create-benefit') {
			this.benefitId = this.venuesManagementService.benefitId
			this.venueId = this.venuesManagementService.venueId
			if (!this.venueId) this.router.navigate(['/'])
		}
	}

	ngAfterViewInit(): void {
		if (this.benefitId) {
			this.loadingMessage = 'Carregando Benefício...'
			this.modalService.open(this.loadingModal)
			this.benefitService.getBenefitId(this.benefitId).subscribe({
				next: res => {
					this.modalService.close(this.loadingModal)
					this.updateForm(res)
				},
				error: () => {
					this.modalService.close(this.loadingModal)
				},
			})
			this.benefitTitle = 'Editar o benefício'
		}

		if (this.benefitId && this.venueId) {
			this.benefitTitle = 'Editar benefício (exceção)'
		}
		this.cb.detectChanges()
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	updateForm(benefit: BenefitDto) {
		const exception = benefit.exceptions?.find(obj => obj.venueId === this.venueId)
		this.benefitForm.patchValue({
			enabled: exception?.enabled ?? benefit.enabled,
			name: exception?.name ?? benefit.name,
			description: exception?.description ?? benefit.description,
			descriptionLong: exception?.descriptionLong ?? benefit.descriptionLong,
			value: exception?.value ?? benefit.value,
			transferValue: exception?.transferValue ?? benefit.transferValue,
			revenueValue: exception?.revenueValue ?? benefit.revenueValue,
		})

		if (benefit.type === 'menu' || benefit.type === 'beverage') {
			this.benefitForm.addControl('menuId', this.fb.control(exception?.menuId))
			this.benefitForm.get('menuId')?.setValidators(Validators.required)
			this.benefitForm.updateValueAndValidity()
		}
	}

	saveBenefit() {
		const date = new Date()
		const dateFormatted = new Intl.DateTimeFormat('pt-BR', {
			weekday: 'long',
			day: 'numeric',
			month: 'long',
			hour: 'numeric',
			minute: 'numeric',
		}).format(date)

		// Create New Benefit
		if (!this.benefitId) {
			this.benefitService
				.createBenefit(this.benefitForm.value, PartnersId.BRADESCO)
				.subscribe({ next: () => this.router.navigate(['/benefit']) })
		} // Update general Benefit
		else if (this.benefitId && !this.venueId) {
			this.loadingMessage = 'Atualizando Benefício....'
			this.modalService.open(this.loadingModal)
			this.benefitService.updateBenefit(this.benefitId, this.benefitForm.value).subscribe({
				next: () => {
					this.saved = `salvo em ${dateFormatted}`
					this.modalService.open(this.savedModal)
					this.modalService.close(this.loadingModal)
				},
				error: () => {
					this.saved = 'algum erro aconteceu!'
					this.modalService.open(this.savedModal)
					this.modalService.close(this.loadingModal)
				},
			})
		} // Update exception
		else if (this.benefitId && this.venueId) {
			this.loadingMessage = 'Atualizando Benefício com exceção....'
			this.modalService.open(this.loadingModal)
			const {
				name,
				description,
				descriptionLong,
				enabled,
				value,
				transferValue,
				revenueValue,
				menuId,
			} = this.benefitForm.value
			this.benefitService
				.updateException(this.benefitId, {
					name,
					venueId: this.venueId,
					description,
					descriptionLong,
					enabled,
					transferValue,
					revenueValue,
					value,
					...(menuId ? { menuId } : {}),
				})
				.subscribe(() => {
					this.saved = `salvo em ${dateFormatted}`
					this.modalService.open(this.savedModal)
					this.modalService.close(this.loadingModal)
				})
		}
	}
}
