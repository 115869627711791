<app-header-master></app-header-master>
<section class="wrapper">
	<div class="spacer"></div>
	<h1 *ngIf="selectedVenue"><span style="font-weight: 400">Nome: </span>{{selectedVenue.name}}</h1>
	<h1 *ngIf="selectedVenue" style="margin-top: 5px">
		<span style="font-weight: 400">venueId:</span> {{selectedVenue._id}}
	</h1>
	<div class="spacer"></div>
	<div style="display: flex; gap: 12px">
		<button
			style="padding: 12px"
			class="button button__confirm--inverese"
			(click)="openAreYouSureModal(actions.users)"
		>
			Atualizar Usuários
		</button>
		<button
			style="padding: 12px"
			class="button button__confirm--inverese"
			(click)="openAreYouSureModal(actions.venueNames)"
		>
			Atualizar Endereço, Nome, Localização
		</button>
	</div>
</section>

<ch-modal [id]="modal.areYouSureId" [preventOutsideClickClose]="true">
	<app-modal-simple
		[message]="areYouSureMessage"
		template="choose"
		(cancelClicked)="modalService.close(modal.areYouSureId)"
		(confirmClicked)="doAction()"
	></app-modal-simple>
</ch-modal>

<ch-modal [id]="modal.searchId" top="150px" [preventOutsideClickClose]="true">
	<ng-container [formGroup]="searchForm">
		<app-modal-venue
			[hasVenues]="hasVenues"
			[venues]="venues"
			[lastVenues]="lastVenues$ | async"
			(onCloseModal)="closeModalVenue()"
			(onClickVenue)="clickVenues($event)"
			(onEraseHistory)="eraseHistory()"
		></app-modal-venue>
	</ng-container>
</ch-modal>

<ch-modal [id]="modal.successId">
	<app-modal-simple [id]="modal.successId" [message]="successMessage" template="goBack"></app-modal-simple>
</ch-modal>

<ch-modal [id]="modal.errorId" [preventOutsideClickClose]="true">
	<app-modal-simple [id]="modal.errorId" [message]="errorMessage" template="goBack"></app-modal-simple>
</ch-modal>
