import { Component, Input } from '@angular/core'

type PossibleIcons = 'voucher-mini' | 'pin-24' | 'garfo-faca-mini'
export type Card = {
	icon: PossibleIcons
	name: string
	link: string
}

@Component({
	selector: 'app-generic-card',
	templateUrl: './generic-card.component.html',
	styleUrls: ['./generic-card.component.scss'],
})
export class GenericCardComponent {
	@Input() icon!: PossibleIcons
	@Input() link!: string
}
