import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
@Injectable({
	providedIn: 'root',
})
export class WaitlistAdminService {
	constructor(private http: HttpClient) {}
	private env = environment

	public getVenuesData(nameVenue: any) {
		return this.http.post(`${this.env.channelsAPI.baseURL}venues/filter`, {
			partner: '595166383625c506d4868f28',
			name: nameVenue,
			venuesAlreadyInView: [],
		})
	}

	public getInfoVenue(idVenue: any) {
		return this.http.post(`${this.env.channelsAPI.baseURL}venues/${idVenue}`, {
			partner: '595166383625c506d4868f28',
		})
	}
}
