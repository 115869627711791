import { Component, OnInit } from '@angular/core'
import { ReservationService } from '../reservation/reservation.service'
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs'
import { ReservationAdminService } from './reservation-admin.service'

@Component({
	selector: 'app-reservation-admin',
	templateUrl: './reservation-admin.component.html',
	styleUrls: ['./reservation-admin.component.scss'],
})
export class ReservationAdminComponent implements OnInit {
	public closeModalSearch: boolean = false
	public tooglerVenue: boolean = false
	public modalValidated: boolean = false
	public openedOptions: boolean = true
	public validatedVoucher: boolean = false
	public nemModalValidation: boolean = false
	public validatedVoucherModal: boolean = false
	public voucherValidated: boolean = false
	public openedVenue: boolean = false
	public seeVoucher: boolean = false
	public openedValidation: boolean = true
	public nameVenues: any = ''
	public loading: boolean = false
	public addLocalStorage: boolean = true
	public nameVenueSelected: any = ''
	public idVenueSelected: any = ''
	public selectedVenue: string = ''
	selectedVenueUpdate = new Subject<string>()
	public value: number = 0
	public openedValidationConfirmed: boolean = false
	public restaurantVenues: any[] = []
	public lastViewed: any[] = []
	public last: any[] = []
	public newLastViewed: any[] = []

	public tabContentButton: any[] = [
		{ class: 'inverse', title: 'Sentar', icon: '' },
		{ class: 'button__filter', title: '', icon: 'tagmeicon-close' },
		{ class: 'button__filter', title: '', icon: 'tagmeicon-megafone' },
	]

	public tabs: any[] = [
		{ title: 'Mesas do Almoço', id: 'lunch' },
		{ title: 'Mesas do Jantar', id: 'dinner' },
	]

	public menus: any[] = [
		{ title: 'Atualizar Página', icon: 'tagmeicon tagmeicon-refresh' },
		{ title: 'Buscar nome do Cliente', icon: 'tagmeicon tagmeicon-buscar' },
		{ title: 'Ver Filtros', icon: 'tagmeicon tagmeicon-filtro' },
		{ title: 'Ocultar Mesas', icon: 'tagmeicon tagmeicon-mesa' },
		{ title: 'Ver Ambientes', icon: 'tagmeicon tagmeicon-pin-24' },
		{ title: 'Painel Semanal', icon: 'tagmeicon tagmeicon-pin-24' },
		{ title: 'Reserva Aberta', icon: 'tagmeicon tagmeicon-pin-24' },
		{ title: 'Adicionar Reserva', icon: 'tagmeicon tagmeicon-pin-24' },
	]

	public menuContentSubtitle: any[] = [
		{ title: 'Mesa Vazia', span: 'empty' },
		{ title: 'Telefone', span: 'phone' },
		{ title: 'Online', span: 'online' },
		{ title: 'Passante', span: 'pass' },
		{ title: 'Confirmada', span: 'confirmed' },
		{ title: 'Sentada', span: 'sitting' },
	]

	public cardButtons: any[] = [
		{ title: 'Perfil do restaurante', icon: 'tagmeicon tagmeicon-home', isOption: true },
		{ title: 'Lista de espera', icon: 'tagmeicon tagmeicon-tempo-24', isOption: true },
		{ title: 'Lista de espera (admin)', icon: 'tagmeicon tagmeicon-tempo-cog-24', isOption: true },
		{ title: 'Reserva', icon: 'tagmeicon tagmeicon-calendario-24', isOption: true },
		{ title: 'Reserva (admin)', icon: 'tagmeicon tagmeicon-calendario-cog-24', isOption: true },
		{ title: 'Passante Check in', icon: 'tagmeicon tagmeicon-pin-24', isOption: true },
		{ title: 'Menu Digital (admin)', icon: 'tagmeicon tagmeicon-app', isOption: true },
		{ title: 'Gestor de pedidos', icon: 'tagmeicon tagmeicon-selo-menu-casual', isOption: true },
		{ title: 'Programas gastronômicos', icon: 'tagmeicon tagmeicon-voucher', isOption: true },
		{ title: 'CRM', icon: 'tagmeicon tagmeicon-megafone', isOption: true },
		{ title: 'Cartela virtual de fidelidade', icon: 'tagmeicon tagmeicon-mesa', isOption: true },
		{ title: 'Logout', icon: 'tagmeicon tagmeicon-logout', isOption: false, isLogout: true },
	]

	constructor(private reservationAdminService: ReservationAdminService) {
		this.selectedVenueUpdate.pipe(debounceTime(700), distinctUntilChanged()).subscribe(value => {
			this.selectVenue(value)
		})
	}

	ngOnInit(): void {
		const idsVenues = JSON.parse(localStorage.getItem('reservationAdmin')!)
		if (idsVenues != null) {
			for (var i = 0; i < 5; i++) {
				if (idsVenues[i]) {
					this.newLastViewed.push(idsVenues[i])
				}
			}
		}
	}

	public async getVenues(nameVenue: any) {
		await this.reservationAdminService.getVenuesData(nameVenue).subscribe(res => {
			this.lastViewed = Object.values(res)

			for (var i = 0; i < 5; i++) {
				for (var j = 0; j < this.lastViewed.length; j++) {
					if (this.newLastViewed[i] == this.lastViewed[j]._id) {
						this.last.push(this.lastViewed[j])
					}
				}
			}

			this.restaurantVenues = Object.values(res)
			this.loading = false
		})
	}

	public async tabSearchVenue(idVenue: any) {
		this.idVenueSelected = idVenue
		await this.chosenVenue(this.idVenueSelected)
		this.closeModalSearch = true
		this.openModalVenue()
	}

	public async search() {
		if (this.idVenueSelected) {
			this.addVenuesToLocalStorage()
			await this.chosenVenue(this.idVenueSelected)
			this.closeModalSearch = true
			this.openModalVenue()
		}
		this.closeModalSearch = true
		// else{
		//   alert('Escolha uma Venue')
		// }
	}

	public addVenuesToLocalStorage() {
		for (var i = 0; i < this.newLastViewed.length; i++) {
			if (this.newLastViewed[i][0] == this.idVenueSelected) {
				this.addLocalStorage = false
			}
		}

		if (this.addLocalStorage) {
			this.newLastViewed.unshift([this.idVenueSelected, this.nameVenueSelected])
			localStorage.setItem('reservationAdmin', JSON.stringify(this.newLastViewed))
		}
	}

	public async chosenVenue(idVenue: any) {
		await this.reservationAdminService.getInfoVenue(idVenue).subscribe(res => {
			this.nameVenues = (res as any).name
		})
	}

	public async selectVenue(nameVenue: any) {
		if (nameVenue.length > 2) {
			await this.getVenues(nameVenue)
			this.nameVenueSelected = nameVenue
			this.idVenueSelected = this.restaurantVenues.find(item => item.name === this.nameVenueSelected)
			if (this.idVenueSelected) {
				this.idVenueSelected = this.idVenueSelected._id
			}
		}
	}

	public openVenue() {
		this.tooglerVenue = !this.tooglerVenue
	}

	public confirmValidation() {
		this.nemModalValidation = true
	}

	public openModalOptions() {
		this.openedOptions = !this.openedOptions
	}

	public openModalVenue() {
		this.openedVenue = !this.openedVenue
	}

	public openModalValidation() {
		this.openedValidation = !this.openedValidation
	}

	public voucherValidate() {
		this.voucherValidated = true
		this.openModalValidation()
	}

	public seeVoucherValidated() {
		this.seeVoucher = true
		this.openModalValidation()
	}
}
