import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ReportsItauService } from './reports.service'

@Component({
	selector: 'app-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss'],
})
export class ReportItauComponent implements OnInit {
	public sectors = this.reportsService.sectors
	constructor(private router: Router, private reportsService: ReportsItauService) {}

	ngOnInit(): void {}

	public selectSectors(slug: string) {
		this.router.navigate(['itau/report-export/' + slug])
	}
}
