import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
	selector: 'app-header-venue',
	templateUrl: './header-venue.component.html',
	styleUrls: ['./header-venue.component.scss'],
})
export class HeaderVenueComponent {
	@Input() name!: string
	@Input() showIcon: boolean = true
	@Output() onClick = new EventEmitter<void>()

	constructor() {}

	clickTitle() {
		this.onClick.emit()
	}
}
