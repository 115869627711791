<div class="page">
	<div class="page__wrapper wrapper">
		<app-back-button></app-back-button>
	</div>

	<div class="page__body">
		<div class="page__wrapper wrapper">
			<div class="edit">
				<label class="edit__label" for="venueId">
					<span>VenueId</span>
				</label>
				<div id="venueId" type="text" class="edit__input edit__input--disabled">{{ venueId }}</div>
				<label class="edit__label" for="partnerId">
					<span>PartnerId</span>
				</label>
				<div id="partnerId" type="text" class="edit__input edit__input--disabled">
					{{ partnerId }}
				</div>
				<!--Logo  -->
				<ng-container *ngIf="vm$ | async as vm">
					<app-accordion
						slug="002"
						name="Upload logo (Logomarca do Restaurante)"
						[tempAccordion]="tempLogo1"
					></app-accordion>
					<ng-template #tempLogo1>
						<app-logo
							type="logo"
							[images]="vm.venueConfig.images"
							[partner]="vm.partner"
							[timestamp]="timestamp"
							(croppedClicked)="openConfirmCroppedModal($event, 'logo')"
							(deleteImage)="openDeleteModal($event, 'logo')"
						>
						</app-logo>
					</ng-template>
					<!--Banner  -->
					<app-accordion
						slug="002"
						name="Upload banner (Imagens no topo da página do restaurante - Carrossel)"
						[tempAccordion]="tempLogo2"
					></app-accordion>
					<ng-template #tempLogo2>
						<app-logo
							type="banners"
							[images]="vm.venueConfig.images"
							[partner]="vm.partner"
							[timestamp]="timestamp"
							(croppedClicked)="openConfirmCroppedModal($event, 'banners')"
							(deleteImage)="openDeleteModal($event, 'banners')"
							(saveOrder)="saveOrder($event, 'banners')"
						>
							></app-logo
						>
					</ng-template>
					<!--Thumb  -->
					<app-accordion
						slug="002"
						name="Upload thumb (Imagens para a página de pesquisas de restaurantes)"
						[tempAccordion]="tempLogo3"
					></app-accordion>
					<ng-template #tempLogo3>
						<app-logo
							type="thumb"
							[images]="vm.venueConfig.images"
							[partner]="vm.partner"
							[timestamp]="timestamp"
							(croppedClicked)="openConfirmCroppedModal($event, 'thumb')"
							(deleteImage)="openDeleteModal($event, 'thumb')"
							(saveOrder)="saveOrder($event, 'thumb')"
						>
							></app-logo
						>
					</ng-template>
				</ng-container>
			</div>
		</div>
	</div>
</div>

<ch-modal [id]="modal.error" top="15%" [preventOutsideClickClose]="true">
	<app-modal-simple [message]="errorMessage"></app-modal-simple>
</ch-modal>

<ch-modal [id]="modal.success" top="15%" [preventOutsideClickClose]="true">
	<app-modal-simple message="Imagens salvo com sucesso!"></app-modal-simple>
</ch-modal>

<ch-modal [id]="modal.confirmCrooped" top="15%">
	<app-modal-simple
		message="Deseja recortar a imagem?"
		template="choose"
		(confirmClicked)="chooseCreateOrUpdateImageOptimzed()"
		(cancelClicked)="modalService.close(modal.confirmCrooped)"
		*ngLoaded="loadingApi"
	>
		<div style="overflow: auto; max-height: 50vh">
			<img
				[src]="imageCropped?.croppedImage"
				style="border: 1px solid rosybrown; max-height: 100%; width: 100%"
			/>
		</div>
	</app-modal-simple>
</ch-modal>

<ch-modal [id]="modal.deleteImage" top="15%">
	<app-modal-simple
		message="Deseja deletar a imagem?"
		template="choose"
		(confirmClicked)="deleteImage()"
		(cancelClicked)="modalService.close(modal.deleteImage)"
		*ngLoaded="loadingApi"
	>
	</app-modal-simple>
</ch-modal>

<ch-modal [id]="modal.loading" top="15%">
	<div *ngLoaded="true"></div>
</ch-modal>
