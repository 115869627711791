<div class="waitlist" [ngClass]="openedVenue ? 'opened' : ''">
	<!-- MODAL SELECT VENUE -->
	<app-modal slug="001" [closeModalSearch]="closeModalSearch" [typeModal]="'venue'">
		<div class="modal__content" content>
			<p class="modal__text">Selecione um estabelecimento (Venue)</p>
			<div class="modal__select" [ngClass]="tooglerVenue ? 'opened' : ''" (click)="openVenue()">
				<label class="modal__label">
					<input
						class="modal__input"
						list="brow"
						type="text"
						[(ngModel)]="selectedVenue"
						name="selectedVenue"
						placeholder="Digite o nome aqui"
						(ngModelChange)="this.selectedVenueUpdate.next($event)"
						value="{{ nameVenueSelected }}"
					/>
					<i class="modal__icon tagmeicon tagmeicon-buscar" (click)="search()"></i>
				</label>
				<datalist id="brow" class="modal__select__list">
					<option
						class="modal__select__item"
						*ngFor="let item of restaurantVenues"
						value="{{ item.name }}"
					></option>
				</datalist>
			</div>
			<div class="modal__last-viewed">
				<p class="modal__last-viewed__title">Últimos visualizados</p>
				<app-loading *ngIf="loading" class="loading" slug="001"></app-loading>
				<ng-container *ngIf="!loading">
					<ul class="tab001" *ngFor="let item of newLastViewed">
						<li class="tab001__item" (click)="tabSearchVenue(item[0])">
							{{ item[1] }}
						</li>
					</ul>
				</ng-container>
			</div>
		</div>
	</app-modal>

	<!-- MODAL OPTIONS -->
	<app-modal slug="001" [closeModalSearch]="openedOptions" [typeModal]="''">
		<div class="modal__content" content>
			<div class="modal__grid">
				<div class="modal__column" *ngFor="let cards of cardButtons">
					<app-card
						slug="001"
						[nameCards]="cards.title"
						[iconCards]="cards.icon"
						[idCards]="cards.id"
						[isOption]="cards.isOption"
						[isLogout]="cards.isLogout"
					>
					</app-card>
				</div>
			</div>
		</div>
	</app-modal>

	<!-- MODAL ACTIONS -->
	<app-modal slug="001" [closeModalSearch]="modalActions">
		<div class="modal__content" content>
			<i class="modal001__close__icon tagmeicon tagmeicon-close" (click)="chooseModalAction()"></i>
			<div class="modal__infos">
				<p class="modal__text">Rafael Caride</p>
				<p class="modal__people">
					2 pessoas -
					<span class="modal__people__waitingTime"> 03:45 minutos </span>
					na espera
				</p>
				<div class="modal__table">
					<label for="" class="modal__label">
						<p class="modal__label__text">Comanda</p>
						<i class="modal__icon tagmeicon tagmeicon-mesa"></i>
						<input type="text" class="modal__input" placeholder="#1564" />
					</label>
					<label for="" class="modal__label">
						<p class="modal__label__text">Nº Mesa</p>
						<i class="modal__icon tagmeicon tagmeicon-mesa-mini"></i>
						<input type="text" class="modal__input" placeholder="12" />
					</label>
					<button class="modal__button button__light" (click)="chooseModalAction()">Salvar</button>
				</div>
				<div class="modal__benefits voucher">
					<p class="modal__benefits__title">Cliente Programa Menu Bradesco</p>
					<p class="modal__benefits__text">
						Benefício: <br />
						<span class="modal__benefits__this"> Uma sobremesa </span>
					</p>
					<p class="modal__benefits__voucher">Voucher 09833691</p>
					<p class="modal__benefits__notice">Voucher validado automaticamente ao sentar cliente</p>
				</div>
				<div class="modal__actions">
					<p class="modal__actions__text">Ações</p>
					<div class="modal__buttons">
						<button class="modal__button button__del--inverse" (click)="chooseModalAction()">
							Excluir
						</button>
						<button class="modal__button button__confirm--inverse" (click)="chooseModalAction()">
							Chamar
						</button>
						<button class="modal__button button__blue" (click)="chooseModalAction()">
							Sentar
						</button>
					</div>
				</div>
			</div>
		</div>
	</app-modal>

	<!-- MODAL VALIDATION -->
	<app-modal slug="001">
		<div class="modal__content" content>
			<div class="modal__confirm">
				<img src="./../../../assets/images/imagem-de-confirmacao.png" />
				<p class="modal__question">
					Deseja sentar
					<span class="modal__question__name">Rafael Caride</span>
					à mesa?
				</p>
				<div class="modal__buttons">
					<button class="modal__button button__blue--inverse">Confirmar</button>
					<button class="modal__button button__blue--inverse">Desistir</button>
				</div>
			</div>
		</div>
	</app-modal>

	<div class="page">
		<app-header-master></app-header-master>

		<div class="page__header">
			<div class="page__wrapper wrapper">
				<div class="waitlist__header">
					<div class="waitlist__header__name" (click)="openModalVenue()">
						<p class="waitlist__header__name__title">{{ nameVenues }}</p>
						<i class="waitlist__header__name__icon tagmeicon tagmeicon-seta"></i>
					</div>
					<div class="waitlist__header__actions">
						<i class="waitlist__header__icon tagmeicon tagmeicon-filtro-mini"></i>
						<i class="waitlist__header__icon tagmeicon tagmeicon-notes-24"></i>
						<i
							class="waitlist__header__icon tagmeicon tagmeicon-options-dots"
							(click)="openModalOptions()"
						></i>
					</div>
				</div>

				<form #createWaitlistForm="ngForm">
					<div class="field">
						<label for="contact" class="field__label">
							<input
								ngModel
								(keyup)="infosWaitlist(createWaitlistForm.value)"
								class="field__input"
								type="text"
								name="phone"
								placeholder="Telefone com DDD"
							/>
						</label>

						<label for="name" class="field__label">
							<input
								ngModel
								(keyup)="infosWaitlist(createWaitlistForm.value)"
								class="field__input"
								name="name"
								type="text"
								placeholder="Nome"
							/>
						</label>

						<div class="field__select" [ngClass]="amountPeople ? 'opened' : ''">
							<div class="field__select__txt" (click)="openedAmountPeople()">
								{{ numberOfPeopleSelected }}
								<i class="field__select__icon tagmeicon tagmeicon-seta"></i>
							</div>
							<ul class="field__select__list">
								<li
									class="field__select__item"
									*ngFor="let item of numberPeoples"
									(click)="selectAmountPeople(item.amount)"
								>
									{{ item.amount }}
								</li>
							</ul>
						</div>

						<label for="email" class="field__label">
							<input
								ngModel
								(keyup)="infosWaitlist(createWaitlistForm.value)"
								class="field__input"
								name="email"
								type="text"
								placeholder="Email"
							/>
						</label>

						<label for="localization" class="field__select">
							<!-- ainda não sei como serão após aberto -->
							<div class="field__select__txt">
								Localização
								<i class="field__icon tagmeicon tagmeicon-mais-bolinha"></i>
							</div>
						</label>

						<label for="client" class="field__select">
							<!-- ainda não sei como serão após aberto -->
							<div class="field__select__txt">
								Tipo de cliente
								<i class="field__icon tagmeicon tagmeicon-mais-bolinha"></i>
							</div>
						</label>

						<label for="obs" class="field__select">
							<!-- ainda não sei como serão após aberto -->
							<div class="field__select__txt">
								Observações
								<i class="field__icon tagmeicon tagmeicon-mais-bolinha"></i>
							</div>
						</label>

						<button class="field__button button" [ngClass]="missingData ? 'disabled' : 'enabled'">
							<!-- após campos preenchidos a class DISABLED sai -->
							Inserir na fila
						</button>
					</div>
				</form>
			</div>
		</div>

		<div class="page__body">
			<div class="page__wrapper wrapper">
				<div class="waitlist__body">
					<p class="waitlist__body__queue">
						<strong>7 clientes</strong> e <strong>14 pessoas</strong> na fila
					</p>

					<div class="waitlist__body__grid">
						<div class="waitlist__body__column">
							<div class="waitlist__body__client">
								<div class="waitlist__body__client__top">
									<div class="waitlist__body__client__order">1º</div>
									<div class="waitlist__body__client__profile">
										<p class="waitlist__body__client__title">Rafael Caride</p>
										<a href="" class="waitlist__body__client__detail link">
											Ver perfil
										</a>
									</div>
									<div class="waitlist__body__client__reservation">
										<p class="waitlist__body__client__title">2 pessoas</p>
										<p class="waitlist__body__client__detail">03:45</p>
									</div>
									<div class="waitlist__body__client__message" (click)="openModalMessage()">
										<!-- quando clicar aqui, com a class SHOW, abrirá o POP UP abaixo e estando aberto, ao clicar, fecha -->
										<i class="waitlist__body__client__icon tagmeicon tagmeicon-email"></i>
										<i
											class="waitlist__body__client__icon tagmeicon tagmeicon-check-confirm"
										></i>
									</div>

									<!-- POP UP -->
									<div
										class="waitlist__body__client__popup"
										[ngClass]="openModalMsg ? 'show' : ''"
									>
										<p class="waitlist__body__client__popup__title">
											Cliente Programa Menu Bradesco
										</p>
										<p class="waitlist__body__client__popup__text">
											Benefício: <br />
											<span class="waitlist__body__client__popup__this">
												Uma Sobremesa
											</span>
										</p>
										<p class="waitlist__body__client__popup__voucher">Voucher 09833691</p>
										<button
											class="waitlist__body__client__popup__button button"
											(click)="openModalActions()"
										>
											Ações
										</button>
									</div>

									<button
										class="waitlist__body__client__button button"
										(click)="openModalActions()"
									>
										<!-- quando clicar nele abrirá o modal ACTIONS -->
										Ações
									</button>
								</div>
								<div class="waitlist__body__client__bottom">
									<ul class="waitlist__body__client__data opened">
										<li
											class="waitlist__body__client__data__item"
											*ngFor="let item of clientBottomTabs"
										>
											<i
												class="waitlist__body__client__data__icon tagmeicon {{
													item.icon
												}}"
											></i>
											{{ item.title }}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
