import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { filter, take } from 'rxjs'
import { UserService } from '../user.service'
import { LoginService } from './login.service'

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
	public loadingUser: boolean = false
	loginForm!: UntypedFormGroup
	errorMessage: string | undefined

	constructor(
		private router: Router,
		private loginService: LoginService,
		private userService: UserService,
		private fb: UntypedFormBuilder
	) {}

	ngOnInit(): void {
		this.userService.user$
			.pipe(
				take(1),
				filter(user => Boolean(user))
			)
			.subscribe(user => {
				this.router.navigate([this.userService.goHome])
			})

		this.loginForm = this.fb.group({
			username: ['', [Validators.required]],
			password: ['', Validators.required],
		})
	}

	public handleSubmit() {
		if (this.loginForm.invalid) {
			this.errorMessage = 'Seu usuário e/ou senha inválidos.'
			return
		}
		this.loadingUser = true
		this.loginService.login(this.loginForm.value).subscribe({
			next: () => {
				this.errorMessage = ''
				this.loadingUser = false
				this.router.navigate([this.userService.useRedirectURL() ?? this.userService.goHome])
			},
			error: error => {
				this.errorMessage = error?.error?.message
				this.loadingUser = false
			},
		})
	}
}
