<ch-modal [id]="modal.partner" top="150px" [preventOutsideClickClose]="true">
	<ng-container [formGroup]="partnerForm">
		<app-modal-partner (confirmClicked)="firtStep()"></app-modal-partner>
	</ng-container>
</ch-modal>

<div class="page">
	<app-header-master></app-header-master>

	<div class="page__body">
		<div class="page__wrapper wrapper">
			<app-back-button [addClass]="'element'"></app-back-button>

			<div class="benefit">
				<h2 class="benefit__title">{{ partnerTitle }}</h2>

				<div class="benefit__box">
					<app-accordion slug="002" [name]="'Gestão de benefícios'" [tempAccordion]="tempAccordion">
						<ng-template #tempAccordion>
							<div class="benefit__grid">
								<ng-container *ngIf="benefitCards$ | async as benefitCards">
									<div class="benefit__column" *ngFor="let item of benefitCards">
										<app-card
											slug="002"
											[nameCardBenefit]="item.name"
											[imgCardBenefit]="
												'https://tagmepub.azureedge.net/pubimg/' + item.icon
											"
											[idBenefit]="item._id"
											[activeCardBenefit]="item.enabled"
											(click)="openEditBenefit(item._id)"
										>
										</app-card>
									</div>
								</ng-container>
							</div>
							<app-loading
								*ngIf="status === 'pending'"
								class="loading"
								slug="001"
							></app-loading>
							<button class="benefit__button button__light" (click)="openCreateBenefit()">
								Criar novo benefício
							</button>
						</ng-template>
					</app-accordion>
				</div>
			</div>
		</div>
	</div>
</div>
