import { Injectable } from '@angular/core'
import { ModalComponentChannels } from './modal.component'

@Injectable({ providedIn: 'root' })
export class ModalService {
	private modals: ModalComponentChannels[] = []

	add(modal: ModalComponentChannels) {
		if (this.hasModal(modal.id)) {
			console.error(`Modal already registered with id: ${modal.id}`)
			return
		}
		this.modals.push(modal)
	}

	remove(id: string) {
		this.modals = this.modals.filter(x => x.id !== id)
	}

	open(id: string) {
		const modal = this.modals.find(x => x.id === id)
		if (modal) {
			modal.open()
		}
	}

	close(id: string) {
		const modal = this.modals.find(x => x.id === id)
		if (modal) {
			modal.close()
		}
	}

	hasModal(id: string): boolean {
		return this.modals.find(m => m.id === id) ? true : false
	}
}
