import { Component, OnInit } from '@angular/core'
import { map } from 'rxjs'
import { LoginService } from '../../user/login/login.service'
import { PaymentsService } from '../payment.service'

@Component({
	templateUrl: './payment-info.page.html',
	styleUrls: ['../venue-payment-base.scss'],
})
export class PaymentInfoPage implements OnInit {
	constructor(private paymentsService: PaymentsService, private loginService: LoginService) {}

	ngOnInit(): void {}
}
