import { HttpClient } from '@angular/common/http'
import { environment as env } from 'src/environments/environment'
import {
	ImageDeleteDto,
	ImageUpdateBase64WithOptimizationDto,
	ImageUploadBase64Dto,
	ImageUploadBase64WithOptimizationDto,
} from './image-upload.interface'
import { Injectable } from '@angular/core'
import { of } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class ImageUploadApi {
	private root = 'image-upload'
	private baseURL = `${env.channelsAPI.baseURL}${this.root}`
	constructor(private http: HttpClient) {}

	createOriginal(imageUploadBase64Dto: ImageUploadBase64Dto) {
		// return of({path: 'original_1'})
		return this.http.post<{ path: string }>(`${this.baseURL}/base64/original`, imageUploadBase64Dto)
	}

	createOptimized(imageUploadBase64Dto: ImageUploadBase64WithOptimizationDto) {
		// return of({path: 'optimized_1'})
		return this.http.post<{ path: string }>(`${this.baseURL}/base64/optimized`, imageUploadBase64Dto)
	}

	updateOptimized(imageUploadBase64Dto: ImageUpdateBase64WithOptimizationDto) {
		return this.http.put<{ path: string }>(`${this.baseURL}/base64/optimized`, imageUploadBase64Dto)
	}

	deleteBlob(imageDeleteQuery: ImageDeleteDto) {
		return this.http.delete<{ path: string }>(`${this.baseURL}?filePath=${imageDeleteQuery.filePath}`)
	}
}
