<div class="page">
	<section class="login">
		<div class="login__wrapper wrapper">
			<div class="login__content">
				<p class="login__content__title">Login</p>
				<p class="login__content__text">Informe seus dados para entrar</p>
				<app-loading *ngIf="loadingUser" class="loading" slug="001"></app-loading>
				<form
					class="login__content__form"
					*ngIf="!loadingUser"
					[formGroup]="loginForm"
					(ngSubmit)="handleSubmit()"
				>
					<label for="nameId" class="login__content__form__label">
						<input
							id="nameId"
							name="username"
							formControlName="username"
							type="text"
							class="login__content__form__input"
							placeholder="Usuário"
						/>
					</label>
					<label for="passwordId" class="login__content__form__label">
						<input
							id="passwordId"
							type="password"
							name="password"
							formControlName="password"
							class="login__content__form__input"
							placeholder="Senha"
						/>
					</label>
					<button class="login__content__form__button button" type="submit">Entrar</button>
					<!-- <a href="" class="login__content__form__link">Esqueci a senha</a> -->
				</form>
				<span class="login__content__alert" *ngIf="errorMessage">{{ errorMessage }}</span>
			</div>
		</div>

		<div class="login__logo">
			<i class="login__logo__icon tagmeicon tagmeicon-tagme-vertical"></i>
		</div>
	</section>
</div>
