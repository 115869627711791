export class VoucherBBI {
	errors: Array<{ field: string; message: string }> = []
	code: string
	type: string
	constructor(code: string, type: string, filename: string) {
		if (!code) this.errors.push({ field: 'CUPOM', message: 'CUPOM não pode ser vazio.' })
		if (!type?.length) this.errors.push({ field: 'TIPO', message: 'TIPO não pode ser vazio.' })
		if (!['thunder', 'quadrato'].includes(type))
			this.errors.push({
				field: 'TIPO',
				message: 'TIPO não pode ser diferente de THUNDER ou QUADRATO.',
			})
		if (!filename)
			this.errors.push({ field: 'NOME DO ARQUIVO', message: 'NOME DO ARQUIVO não pode ser vazio.' })
		this.type = type
		this.code = code
	}
}
