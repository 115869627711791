import { Injectable } from '@angular/core'
import { environment as env } from 'src/environments/environment'
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Injectable({ providedIn: 'root' })
export class OrdersApi {
	constructor(private readonly http: HttpClient) {}

	findHistoryAdmin(findHistoryDto: findHistoryDto, partnerId: string) {
		const headers = new HttpHeaders().set('partnerId', partnerId)
		const query = findHistoryDto ? '?' + new URLSearchParams(findHistoryDto as any).toString() : ''
		return this.http.get<any[]>(`${env.channelsAPI.baseURL}orders/admin/history/${query}`, {
			headers,
		})
	}
}

interface findHistoryDto {
	clientId?: string
	cpf?: string
	intent: string
	startDate: string // Date YYYY-MM-dd
	endDate: string // Date YYYY-MM-dd
	skip?: string // number YYYY-MM-dd
	limit?: string // number YYYY-MM-dd
}
