<div class="accordion001" [ngClass]="openedAccordion ? '' : 'opened'" (click)="togglerAccordion()">
	<div class="accordion001__header">
		<div class="accordion001__title">
			<i class="accordion001__icon {{ icon }} {{ class }}"></i>
			<h2 class="accordion001__name">{{ name }}</h2>
		</div>
		<div class="accordio001__arrow">
			<i class="accordion001__arrow__icon tagmeicon tagmeicon-seta"></i>
		</div>
	</div>
	<div class="accordion001__body opened">
		<ng-container *ngTemplateOutlet="tempAccordion"></ng-container>
	</div>
</div>
