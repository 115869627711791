<div class="box">
	<div class="box__check" [ngClass]="{ selectedBox: selected }" (click)="clickSelection()">
		<label class="checkbox-container">
			<input type="checkbox" [(ngModel)]="selected" (change)="clickSelection()" />

			<span class="checkmark"></span>
		</label>
	</div>
	<div class="payment">
		<div class="payment__col payment__firstColumn">
			<div class="payment__row">
				<p class="payment__name">{{ order.client?.name }} {{ order.client?.lastName }}</p>
				<div class="payment__box" *ngIf="template">
					{{ text }}
				</div>
			</div>
			<div class="payment__row">
				<p class="payment__text">{{ order.reservationDay | date : 'dd/MM/YYYY' }}</p>
				<p class="payment__text">{{ order.reservationTime }}</p>
				<p class="payment__text">{{ partySize }}</p>
			</div>
			<div class="payment__origin">
				<img class="payment__icon" src="assets/icons/internet-mini.svg" />
				Origem: {{ order.partner?.name }}
			</div>
		</div>
		<!--<div class="payment__values" *ngIf="order.paymentStatus === 'DONE'">
			<div class="payment__col payment__lastColumn">
				<button class="payment__btnPrint" (click)="clickPrint()">
					<i class="tagmeicon tagmeicon-imprimir-mini"></i>
				</button>
			</div>
		</div>-->
		<div class="payment__values">
			<div class="payment__col">
				<p class="payment__title">Valor da conta</p>
				<p class="payment__value" *ngIf="lastPayment?.orderInitialValue">
					{{ lastPayment.orderInitialValue / 100 | currency : 'BRL' }}
				</p>
				<p class="payment__value" *ngIf="!lastPayment?.orderInitialValue">-</p>
			</div>
			<div class="payment__col">
				<p class="payment__title">Gorjeta</p>
				<p class="payment__value" *ngIf="lastPayment?.gratuityValue">
					{{ lastPayment.gratuityValue / 100 | currency : 'BRL' }}
				</p>

				<p class="payment__value" *ngIf="!lastPayment?.gratuityValue">-</p>
			</div>
			<div class="payment__col">
				<p class="payment__title">Taxas</p>
				<p class="payment__value" *ngIf="taxes">{{ taxes / 100 | currency : 'BRL' }}</p>
				<p class="payment__value" *ngIf="!taxes">-</p>
			</div>
			<div class="payment__col" *ngIf="lastPayment?.discountValue">
				<p class="payment__title">Desconto</p>
				<p class="payment__value">{{ lastPayment.discountValue }}</p>
			</div>
			<div class="payment__col">
				<p class="payment__title">Valor a receber</p>
				<p class="payment__value" *ngIf="lastPayment?.venueValue">
					{{ lastPayment.venueValue / 100 | currency : 'BRL' }}
				</p>
				<p class="payment__value" *ngIf="!lastPayment?.venueValue">-</p>
			</div>
			<div class="payment__col">
				<p class="payment__title payment__title--to-receive">Valor pago</p>
				<p class="payment__value payment__value--to-receive" *ngIf="lastPayment?.orderTotalValue">
					{{ lastPayment.orderTotalValue / 100 | currency : 'BRL' }}
				</p>
				<p class="payment__value payment__value--to-receive" *ngIf="!lastPayment?.orderTotalValue">
					-
				</p>
			</div>
		</div>
		<div class="payment__values">
			<div class="payment__col payment__lastColumn">
				<button class="payment__btn" (click)="clickRefund()" *ngIf="order.paymentStatus === 'DONE'">
					Estornar
				</button>
				<button
					class="payment__btn"
					style="height: 40px"
					(click)="gratuityClicked.emit(order)"
					*ngIf="order.paymentStatus === 'READY' || order.paymentStatus === 'FAILED'"
				>
					Taxa de Serviço
				</button>
			</div>
		</div>
	</div>
</div>
