<app-accordion001
	*ngIf="slug === '001'"
	[name]="name"
	[icon]="icon"
	[class]="class"
	[tempAccordion]="tempAccordion"
>
</app-accordion001>

<app-accordion002 *ngIf="slug === '002'" [name]="name" [tempAccordion]="tempAccordion"> </app-accordion002>
