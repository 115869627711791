<div class="page">
	<div class="page__wrapper wrapper">
		<app-back-button [page]="'category'"></app-back-button>
	</div>

	<div class="page__body">
		<div class="page__wrapper wrapper">
			<div class="create">
				<p class="create__title">Criar nova categoria</p>

				<div class="create__photo">
					<div *ngIf="!imageSrc" class="create__photo__box">
						<i class="create__photo__box__icon tagmeicon tagmeicon-foto"></i>
					</div>
					<img
						*ngIf="imageSrc"
						class="create__photo__box"
						id="selectedImage"
						[src]="imageSrc"
						alt="your image"
					/>
					<p class="create__photo__msg">
						Arraste e solte<br />
						a foto aqui
					</p>
				</div>

				<div class="create__box">
					<label for="files" class="create__link" (change)="readURL($event)"> Escolher foto </label>
					<input id="files" class="create__box__input" type="file" (change)="readURL($event)" />
				</div>
				<!-- FORM -->
				<form class="create__form" #createCategoryForm="ngForm">
					<!-- SWITCH -->
					<label class="switch">
						<input
							type="checkbox"
							class="switch__input"
							name="enabled"
							[ngModel]="editCategory ? dataEditCategory.enabled : false"
						/>
						<div class="switch__round"></div>
						<p class="switch__txt">Categoria (ativa)</p>
					</label>
					<label class="create__label"> Nome da categoria </label>
					<!-- <button *ngIf="editCategory" (click)="deleteCategory()"> DELETAR</button> -->
					<input
						required
						(keyup)="infosBenefit(createCategoryForm.value)"
						[ngModel]="editCategory ? dataEditCategory.name : ''"
						name="name"
						type="text"
						class="create__input"
						placeholder="Ex.: Cartão Gold"
					/>
					<div class="create__select" [ngClass]="openedBrand ? 'opened' : ''">
						<label class="create__label"> Bandeira </label>
						<div class="create__select__txt" (click)="togglerBrand()">
							{{ brandName }}
						</div>
						<ul class="create__select__list">
							<li
								class="create__select__item"
								*ngFor="let item of brands"
								(click)="selectBrand(item.name)"
							>
								{{ item.name }}
							</li>
						</ul>
					</div>
					<!-- <div class="create__select" [ngClass]="openedType ? 'opened' : ''">                   
                            <label  class="create__label">
                                Tipo
                            </label>
                            <div class="create__select__txt" (click)="togglerType()">
                                {{typeName}}
                            </div>
                            <ul class="create__select__list">
                                <li class="create__select__item" *ngFor="let item of types;" (click)="selectType(item.name)">
                                    {{item.name}}
                                </li>
                            </ul>   
                        </div> -->

					<div class="create__range">
						<div class="create__range__form">
							<div class="create__range__start">
								<label class="create__label"> Range (início) </label>
								<input
									required
									id="firstRange"
									minlength="4"
									maxlength="6"
									class="create__input"
									placeholder="Ex.: 374777"
								/>
							</div>
							<div class="create__range__end">
								<label class="create__label"> Range (fim) </label>
								<input
									id="secondRange"
									minlength="4"
									maxlength="6"
									class="create__input"
									placeholder="Ex.: 374800"
								/>
							</div>
							<div class="create__range__button button__light" (click)="addRange()">
								Adicionar
							</div>
						</div>
						<ul class="create__range__list opened">
							<li class="create__range__list__item" *ngFor="let item of range">
								<p class="create__range__list__item__content">
									{{ item.start }}{{ item.unique }}
									-
									{{ item.end }}
								</p>
								<span
									class="create__range__list__item__link"
									(click)="removeRange(item.idRange)"
								>
									Excluir
								</span>
							</li>
						</ul>
					</div>
					<div class="create__select" [ngClass]="openedBenefit ? 'opened' : ''">
						<label class="create__label"> Selecione um ou mais benefícios </label>
						<div class="create__select__txt" (click)="togglerBenefit()">Todos os benefícios</div>
						<ul class="create__select__list">
							<li
								class="create__select__item"
								*ngFor="let item of benefits"
								(click)="selectBenefit(item._id, item.name, item.icon, true)"
							>
								{{ item.name }}
							</li>
						</ul>
						<ul class="create__selected" [ngClass]="showBenefit ? 'opened' : ''">
							<li class="create__selected__item" *ngFor="let item of selectedBenefits">
								<div class="create__selected__item__left">
									<img
										src="https://tagmepub.azureedge.net/pubimg/{{ item.benefitsIcon }}"
									/>
									<p class="create__selected__item__content">
										{{ item.benefitsName }}
									</p>
								</div>
								<div class="create__selected__item__right">
									<div
										class="create__selected__item__link"
										(click)="removeBenefit(item.benefitsId)"
									>
										Excluir
									</div>
								</div>
							</li>
						</ul>
					</div>
					<button
						type="submit"
						[disabled]="buttonDisabled"
						[ngClass]="buttonDisabled ? ' disabled' : ''"
						class="create__button button"
						(click)="saveCreateCategory(createCategoryForm.value)"
					>
						Salvar
					</button>
				</form>
			</div>
		</div>
	</div>
</div>
