import { Component, OnInit } from '@angular/core'

@Component({
	selector: 'app-loading001',
	templateUrl: './loading001.component.html',
	styleUrls: ['./loading001.component.scss'],
})
export class Loading001Component implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
