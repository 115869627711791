import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
	selector: 'app-payment-button [firstDay]',
	templateUrl: './payment-button.component.html',
	styleUrls: ['./payment-button.component.scss'],
})
export class PaymentButtonComponent {
	@Input() firstDay!: string | null
	@Input() lastDay!: string | null
	@Output() buttonClicked = new EventEmitter<void>()

	clickButton() {
		this.buttonClicked.emit()
	}

	get isToday() {
		const date = new Date()

		// date in dd/mm/yyyy format
		const formattedDate = new Intl.DateTimeFormat('en-GB', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
		}).format(date)

		return this.firstDay === formattedDate
	}
}
