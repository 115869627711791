import { Injectable } from '@angular/core'
import { HttpService } from 'src/app/services/http.service'

@Injectable({ providedIn: 'root' })
export class PaymentsService {
	constructor(private http: HttpService) {}

	getPaymentOrderAdmin<Type>(venueId: string, startDate: string, endDate: string) {
		return this.http.get<Type>(`orders/admin/${venueId}/${startDate}/${endDate}`)
		// return this.http.get<Type>(`orders/admin/${venueId}/2023-02-01/2023-02-28`)
	}

	updateGratuityValue(orderId: string, removeGratuityValue: boolean) {
		return this.http.patch(`orders/gratuityValue/`, { orderId, removeGratuityValue })
	}

	cancelPayment(paymentId: string) {
		return this.http.delete(`payments/cancel/${paymentId}`)
	}
}
