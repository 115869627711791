import { Component, HostBinding, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { environment as env } from 'src/environments/environment'
import { Template } from '../card.component'

@Component({
	selector: 'app-card001',
	templateUrl: './card001.component.html',
	styleUrls: ['./card001.component.scss'],
})
export class Card001Component implements OnInit {
	@Input() nameCards: string = ''
	@Input() iconCards: string = ''
	@Input() idCards: string = ''
	@Input() isOption: boolean = false
	@Input() isLogout: boolean = false
	@Input() template: Template = 'default'

	constructor(private router: Router) {}

	ngOnInit(): void {}

	public navigate() {
		if (this.idCards === 'concierge') {
			window.open(`${env.progMenu}/concierge`)
		} else {
			this.router.navigate([this.idCards])
		}
	}

	@HostBinding('style')
	get addProps() {
		const config: Record<Template, any> = {
			progMenu: {
				'--color': 'var(--red)',
			},
			tagme: {
				'--color': 'var(--orange)',
			},
			iupp: {
				'--color': 'var(--blue)',
			},
			default: {
				'--color': 'var(--blackTagme)',
			},
		}
		return config[this.template]
	}
}
