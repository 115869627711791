import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-order-box',
	templateUrl: './order-box.component.html',
	styleUrls: ['./order-box.component.scss'],
})
export class OrderBoxComponent implements OnInit {
	@Input() type: 'success' | 'fail' | 'validated' | 'attention' | 'futureReservation' = 'success'

	classes = {
		success: 'order--success',
		fail: 'order--fail',
		validated: 'order--validated',
		attention: 'order--attention',
		futureReservation: 'order--attention',
	}

	texts = {
		success: 'Reserva validada!',
		fail: 'Não é permitido validar reserva cancelada.',
		validated: 'Já validado anteriormente.',
		attention: 'Reserva precisa estar finalizada para ser validada.',
		futureReservation: 'Não é permitido validar uma reserva antes do dia e horário desta reserva.',
	}

	constructor() {}

	ngOnInit(): void {}

	get chooseClass() {
		return this.classes[this.type]
	}

	get chooseText() {
		return this.texts[this.type]
	}
}
