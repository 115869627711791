import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { UserService } from 'src/app/page/user/user.service'

@Component({
	selector: 'app-header-master',
	templateUrl: './header-master.component.html',
	styleUrls: ['./header-master.component.scss'],
})
export class HeaderMasterComponent implements OnInit {
	constructor(private router: Router, private userService: UserService) {}

	ngOnInit(): void {}

	public goHome() {
		this.router.navigate(['home'])
	}

	public logout() {
		this.userService.logout()
	}
}
