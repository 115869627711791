import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { VenuesManagementService } from '../venues-management.service'
import { VenuesConfigApi } from 'src/app/services/venues-config/venues-config.service'
import { UpdateCheckinDto } from 'src/app/services/venues-config/venues-config.interfaces'
import { ModalService } from 'src/app/components/modalChannels/modal.service'
import { Subject, takeUntil } from 'rxjs'

@Component({
	templateUrl: './edit-checkin.page.html',
	styleUrls: ['./edit-checkin.page.scss'],
})
export class EditCheckinPage implements OnInit, OnDestroy {
	form!: FormGroup
	venueId: string | null = this.venuesManagementService.venueId
	partnerId: string | null = this.venuesManagementService.partnerId
	errorMessage: string | null = null
	modal = {
		error: 'modalErrorId',
		success: 'modalSuccessId',
	}
	private destroy$ = new Subject<void>()
	constructor(
		private fb: FormBuilder,
		private venuesManagementService: VenuesManagementService,
		private venuesConfigApi: VenuesConfigApi,
		private modalService: ModalService
	) {}

	ngOnInit(): void {
		this.form = this.fb.group({
			enabled: [false, Validators.required],
			confirmPayment: [false, Validators.required],
			startTime: [null, Validators.required],
			endTime: [null, Validators.required],
		})

		this.venuesManagementService.ensurePrimaryKey()
		this.updateForm()
	}

	ngOnDestroy(): void {
		this.destroy$.next()
		this.destroy$.complete()
	}

	private updateForm() {
		this.venuesManagementService.venueConfig$.pipe(takeUntil(this.destroy$)).subscribe(venueConfig => {
			if (!venueConfig) return
			const { checkin } = venueConfig
			if (!checkin) return
			const { enabled, confirmPayment, startTime, endTime } = checkin
			this.form.patchValue({ enabled, confirmPayment, startTime, endTime })
		})
	}

	handleSubmit() {
		if (this.form.invalid) return
		const updateCheckin = {
			venueId: this.venueId,
			partnerId: this.partnerId,
			enabled: this.form.value.enabled,
			confirmPayment: this.form.value.confirmPayment,
			startTime: this.form.value.startTime,
			endTime: this.form.value.endTime,
		} as UpdateCheckinDto
		this.venuesConfigApi.updateCheckin(updateCheckin).subscribe({
			next: () => {
				this.modalService.open(this.modal.success)
			},
			error: err => {
				this.errorMessage = err.error.message
				this.modalService.open(this.modal.error)
			},
		})
	}
}
