<app-header-master></app-header-master>

<main class="wrapper">
	<h1>Criação de Vouchers</h1>
	<div class="spacer"></div>
	<div class="spacer"></div>
	<input type="file" (change)="onFileSelected($event)" accept=".csv" />
	<div class="spacer"></div>
	<div *ngIf="hasErrors">Por gentiliza, sanar os erros da planilha.</div>
	<button
		*ngIf="!hasErrors && vouchers.length > 0 && status === 'idle'"
		class="button button__primary"
		style="width: 120px; height: 35px"
		(click)="importVouchers()"
	>
		Enviar dados
	</button>
	<div class="spacer"></div>
	<app-loading *ngIf="status === 'pending'" class="loading" slug="001"></app-loading>
	<div *ngIf="status === 'resolved'">
		<h1 [ngStyle]="{color: 'red', fontSize: '20px'}">Duplicados</h1>
		<table>
			<thead>
				<tr>
					<th>Cupom</th>
					<th>Tipo</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let failure of results.failureVouchers">
					<th>{{failure.code}}</th>
					<th>{{failure.type}}</th>
				</tr>
			</tbody>
		</table>
		<div [ngStyle]="{height: '50px'}"></div>
		<h1 [ngStyle]="{color: 'green', fontSize: '20px'}">Sucesso</h1>
		<table>
			<thead>
				<tr>
					<th>Cupom</th>
					<th>Tipo</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let success of results.successVouchers">
					<th>{{success.code}}</th>
					<th>{{success.type}}</th>
				</tr>
			</tbody>
		</table>
	</div>
	<ng-container *ngIf="status === 'rejected'">
		<h1 [ngStyle]="{color: 'red', fontSize: '20px'}">Erro</h1>
		{{error}}
	</ng-container>
</main>
<table *ngIf="status == 'idle'">
	<thead>
		<tr>
			<th>cupom</th>
			<th>beneficio</th>
			<th *ngIf="hasErrors">Errors</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let csvLine of vouchers">
			<td [title]="csvLine.code">{{ csvLine.code}}</td>
			<td [title]="csvLine.type">{{ csvLine.type }}</td>
			<td *ngIf="csvLine.errors">
				<div style="color: red; font-weight: bold" *ngFor="let error of csvLine.errors">
					{{error.message}}
				</div>
			</td>
		</tr>
	</tbody>
</table>
