<div class="order" [ngClass]="chooseClass">
    <div class="order__item">
        <div class="order__box">
            <i class="order__icon"></i>
        </div>
        <p class="order__msg">
            {{ chooseText }}
        </p>
    </div>
</div>
