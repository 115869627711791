import { Location } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ModalService } from '../../modalChannels/modal.service'

type Templates = 'default' | 'choose' | 'goBack'

@Component({
	selector: 'app-modal-simple [message]',
	template: `
		<section class="modal__wrapper" *ngIf="templates.default">
			<h1>{{ message }}</h1>
			<button class="modal__btn" (click)="location.back()">Voltar</button>
		</section>
		<section class="modal__wrapper" *ngIf="templates.choose">
			<h1>{{ message }}</h1>
			<ng-content></ng-content>
			<button class="modal__btn" (click)="confirmClicked.emit()">Sim</button>
			<button class="modal__btn modal__btn--ghost" (click)="cancelClicked.emit()">Não</button>
		</section>

		<section class="modal__wrapper" *ngIf="templates.goBack">
			<h1>{{ message }}</h1>
			<button class="modal__btn modal__btn--ghost" (click)="closeModal()">Fechar</button>
		</section>
	`,
	styles: [
		`
			@import './../../../../theme';
			h1 {
				text-align: center;
			}
			.modal {
				&__wrapper {
					background-color: $white;
					padding: 24px;
					min-width: 300px;
					border-radius: 3px;
				}

				&__btn {
					display: block;
					margin: 0 auto;
					background-color: $orange;
					border-radius: 3px;
					margin-top: 15px;
					padding-inline: 12px;
					padding-block: 8px;
					width: 100%;
					max-width: 300px;
					cursor: pointer;
					color: $white;
					font-weight: 600;
					&--ghost {
						background-color: transparent;
						color: $blackTagme;
						border: 1px solid $gray69;
					}
				}
			}
		`,
	],
})
export class ModalSimpleComponent implements OnInit {
	@Input() message!: string | null
	@Input() template: Templates = 'default'
	@Input() id!: string
	templates: Record<Templates, boolean> = {
		default: false,
		choose: false,
		goBack: false,
	}
	@Output() confirmClicked = new EventEmitter()
	@Output() cancelClicked = new EventEmitter()
	@Input() loading = false
	constructor(public location: Location, public modalService: ModalService) {}
	ngOnInit(): void {
		this.templates[this.template] = true
	}

	closeModal() {
		this.modalService.close(this.id)
	}
}
