import { Component, Input, ViewChild, Output, EventEmitter, OnChanges } from '@angular/core'
import { ImageCroppedEvent, ImageCropperComponent, LoadedImage } from 'ngx-image-cropper'
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { VenuesImages } from 'src/app/services/venues-config/venues-config.interfaces'

export type Types = 'logo' | 'banners' | 'thumb'
type Action = 'createOriginalAndOptimized' | 'createOptimizedFromOriginal' | 'updateOptimized'
export type CroppedClickedType = {
	imageCropped: ImagesCropped
	imagesConfig: Array<ImagesCropped>
	action: Action
}

export type ImagesCropped = {
	loadImage: boolean
	imageChangedEvent: string | null
	croppedImage: string | null
	imageUrl: string | null
	optimizedUrl: string | null
	uploadOriginal: string | null
	order: number | null
}

const initialState: Array<ImagesCropped> = [
	{
		loadImage: false,
		imageChangedEvent: null,
		croppedImage: null,
		imageUrl: null,
		optimizedUrl: null,
		uploadOriginal: null,
		order: null,
	},
]

@Component({
	selector: 'app-logo',
	templateUrl: './logo.component.html',
	styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnChanges {
	@Input() type!: Types
	@Input() images!: VenuesImages | null
	@Input() partner!: any
	@ViewChild(ImageCropperComponent) imageCropper!: ImageCropperComponent
	@Output() croppedClicked = new EventEmitter<CroppedClickedType>()
	@Output() saveOrder = new EventEmitter<Array<Pick<ImagesCropped, 'imageUrl' | 'order'>>>()
	@Output() saveOriginalImage = new EventEmitter()
	@Output() deleteImage = new EventEmitter<{ imageUrl: string }>()
	aspectRatio: number = 1

	@Input() timestamp = Date.now()

	imagesConfig: Array<ImagesCropped> = initialState
	widthPartner!: number
	heightPartner!: number

	constructor() {}

	ngOnChanges(): void {
		const images = this.partner?.images
		const image = images ? images[this.type] : undefined

		if (!image) {
			alert('Está faltando configuração de images no parceiro.')
			window.location.href = '/'
			return
		}

		this.widthPartner = image.width
		this.heightPartner = image.height

		if (!this.widthPartner || !this.heightPartner) {
			alert('Está faltando configuração de width e height no parceiro.')
			window.location.href = '/'
			return
		}

		this.aspectRatio = this.widthPartner / this.heightPartner
		if (this.type === 'logo') {
			if (!this.images) return
			this.imagesConfig = [
				{
					loadImage: this.images?.logo?.logoUrl ? true : false,
					imageChangedEvent: '',
					croppedImage: '',
					imageUrl: this.images?.logo?.logoUrl,
					optimizedUrl: this.images?.logo?.optimizedLogoUrl,
					uploadOriginal: null,
					order: 1,
				},
			]
		}
		if (this.type === 'banners') {
			if (this.images?.banners && this.images.banners.length > 0) {
				this.imagesConfig = this.images?.banners.map(db => ({
					loadImage: db.coverUrl ? true : false,
					imageChangedEvent: '',
					croppedImage: '',
					imageUrl: db.coverUrl,
					optimizedUrl: db.optimizedCoverUrl,
					uploadOriginal: null,
					order: db.order,
				}))
			} else {
				this.imagesConfig = [
					{
						loadImage: false,
						imageChangedEvent: '',
						croppedImage: '',
						imageUrl: '',
						optimizedUrl: '',
						uploadOriginal: null,
						order: 1,
					},
				]
			}
		}
		if (this.type === 'thumb') {
			if (this.images?.thumb && this.images.thumb.length > 0) {
				this.imagesConfig = this.images?.thumb.map(db => ({
					loadImage: db.coverUrl ? true : false,
					imageChangedEvent: null,
					croppedImage: null,
					imageUrl: db.coverUrl,
					uploadOriginal: null,
					optimizedUrl: db.optimizedCoverUrl,
					order: db.order,
				}))
			} else {
				this.imagesConfig = [
					{
						loadImage: false,
						imageChangedEvent: '',
						croppedImage: '',
						imageUrl: '',
						optimizedUrl: '',
						uploadOriginal: null,
						order: 1,
					},
				]
			}
		}
	}

	loadImageFromFile(event: any, index: number): void {
		this.imagesConfig[index].loadImage = event.target.files.length > 0
		this.imagesConfig[index].imageChangedEvent = event
	}

	loadImageFailed() {
		this.imagesConfig = this.imagesConfig.slice(0, -1)
	}

	imageCropped(event: ImageCroppedEvent, index: number) {
		const copy = [...this.imagesConfig]
		copy[index].croppedImage = event.base64 as any
		this.imagesConfig = copy
	}

	imageLoaded(event: LoadedImage, index: number) {
		const copy = [...this.imagesConfig]
		copy[index].uploadOriginal = event.original.base64
		this.imagesConfig = copy
	}

	clickCropped(index: number) {
		const config = this.imagesConfig[index]
		this.croppedClicked.emit({
			imageCropped: this.imagesConfig[index],
			imagesConfig: this.imagesConfig,
			action: config.optimizedUrl
				? 'updateOptimized'
				: config.uploadOriginal
				? 'createOriginalAndOptimized'
				: 'createOptimizedFromOriginal',
		})
	}

	sendNewOrder() {
		const newImageOrder = this.imagesConfig.map((image, idx) => ({
			imageUrl: image.imageUrl,
			order: idx + 1,
		}))
		this.saveOrder.emit(newImageOrder)
	}

	uploadMore() {
		this.imagesConfig.push({ ...initialState[0] })
		this.imagesConfig[this.imagesConfig.length - 1].order = this.imagesConfig.length
	}

	clearImage(imageUrl: string): void {
		this.deleteImage.emit({ imageUrl })
	}

	lastImageSelected() {
		if (this.imagesConfig.length === 0) return false
		return this.imagesConfig[this.imagesConfig.length - 1].loadImage
	}

	drop(event: CdkDragDrop<string[]>): void {
		moveItemInArray(this.imagesConfig, event.previousIndex, event.currentIndex)
	}

	get uploadedFileCount(): number {
		return this.imagesConfig.filter(config => config.loadImage).length
	}
}
