<div class="accordion002" [ngClass]="openedAccordion ? '' : 'opened'">
	<div class="accordion002__header" (click)="togglerAccordion()">
		<div class="accordion002__title">
			<h2 class="accordion002__name">{{ name }}</h2>
		</div>
		<div class="accordio002__arrow">
			<i class="accordion002__arrow__icon tagmeicon tagmeicon-seta"></i>
		</div>
	</div>
	<div class="accordion002__body">
		<ng-container *ngTemplateOutlet="tempAccordion"></ng-container>
	</div>
</div>
