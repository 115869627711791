import {
	AfterViewInit,
	Component,
	Directive,
	Input,
	Renderer2,
	TemplateRef,
	ViewContainerRef,
	ViewRef,
} from '@angular/core'

@Component({
	template: `
		<div class="container">
			<app-loading001></app-loading001>
		</div>
	`,
	styles: [
		`
			.container {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 300px;
				height: 300px;
				border-radius: 8px;
				background-color: white;
			}
		`,
	],
})
export class ModalLoading {}

@Directive({
	selector: '[ngLoaded]',
})
export class LoadedDirective {
	@Input()
	public set ngLoaded(loading: boolean) {
		this.viewContainer.clear()
		if (loading) {
			this.viewContainer.createComponent(ModalLoading)
		} else {
			this.viewContainer.createEmbeddedView(this.templateRef)
		}
	}

	constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}
}
