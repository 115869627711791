import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { filter, Subscription } from 'rxjs'

@Component({
	selector: 'app-payment-search',
	templateUrl: './payment-search.component.html',
	styleUrls: ['./payment-search.component.scss'],
})
export class PaymentSearchComponent implements OnInit, OnDestroy {
	form!: UntypedFormGroup

	subs = new Subscription()
	@Output() submitted = new EventEmitter<string>()

	constructor(private fb: UntypedFormBuilder) {}

	ngOnInit(): void {
		this.form = this.fb.group({ name: ['', Validators.required] })
		this.subs.add(this.form.valueChanges.subscribe(form => this.submitted.emit(form.name)))
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	handleSubmit() {
		if (this.form.invalid) {
		}
		this.submitted.emit(this.form.value.name)
	}
}
