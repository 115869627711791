import { Component, OnInit } from '@angular/core'
import { WaitlistAdminService } from './waitlist-admin.service'
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs'

@Component({
	selector: 'app-waitlist-admin',
	templateUrl: './waitlist-admin.component.html',
	styleUrls: ['./waitlist-admin.component.scss'],
})
export class WaitlistAdminComponent implements OnInit {
	public closeModalSearch: boolean = false
	public tooglerVenue: boolean = false
	public modalValidated: boolean = false
	public openedOptions: boolean = true
	public validatedVoucher: boolean = false
	public nemModalValidation: boolean = false
	public validatedVoucherModal: boolean = false
	public openedVenue: boolean = false
	public nameVenues: any = ''
	public minPeopleSelected: any = 0
	public maxPeopleSelected: any = 0
	public voucherValidated: boolean = false
	public seeVoucher: boolean = false
	public openedValidation: boolean = true
	public openedTypes: boolean = false
	public phraseClientSms: any = 'Olá, você está em nossa lista de espera.'
	public phraseCallClient: any = 'A sua vez chegou! Compareça a entrada do nosso restaurante.'
	public phraseLateClient: any = 'Você está atrasado, por favor, venha rápido! ;)'
	public openedFormat: boolean = false
	public openedLate: boolean = false
	public openedEstimatedTime: boolean = false
	public openedTypeCommand: boolean = false
	public openedFirstTime: boolean = false
	public openedSecondTime: boolean = false
	public selectedVenue: string = ''
	selectedVenueUpdate = new Subject<string>()

	public minPeople: boolean = false
	public addLocalStorage: boolean = true
	public maxPeople: boolean = false
	public loading: boolean = false
	public nameVenueSelected: any = ''
	public idVenueSelected: any = ''
	public selectedType: any = 'Escolha o tipo'
	public selectedFormat: any = 'Crescente - Tempo estimado na espera'
	public selectedLate: any = '10 minutos'
	public selectedTypeCommand: any = 'Manual (Hostess inclui na entrada)'
	public selectedFirstTime: any = '9:00'
	public selectedSecondTime: any = '21:00'
	public selectedEstimatedTime: any = 'Crescente - Tempo estimado na espera'
	public value: number = 0
	public openedValidationConfirmed: boolean = false
	public restaurantVenues: any[] = []
	public lastViewed: any[] = []
	public last: any[] = []
	public newLastViewed: any[] = []

	public enabledConfiguration: boolean = false
	public enabledWidget: boolean = false
	public enabledGoogle: boolean = false
	public enabledChannels: boolean = false
	public enabledPosition: boolean = false
	public enabledTime: boolean = false
	public enabledSmsAdmin: boolean = false
	public enabledClientList: boolean = false
	public enabledCallClient: boolean = false
	public enabledLateClient: boolean = false
	public enabledInfoWidget: boolean = false
	public enabledGeneralInformation: boolean = false
	public enabledPriorityQueue: boolean = false
	public enabledNextClient: boolean = false

	public tabContentButton: any[] = [
		{ class: 'inverse', title: 'Sentar', icon: '' },
		{ class: 'button__filter', title: '', icon: 'tagmeicon-close' },
		{ class: 'button__filter', title: '', icon: 'tagmeicon-megafone' },
	]

	public numberMinPeople: any[] = [
		{ value: 0 },
		{ value: 1 },
		{ value: 2 },
		{ value: 3 },
		{ value: 4 },
		{ value: 5 },
		{ value: 6 },
		{ value: 7 },
		{ value: 8 },
		{ value: 9 },
		{ value: 10 },
	]

	public numberMaxPeople: any[] = [
		{ value: 0 },
		{ value: 1 },
		{ value: 2 },
		{ value: 3 },
		{ value: 4 },
		{ value: 5 },
		{ value: 6 },
		{ value: 7 },
		{ value: 8 },
		{ value: 9 },
		{ value: 10 },
	]

	public tabs: any[] = [
		{ title: 'Mesas do Almoço', id: 'lunch' },
		{ title: 'Mesas do Jantar', id: 'dinner' },
	]

	public menus: any[] = [
		{ title: 'Atualizar Página', icon: 'tagmeicon tagmeicon-refresh' },
		{ title: 'Buscar nome do Cliente', icon: 'tagmeicon tagmeicon-buscar' },
		{ title: 'Ver Filtros', icon: 'tagmeicon tagmeicon-filtro' },
		{ title: 'Ocultar Mesas', icon: 'tagmeicon tagmeicon-mesa' },
		{ title: 'Ver Ambientes', icon: 'tagmeicon tagmeicon-pin-24' },
		{ title: 'Painel Semanal', icon: 'tagmeicon tagmeicon-pin-24' },
		{ title: 'Reserva Aberta', icon: 'tagmeicon tagmeicon-pin-24' },
		{ title: 'Adicionar Reserva', icon: 'tagmeicon tagmeicon-pin-24' },
	]

	public selectTypes: any[] = [{ title: 'Múltiplas filas' }, { title: 'Todos os tipos' }]

	public selectFormats: any[] = [
		{ title: 'Crescente - Tempo estimado na espera' },
		{ title: 'Decrescente - Tempo estimado na espera' },
	]

	public selectLateTime: any[] = [{ title: '10 minutos' }, { title: '15 minutos' }, { title: '20 minutos' }]

	public selectFirstTime: any[] = [{ title: '9:00' }, { title: '9:30' }, { title: '10:00' }]

	public selectSecondTime: any[] = [{ title: '21:00' }, { title: '21:30' }, { title: '22:00' }]

	public selectEstimatedTimeOptions: any[] = [
		{ title: 'Crescente - Tempo estimado na espera' },
		{ title: 'Decrescente - Tempo estimado na espera' },
	]

	public selectTypeCommandOptions: any[] = [
		{ title: 'Manual (Hostess inclui na entrada)' },
		{ title: 'Outro tipo de comanda' },
	]

	public menuContentSubtitle: any[] = [
		{ title: 'Mesa Vazia', span: 'empty' },
		{ title: 'Telefone', span: 'phone' },
		{ title: 'Online', span: 'online' },
		{ title: 'Passante', span: 'pass' },
		{ title: 'Confirmada', span: 'confirmed' },
		{ title: 'Sentada', span: 'sitting' },
	]

	public cardButtons: any[] = [
		{ title: 'Perfil do restaurante', icon: 'tagmeicon tagmeicon-home', isOption: true },
		{ title: 'Lista de espera', icon: 'tagmeicon tagmeicon-tempo-24', isOption: true },
		{ title: 'Lista de espera (admin)', icon: 'tagmeicon tagmeicon-tempo-cog-24', isOption: true },
		{ title: 'Reserva', icon: 'tagmeicon tagmeicon-calendario-24', isOption: true },
		{ title: 'Reserva (admin)', icon: 'tagmeicon tagmeicon-calendario-cog-24', isOption: true },
		{ title: 'Passante Check in', icon: 'tagmeicon tagmeicon-pin-24', isOption: true },
		{ title: 'Menu Digital (admin)', icon: 'tagmeicon tagmeicon-app', isOption: true },
		{ title: 'Gestor de pedidos', icon: 'tagmeicon tagmeicon-selo-menu-casual', isOption: true },
		{ title: 'Programas gastronômicos', icon: 'tagmeicon tagmeicon-voucher', isOption: true },
		{ title: 'CRM', icon: 'tagmeicon tagmeicon-megafone', isOption: true },
		{ title: 'Cartela virtual de fidelidade', icon: 'tagmeicon tagmeicon-mesa', isOption: true },
		{ title: 'Logout', icon: 'tagmeicon tagmeicon-logout', isOption: false, isLogout: true },
	]

	public cardsWait: any[] = [
		{ title: 'Bar', icon: 'tagmeicon tagmeicon-bebidas', id: '' },
		{ title: 'Hall', icon: '', id: '' },
		{ title: 'Deck', icon: '', id: '' },
		{ title: 'Dar uma volta', icon: 'tagmeicon tagmeicon-passante-mini', id: '' },
	]

	public cardsAccommodation: any[] = [
		{ title: '2º andar', icon: '', id: '' },
		{ title: 'Varanda', icon: '', id: '' },
		{ title: 'Próx. a janela', icon: '', id: '' },
		{ title: 'Mesa redonda', icon: 'tagmeicon tagmeicon-mesa', id: '' },
	]

	public cardsPriority: any[] = [
		{ title: 'Gestante', icon: 'tagmeicon tagmeicon-gestante-mini', id: '' },
		{ title: 'Lactante', icon: '', id: '' },
		{ title: 'Idoso', icon: '', id: '' },
		{ title: 'PNE', icon: 'tagmeicon tagmeicon-cadeirante-mini', id: '' },
		{ title: 'Autistmo', icon: '', id: '' },
	]

	constructor(private waitlistAdminService: WaitlistAdminService) {
		this.selectedVenueUpdate.pipe(debounceTime(700), distinctUntilChanged()).subscribe(value => {
			this.selectVenue(value)
		})
	}

	ngOnInit(): void {
		const idsVenues = JSON.parse(localStorage.getItem('checkin')!)
		if (idsVenues != null) {
			for (var i = 0; i < 5; i++) {
				if (idsVenues[i]) {
					this.newLastViewed.push(idsVenues[i])
				}
			}
		}
	}

	public infosBenefit(event: any) {
		var a = []
		// a.push(event)
		var num = a.length
		if ('Backspace' && num > 0) {
			num = num - 1
		}
	}

	public async getVenues(nameVenue: any) {
		await this.waitlistAdminService.getVenuesData(nameVenue).subscribe(res => {
			this.lastViewed = Object.values(res)

			for (var i = 0; i < 5; i++) {
				for (var j = 0; j < this.lastViewed.length; j++) {
					if (this.newLastViewed[i] == this.lastViewed[j]._id) {
						this.last.push(this.lastViewed[j])
					}
				}
			}

			this.restaurantVenues = Object.values(res)
			this.loading = false
		})
	}

	public async tabSearchVenue(idVenue: any) {
		this.idVenueSelected = idVenue
		await this.chosenVenue(this.idVenueSelected)
		this.closeModalSearch = true
		this.openModalVenue()
	}

	public async search() {
		if (this.idVenueSelected) {
			this.addVenuesToLocalStorage()
			await this.chosenVenue(this.idVenueSelected)
			this.closeModalSearch = true
			this.openModalVenue()
		}
		this.closeModalSearch = true
		// else{
		//   alert('Escolha uma Venue')
		// }
	}

	public addVenuesToLocalStorage() {
		for (var i = 0; i < this.newLastViewed.length; i++) {
			if (this.newLastViewed[i][0] == this.idVenueSelected) {
				this.addLocalStorage = false
			}
		}

		if (this.addLocalStorage) {
			this.newLastViewed.unshift([this.idVenueSelected, this.nameVenueSelected])
			localStorage.setItem('waitlistAdmin', JSON.stringify(this.newLastViewed))
		}
	}

	public toogleTypes() {
		this.openedTypes = !this.openedTypes
	}

	public toogleFormat() {
		this.openedFormat = !this.openedFormat
	}

	public toogleTypeCommand() {
		this.openedTypeCommand = !this.openedTypeCommand
	}

	public toogleLate() {
		this.openedLate = !this.openedLate
	}

	public toogleEstimatedTime() {
		this.openedEstimatedTime = !this.openedEstimatedTime
	}

	public toogleFirstTime() {
		this.openedFirstTime = !this.openedFirstTime
	}

	public toogleSecondTime() {
		this.openedSecondTime = !this.openedSecondTime
	}

	public toogleMinPeople() {
		this.minPeople = !this.minPeople
	}

	public toogleMaxPeople() {
		this.maxPeople = !this.maxPeople
	}

	public openModalVenue() {
		this.openedVenue = !this.openedVenue
	}

	public openModalOptions() {
		this.openedOptions = !this.openedOptions
	}

	public openModalValidation() {
		this.openedValidation = !this.openedValidation
	}

	public openVenue() {
		this.tooglerVenue = !this.tooglerVenue
	}

	public minPeopleOption(value: any) {
		this.minPeopleSelected = value
		this.toogleMinPeople()
	}

	public maxPeopleOption(value: any) {
		this.maxPeopleSelected = value
		this.toogleMaxPeople()
	}

	public selectType(type: any) {
		this.selectedType = type
		this.toogleTypes()
	}

	public selectTypeCommand(command: any) {
		this.selectedTypeCommand = command
		this.toogleTypeCommand()
	}

	public selectLate(late: any) {
		this.selectedLate = late
		this.toogleLate()
	}

	public selectEstimatedTimeName(estimated: any) {
		this.selectedEstimatedTime = estimated
		this.toogleLate()
	}

	public selectFirstTimeName(firstTime: any) {
		this.selectedFirstTime = firstTime
		this.toogleFirstTime()
	}

	public selectSecondTimeName(secondTime: any) {
		this.selectedSecondTime = secondTime
		this.toogleSecondTime()
	}

	public selectFormat(format: any) {
		this.selectedFormat = format
		this.toogleFormat()
	}

	public confirmValidation() {
		this.nemModalValidation = true
	}

	public voucherValidate() {
		this.voucherValidated = true
		this.openModalValidation()
	}

	public seeVoucherValidated() {
		this.seeVoucher = true
		this.openModalValidation()
	}

	public async chosenVenue(idVenue: any) {
		await this.waitlistAdminService.getInfoVenue(idVenue).subscribe(res => {
			this.nameVenues = (res as any).name
		})
	}

	public async selectVenue(nameVenue: any) {
		if (nameVenue.length > 2) {
			await this.getVenues(nameVenue)
			this.nameVenueSelected = nameVenue
			this.idVenueSelected = this.restaurantVenues.find(item => item.name === this.nameVenueSelected)
			if (this.idVenueSelected) {
				this.idVenueSelected = this.idVenueSelected._id
			}
		}
	}
}
