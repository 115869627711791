import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	OnInit,
	Output,
} from '@angular/core'
import { Order } from 'src/app/interfaces/order.interface'
import { STATUS_ORDER } from 'src/app/interfaces/status.interface'
import { capitalizeFirstLetter } from 'src/shared/util'

@Component({
	selector: 'app-checkin-card',
	templateUrl: './checkin-card.component.html',
	styleUrls: ['./checkin-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckinCardComponent implements OnInit {
	@Input() checkinOrder: Order
	@Output() buttonClicked = new EventEmitter<STATUS_ORDER>()
	template!: STATUS_ORDER | undefined

	text: Record<STATUS_ORDER, any> = {
		CHK_CANCEL: 'Recusado',
		VALIDATED: 'Validado',
	}
	ngOnInit(): void {
		this.setTemplate()
	}

	private setTemplate() {
		if (this.checkinOrder.status === 'VALIDATED') {
			this.template = 'VALIDATED'
		} else if (this.checkinOrder.status === 'CHK_CANCEL') {
			this.template = 'CHK_CANCEL'
		} else {
			this.template = undefined
		}
	}

	@HostBinding('style')
	get addProps() {
		if (this.template) {
			const config: Record<STATUS_ORDER, any> = {
				CHK_CANCEL: { '--color': 'var(--red)', '--bg-color': 'var(--pink6)', '--opacity': '20%' },
				VALIDATED: { '--color': 'var(--green)', '--bg-color': 'var(--greenE)', '--opacity': '20%' },
			}
			return config[this.template]
		}
	}

	clickButton(type: STATUS_ORDER) {
		this.buttonClicked.emit(type)
	}

	get partySize() {
		return this.checkinOrder.partySize === 1 ? '1 pessoa' : `${this.checkinOrder.partySize} pessoas`
	}

	get benefits() {
		if (this.checkinOrder.benefits) {
			return [...this.checkinOrder.benefits]
				.sort((a, b) => a.order - b.order)
				.map(item => ({ name: item.name, value: item.value }))
		}
		return [
			{
				name: capitalizeFirstLetter(this.checkinOrder.voucher.type),
				value: this.checkinOrder.voucher.value,
			},
		]
	}
}
