<div class="page">
	<app-header-master></app-header-master>
	<app-header-venue
		*ngIf="venue$ | async as venue"
		[name]="venue.name"
		[showIcon]="false"
	></app-header-venue>
	<div class="page__body">
		<div class="wrapper">
			<app-breadcrumb></app-breadcrumb>
		</div>

		<div class="boxBody">
			<div class="page__wrapper wrapper">
				<p style="margin: 10px">
					Relatório das reservas e check-in validados do Programa Menu e Celebration
				</p>
				<div class="report-export">
					<div class="spacer"></div>
					<form class="report-export__form" [formGroup]="form" (ngSubmit)="filter()">
						<ng-container>
							<div class="field__label">
								Período Inicial
								<div class="field__wrapper">
									<i class="field__icon left tagmeicon tagmeicon-calendario-24"></i>
									<input
										class="field__input"
										type="date"
										formControlName="startDate"
										name="startDate"
										placeholder="dd/mm/YYYY"
									/>
								</div>
							</div>
							<span class="field__feedback"></span>
							<div class="field__label">
								Período Final
								<div class="field__wrapper">
									<i class="field__icon left tagmeicon tagmeicon-calendario-24"></i>
									<input
										class="field__input"
										type="date"
										placeholder="dd/mm/YYYY"
										formControlName="endDate"
										name="endDate"
									/>
								</div>
							</div>
							<span class="field__feedback"></span>
							<div style="display: flex; gap: 16px">
								<button
									class="report-export__form__button button"
									type="button"
									(click)="filter()"
								>
									Pré-visualizar relatório
								</button>

								<button
									class="report-export__form__button button"
									type="submit"
									(click)="downloadCSV()"
									[disabled]="!reservations"
								>
									Download relatório
								</button>
							</div>
						</ng-container>
					</form>
				</div>
			</div>
			<div class="boxTable" *ngIf="reservations">
				<app-general-table [info]="reservations"></app-general-table>
			</div>
		</div>
	</div>
</div>
