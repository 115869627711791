import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import {
	debounceTime,
	distinctUntilChanged,
	filter,
	Observable,
	of,
	Subscription,
	switchMap,
	tap,
} from 'rxjs'
import { ModalService } from 'src/app/components/modalChannels/modal.service'
import { VenuesCacheService } from '../../payment/venue-cache.service'
import { UserService } from '../../user/user.service'
import { VenuesApi } from '../../../services/venues.service'
import { Venue } from '../../venue-management/venues.interface'
import { OrdersApi } from 'src/app/services/orders.service'
import { VenuesLocalStorage, VenueLocalStorage } from 'src/app/services/venue-ls.service'

@Component({
	templateUrl: './reservation.page.html',
	styleUrls: ['./reservation.page.scss'],
})
export class TagmeReservationPage implements OnInit, OnDestroy, AfterViewInit {
	public isAdmin = this.userService.isAdmin
	public venueId: string | null = null
	public venue$!: Observable<any>

	// Venues
	public venues: Venue | null = null
	public hasVenues: boolean = false

	public searchForm!: UntypedFormGroup
	public orderForm!: UntypedFormGroup
	public modalSearchId = 'modal-search-venue' as const

	// Orders
	public orders: any[] = []

	// Observables and Subscriptions
	public selectedVenue$: Observable<any | null> = of(null)
	public lastVenues$ = this.venuesLocalStorage.getVenuesKey('admin')
	public subs = new Subscription()
	public venuesAdminOptions$ = this.venuesCacheService.venuesCache$
	public modalVenueAdminOptions = 'modalVeuneAdminOptions'

	constructor(
		private userService: UserService,
		private venuesLocalStorage: VenuesLocalStorage,
		private venuesCacheService: VenuesCacheService,
		private venuesService: VenuesApi,
		private modalService: ModalService,
		private fb: UntypedFormBuilder,
		private ordersApi: OrdersApi
	) {}

	ngOnInit(): void {
		this.searchForm = this.fb.group({
			name: ['', Validators.required],
		})
		this.orderForm = this.fb.group({
			status: ['VALIDATED', [Validators.required]],
			partnerId: ['595166383625c506d4868f28', [Validators.required]],
			startDate: [new Date().toISOString().substring(0, 10), [Validators.required]],
			endDate: [new Date().toISOString().substring(0, 10), [Validators.required]],
			intent: ['reservation', [Validators.required]],
			cpf: [],
		})
		const searchControl = this.searchForm.get('name')
		// Suggestions after typing
		this.subs.add(
			searchControl?.valueChanges
				.pipe(
					debounceTime(500),
					filter(name => name.length >= 3),
					distinctUntilChanged(),
					switchMap(name => this.venuesService.getVenues(name))
				)
				.subscribe(venues => {
					this.venues = venues
					this.hasVenues = Boolean(this.venues.length)
				})
		)

		// Erase suggestions after delete content
		this.subs.add(
			searchControl?.valueChanges.pipe(filter(name => name.length < 3)).subscribe(() => {
				this.venues = null
				this.hasVenues = false
			})
		)
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
		this.modalService.close(this.modalSearchId)
	}

	ngAfterViewInit(): void {
		if (!this.venueId && this.userService.isAdmin) {
			this.openModal()
		}
	}

	closeModal() {
		if (!this.venueId) {
			alert('É necessário escolher uma venue.')
			return
		}
		this.modalService.close(this.modalSearchId)
	}

	selectVenue() {
		this.modalService.open(this.modalSearchId)
	}

	openModal() {
		this.modalService.open(this.modalSearchId)
	}

	clickVenues(venue: VenueLocalStorage) {
		this.venue$ = this.venuesCacheService
			.getVenueById(venue._id)
			.pipe(tap(v => this.venuesLocalStorage.addVenuesToLocalStorage(v, 'admin')))

		this.venueId = venue._id

		// this.venuesService.setVenues([])
		this.searchForm.patchValue({ name: '' })
		this.closeModal()
	}

	handleSubmit() {
		const { cpf, partnerId, ...rest } = this.orderForm.value
		const body = cpf ? { cpf, ...rest } : rest
		this.subs.add(
			this.ordersApi.findHistoryAdmin(body, partnerId).subscribe({
				next: orders => {
					this.orders = orders
				},
			})
		)
	}

	eraseHistory() {
		this.venuesLocalStorage.removeVenuesLocalStorage('admin')
	}
}
