import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { UserService } from '../user.service'

@Injectable({ providedIn: 'root' })
export class AuthGuardAdminChild {
	constructor(private userService: UserService, private router: Router) {}
	canActivateChild(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (!this.userService.isLoggedIn) {
			this.userService.setRedirectURL(state.url)
			this.router.navigate(['/login'])
			return false
		}
		return this.userService.isAdmin || this.userService.isConcierge
	}
}
