import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import {
	debounceTime,
	distinctUntilChanged,
	filter,
	Observable,
	of,
	Subscription,
	switchMap,
	tap,
} from 'rxjs'
import { Card } from 'src/app/components/generic-card/generic-card.component'
import { ModalService } from 'src/app/components/modalChannels/modal.service'
import { UserService } from '../../user/user.service'
import { VenuesApi } from '../../../services/venues.service'
import { Venue } from '../../venue-management/venues.interface'
import { VenuesCacheService } from '../venue-cache.service'
import { VenuesLocalStorage, VenueLocalStorage } from 'src/app/services/venue-ls.service'
import { VenuesConfigApi } from 'src/app/services/venues-config/venues-config.service'
import { PartnersId } from 'src/shared/util'

@Component({
	templateUrl: './venue-home.page.html',
	styleUrls: ['./venue-home.page.scss'],
})
export class VenueHomePage implements OnInit, OnDestroy, AfterViewInit {
	public isAdmin = this.userService.isAdmin
	public venueId: string | null = null
	public venue$!: Observable<any>

	// Venues
	public venues: Venue | null = null
	public hasVenues: boolean = false

	public searchForm!: UntypedFormGroup
	public modalSearchId = 'modal-search-venue' as const

	// Observables and Subscriptions
	public selectedVenue$: Observable<any | null> = of(null)
	public lastVenues$ = this.venuesLocalStorage.getVenuesKey('admin')
	public subs = new Subscription()
	public venuesAdminOptions$ = this.venuesCacheService.venuesCache$
	public modalVenueAdminOptions = 'modalVeuneAdminOptions'
	partnersId = PartnersId
	has = {
		[PartnersId.BRADESCO]: false,
		[PartnersId.ITAU]: false,
	}

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private userService: UserService,
		private venuesLocalStorage: VenuesLocalStorage,
		private venuesCacheService: VenuesCacheService,
		private venuesService: VenuesApi,
		private modalService: ModalService,
		private fb: UntypedFormBuilder,
		private venuesConfigApi: VenuesConfigApi
	) {}

	ngOnInit(): void {
		this.searchForm = this.fb.group({
			name: ['', Validators.required],
		})

		const searchControl = this.searchForm.get('name')
		// Suggestions after typing
		this.subs.add(
			searchControl?.valueChanges
				.pipe(
					debounceTime(500),
					filter(name => name.length >= 3),
					distinctUntilChanged(),
					switchMap(name => this.venuesService.getVenues(name))
				)
				.subscribe(venues => {
					this.venues = venues
					this.hasVenues = Boolean(this.venues.length)
				})
		)

		// Erase suggestions after delete content
		this.subs.add(
			searchControl?.valueChanges.pipe(filter(name => name.length < 3)).subscribe(() => {
				this.venues = null
				this.hasVenues = false
			})
		)

		this.activatedRoute.params.subscribe(params => {
			this.venueId = params['venueId']
			if (!this.venueId) {
			} else {
				this.venue$ = this.venuesCacheService.getVenueById(this.venueId)
				this.verifyPartners(this.venueId)
			}

			if (this.userService.isVenueAdmin) {
				const user = this.userService.getUser as any
				const getVenueIds = user?.venueIds.filter(venueId => venueId !== this.venueId)
				this.venuesCacheService.addVenuesId(getVenueIds)
			}
		})
	}

	private verifyPartners(venueId: string) {
		// temporarily removing ITAU until back is ready
		for (const partnerId of [PartnersId.BRADESCO]) {
			this.venuesConfigApi.isEnabled({ venueId, partnerId }).subscribe({
				next: res => {
					this.has[partnerId] = res.enabled
				},
				error: () => {
					this.has[partnerId] = false
				},
			})
		}
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
		this.modalService.close(this.modalSearchId)
	}

	ngAfterViewInit(): void {
		if (!this.venueId && this.userService.isAdmin) {
			this.openModal()
		}
	}

	closeModal() {
		if (!this.venueId) {
			alert('É necessário escolher uma venue.')
			return
		}
		this.modalService.close(this.modalSearchId)
	}

	selectVenue() {
		if (this.userService.isAdmin) {
			this.modalService.open(this.modalSearchId)
			return
		}
		if (this.userService.isVenueAdmin) {
			this.modalService.open(this.modalVenueAdminOptions)
		}
	}

	selectVenueVenueAdmin(venueId: string) {
		this.verifyPartners(venueId)
		this.venue$ = this.venuesCacheService.selectVenueById(venueId)
		this.router.navigate([`/venue/${venueId}`])
		this.modalService.close(this.modalVenueAdminOptions)
	}

	openModal() {
		this.modalService.open(this.modalSearchId)
	}

	clickVenues(venue: VenueLocalStorage) {
		this.verifyPartners(venue._id)
		this.venue$ = this.venuesCacheService
			.getVenueById(venue._id)
			.pipe(tap(v => this.venuesLocalStorage.addVenuesToLocalStorage(v, 'admin')))

		this.venueId = venue._id

		// this.venuesService.setVenues([])
		this.searchForm.patchValue({ name: '' })
		this.closeModal()
	}

	eraseHistory() {
		this.venuesLocalStorage.removeVenuesLocalStorage('admin')
	}
}
