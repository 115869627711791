import { LOCALE_ID, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { NgxCurrencyModule } from 'ngx-currency'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { LoginComponent } from './page/user/login/login.component'
import { ProjectsComponent } from './page/projects/projects.component'
import { BenefitComponent } from './page/benefit/benefit.component'
import { CategoryComponent } from './page/category/category.component'
import { VenuesManagementPage } from './page/venue-management/venue-management/venue-management.component'
import { ReservationComponent } from './page/reservation/reservation.component'
import { ReservationAdminComponent } from './page/reservation-admin/reservation-admin.component'
import { WaitlistComponent } from './page/waitlist/waitlist.component'
import { WaitlistAdminComponent } from './page/waitlist-admin/waitlist-admin.component'
import { ConciergeComponent } from './page/concierge/concierge.component'
import { ConciergeAdminComponent } from './page/concierge-admin/concierge-admin.component'
import { ReportBradescoComponent } from './page/report/report-menu-bradesco/report.component'
import { AccordionComponent } from './components/accordion/accordion.component'
import { Accordion001Component } from './components/accordion/accordion001/accordion001.component'
import { Accordion002Component } from './components/accordion/accordion002/accordion002.component'
import { CardComponent } from './components/card/card.component'
import { Card001Component } from './components/card/card001/card001.component'
import { Card002Component } from './components/card/card002/card002.component'
import { TagsComponent } from './components/tags/tags.component'
import { Tags001Component } from './components/tags/tags001/tags001.component'
import { Tags002Component } from './components/tags/tags002/tags002.component'
import { CardDescriptionComponent } from './components/card-description/card-description.component'
import { TabComponent } from './components/tab/tab.component'
import { BackButtonComponent } from './components/back-button/back-button.component'
import { ModalComponent } from './components/modal/modal.component'
import { Modal001Component } from './components/modal/modal001/modal001.component'
import { CreateCategoryComponent } from './page/category/create-category/create-category.component'
import { CreateConciergeAdminComponent } from './page/concierge-admin/create-concierge-admin/create-concierge-admin.component'
import { HeaderMasterComponent } from './components/header-master/header-master.component'
import { Tab001Component } from './components/tab/tab001/tab001.component'
import { Concierge001Component } from './components/concierge/concierge001/concierge001.component'
import { LoadingComponent } from './components/loading/loading.component'
import { Loading001Component } from './components/loading/loading001/loading001.component'
import { ReportExportBradescoComponent } from './page/report/report-export-bradesco/report-export.component'
import { Card003Component } from './components/card/card003/card003.component'
import { GeneralBenefitComponent } from './page/benefit/general-benefit/general-benefit.component'
import { ModalComponentChannels } from './components/modalChannels/modal.component'
import { CheckinCardComponent } from './components/checkin/checkin-card/checkin-card.component'
import { GiftCardComponent } from './components/checkin/gift-card/gift-card.component'
import { CheckinHeaderComponent } from './components/checkin/checkin-header/checkin-header.component'
import { CheckinPage } from './page/checkin/checkin.page'
import { CheckinModalComponent } from './components/checkin/checkin-modal/checkin-modal.component'
import { CheckInPopUp } from './components/checkin/checkin-popup/checkin-popup.component'
import { CalendarComponent } from './components/calendar/calendar.component'
import { CalendarMultiComponent } from './components/calendar-multi/calendar-multi.component'
import { TableComponent } from './components/table/table.component'

import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component'
import { SelectBoxComponent } from './components/select-box/select-box.component'

// Venue
import { HeaderVenueComponent } from './components/header-venue/header-venue.component'
import { VenueHomePage } from './page/payment/venue-home/venue-home.page'
import { GenericCardComponent } from './components/generic-card/generic-card.component'
import { LogoComponent } from './components/logo/logo.component'
import { ImageCropperModule } from 'ngx-image-cropper'

// Venue - Payments
import { PaymentButtonComponent } from './components/payment/payment-button/payment-button.component'
import { PaymentInfoPage } from './page/payment/payment-info/payment-info.page'
import { PaymentFilterComponent } from './components/payment/payment-filter-info/payment-filter-info.component'
import { PaymentInfosComponent } from './components/payment/payment-infos/payment-infos.component'
import { PaymentDetailsComponent } from './components/payment/payment-details/payment-details.component'
import { PaymentDetailsPage } from './page/payment/payment-details/payment-details.page'
import { PaymentFilterDetails } from './components/payment/payment-filter-details/payment-filter-details.component'
import { PaymentSearchComponent } from './components/payment/payment-search/payment-search.component'
import { PaymentCardComponent } from './components/payment/payment-card/payment-card.component'
import { PaymentPrintComponent } from './components/payment/payment-print/payment-print.component'

import { registerLocaleData } from '@angular/common'
import { ModalVenueComponent } from './components/modal-components/modal-venue/modal-venue.component'
import { PaymentRefundModalComponent } from './page/payment/payment-refund-modal/payment-refund-modal.component'
import localePT from '@angular/common/locales/pt'
import { OrderComponent } from './page/order/order.component'
import { OrderBoxComponent } from './components/order-box/order-box.component'
registerLocaleData(localePT)

import { TokenInterceptor } from './interceptors/auth.interceptor'
import { CustomHeadersInterceptor } from './interceptors/custom-headers.interceptor'
import { TagmeReservationPage } from './page/tagme/reservation/reservation.page'
import { TagmeSingleVoucherPage } from './page/tagme/single-voucher/single-voucher.page'
import { FileSaverModule } from 'ngx-filesaver'
import { EditCheckinPage } from './page/venue-management/edit-checkin/edit-checkin.page'
import { EditEmailsPage } from './page/venue-management/edit-email/edit-email.page'
import { TagmeSyncVenuePage } from './page/tagme/sync-venue/sync-venue.page'
import { ModalSimpleComponent } from './components/modal-components/modal-simple/modal-simple.component'
import { EditPaymentsPage } from './page/venue-management/edit-payment/edit-payment.page'
import { ModalPartnerComponent } from './components/modal-components/modal-partner/modal-partner.component'
import { EditTypesPage } from './page/venue-management/edit-types/edit-types.page'
import { SandBoxPage } from './page/sandbox/sandbox.page'
import { ItauVouchersPage } from './page/itau/vouchers/vouchers.page'
import { ItauSingleVoucherPage } from './page/itau/single-voucher/single-voucher.page'
import { AbsolutePipe } from 'src/shared/pipes/absolute-path.pipe'
import { EditImagesPage } from './page/venue-management/edit-images/edit-images.page'
import { LoadedDirective, ModalLoading } from './directives/loading.directive'
import { SortArrayPipe } from 'src/shared/pipes/sort-array.pipe'
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask'

//new reports
import { VenueReportsOrdersBradescoPage } from './page/report/report-orders-bradesco/report-orders.page'
import { GeneralTableComponent } from './components/general-table/general-table.component'
import { VenueReportsOrdersMpPage } from './page/report/report-orders-mp/report-orders.page'
import { ReportItauComponent } from './page/report/report-menu-itau/report.component'
import { ReportExportItauComponent } from './page/report/report-export-itau/report-export.component'
import { VouchersBBIPage } from './page/bradesco/vouchers-bbi/vouchers-bbi.page'

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		ProjectsComponent,
		BenefitComponent,
		CategoryComponent,
		VenuesManagementPage,
		ReservationComponent,
		ReservationAdminComponent,
		WaitlistComponent,
		WaitlistAdminComponent,
		GeneralBenefitComponent,
		ConciergeComponent,
		ConciergeAdminComponent,
		ReportBradescoComponent,
		ReportItauComponent,
		AccordionComponent,
		Accordion001Component,
		Accordion002Component,
		CardComponent,
		Card001Component,
		Card002Component,
		TagsComponent,
		Tags001Component,
		Tags002Component,
		CardDescriptionComponent,
		TabComponent,
		BackButtonComponent,
		ModalComponent,
		Modal001Component,
		CreateCategoryComponent,
		CreateConciergeAdminComponent,
		HeaderMasterComponent,
		Tab001Component,
		Concierge001Component,
		LoadingComponent,
		Loading001Component,
		ReportExportBradescoComponent,
		ReportExportItauComponent,
		Card003Component,
		ModalComponentChannels,
		CheckinPage,
		CheckinCardComponent,
		GiftCardComponent,
		CheckinHeaderComponent,
		CheckinModalComponent,
		CheckInPopUp,
		CalendarComponent,
		CalendarMultiComponent,
		BreadcrumbComponent,
		VenueHomePage,
		PaymentButtonComponent,
		PaymentSearchComponent,
		PaymentInfoPage,
		PaymentDetailsPage,
		PaymentFilterComponent,
		PaymentFilterDetails,
		PaymentInfosComponent,
		PaymentDetailsComponent,
		PaymentCardComponent,
		SelectBoxComponent,
		GenericCardComponent,
		HeaderVenueComponent,
		ModalVenueComponent,
		PaymentRefundModalComponent,
		TagmeReservationPage,
		PaymentPrintComponent,
		EditCheckinPage,
		LogoComponent,
		TagmeSyncVenuePage,
		ModalSimpleComponent,
		EditPaymentsPage,
		ModalPartnerComponent,
		EditTypesPage,
		EditEmailsPage,
		SandBoxPage,
		ItauVouchersPage,
		ItauSingleVoucherPage,
		EditImagesPage,
		AbsolutePipe,
		LoadedDirective,
		ModalLoading,
		SortArrayPipe,
		TableComponent,
		TagmeSingleVoucherPage,
		OrderComponent,
		OrderBoxComponent,
		VenueReportsOrdersBradescoPage,
		VenueReportsOrdersMpPage,
		GeneralTableComponent,
		VouchersBBIPage,
	],
	imports: [
		BrowserModule,
		ReactiveFormsModule,
		HttpClientModule,
		AppRoutingModule,
		FormsModule,
		FileSaverModule,
		ImageCropperModule,
		DragDropModule,
		NgxCurrencyModule,
		NgxMaskDirective,
		NgxMaskPipe,
	],
	providers: [
		{ provide: LOCALE_ID, useValue: 'pt-br' },
		{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: CustomHeadersInterceptor, multi: true },
		AbsolutePipe,
		provideNgxMask(),
	],

	bootstrap: [AppComponent],
})
export class AppModule {}
