import { Injectable } from '@angular/core'
import { BehaviorSubject, map, tap } from 'rxjs'

export type LOCAL_KEYS = 'bradesco' | 'esfera' | 'admin' | 'default' | 'latam' | 'bb'

export type VenueLocalStorage = {
	_id: string
	name: string
	partner: boolean
}

type VenuesLS = Record<LOCAL_KEYS, VenueLocalStorage[]>

const initValue: VenuesLS = { bradesco: [], admin: [], default: [], esfera: [], latam: [], bb: [] }

@Injectable({ providedIn: 'root' })
export class VenuesLocalStorage {
	private localStorageVenues = new BehaviorSubject<VenuesLS>(this.getVenuesLocalStorage())
	localStorageVenues$ = this.localStorageVenues.asObservable()

	constructor() {}

	getVenuesKey(key: LOCAL_KEYS) {
		return this.localStorageVenues$.pipe(
			map(venuesLS => {
				return venuesLS ? venuesLS[key] : null
			})
		)
	}

	addVenuesToLocalStorage(obj: VenueLocalStorage, key: LOCAL_KEYS) {
		const currentVenuesLS = this.localStorageVenues.getValue()
		let venuesKey = currentVenuesLS[key]
		if (!venuesKey) {
			venuesKey = []
			this.localStorageVenues.next({ ...currentVenuesLS, [key]: venuesKey })
		}
		// // Don't add if find venue already in localStorage
		const findVenue = venuesKey.find(venue => venue._id === obj._id)
		if (findVenue) {
			return
		}
		const newKeyVenues = [obj, ...venuesKey]
		// // Remove the last element if the length is greater than 5
		if (newKeyVenues.length > 5) {
			newKeyVenues.pop()
		}
		const newVenues = {
			...currentVenuesLS,
			[key]: newKeyVenues,
		}
		// Add new names to localStorage
		localStorage.setItem('venues-ls', JSON.stringify(newVenues))
		this.localStorageVenues.next(newVenues)
	}

	getVenuesLocalStorage(): VenuesLS {
		const venuesLS = localStorage.getItem('venues-ls')
		if (venuesLS) {
			return JSON.parse(venuesLS)
		}
		return initValue
	}

	removeVenuesLocalStorage(key: LOCAL_KEYS): void {
		const currentVenuesLS = this.localStorageVenues.getValue()
		const newVenues = {
			...currentVenuesLS,
			[key]: [],
		}

		// Add new names to localStorage
		localStorage.setItem('venues-ls', JSON.stringify(newVenues))
		this.localStorageVenues.next(newVenues)
	}
}
