import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { tap } from 'rxjs'
import { environment as env } from '../../../../environments/environment'
import { ILoginDto, UserResponse } from '../types/user'
import { UserService } from '../user.service'

@Injectable({
	providedIn: 'root',
})
export class LoginService {
	constructor(private http: HttpClient, private userService: UserService) {}

	public login(body: ILoginDto) {
		return this.http
			.post<UserResponse>(`${env.channelsAPI.baseURL}auth/admin`, body)
			.pipe(tap(user => this.userService.setUser(user)))
	}
}
