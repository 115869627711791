import { Component, OnDestroy, OnInit } from '@angular/core'
import { VenuesManagementService } from '../venues-management.service'
import { Router } from '@angular/router'
import { VenuesConfigApi } from 'src/app/services/venues-config/venues-config.service'
import { ModalService } from 'src/app/components/modalChannels/modal.service'
import { UpdateTypesDto } from 'src/app/services/venues-config/venues-config.interfaces'
import { Subject, takeUntil } from 'rxjs'

@Component({
	templateUrl: 'edit-types.page.html',
	styleUrls: ['edit-types.page.scss'],
})
export class EditTypesPage implements OnInit, OnDestroy {
	venueId: string | null = this.venuesManagementService.venueId
	partnerId: string | null = this.venuesManagementService.partnerId
	errorMessage: string | null = null
	types: { type: string }[] | null = null
	newType: string = ''

	modal = {
		error: 'modalErrorId',
		success: 'modalSuccessId',
	}

	private destroy$ = new Subject<void>()

	constructor(
		private venuesManagementService: VenuesManagementService,
		private router: Router,
		private venuesConfigApi: VenuesConfigApi,
		private modalService: ModalService
	) {}

	ngOnInit(): void {
		this.updateForm()
	}

	ngOnDestroy(): void {
		this.destroy$.next()
		this.destroy$.complete()
	}

	private updateForm() {
		this.venuesManagementService.ensurePrimaryKey()
		this.venuesManagementService.venueConfig$.pipe(takeUntil(this.destroy$)).subscribe({
			next: venueConfig => {
				if (!venueConfig?.types) return
				const { types } = venueConfig
				if (!types) return
				this.types = types
			},
			error: err => {
				this.errorMessage = err.error.message
				this.modalService.open(this.modal.error)
			},
		})
	}

	addType() {
		if (!this.newType || !this.types) return
		this.types = [...this.types, { type: this.newType }]
		this.newType = ''
	}

	removeType(index: number) {
		if (!this.types) return
		const newArrayTypes = [...this.types]
		newArrayTypes.splice(index, 1)
		this.types = newArrayTypes
	}

	save() {
		const updateTypes = {
			partnerId: this.partnerId,
			venueId: this.venueId,
			types: this.types,
		} as UpdateTypesDto
		this.venuesConfigApi.updateTypes(updateTypes).subscribe({
			next: () => {
				this.modalService.open(this.modal.success)
			},
			error: err => {
				this.errorMessage = err.error.message
				this.modalService.open(this.modal.error)
			},
		})
	}
}
