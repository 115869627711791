import { DOCUMENT } from '@angular/common'
import {
	Component,
	ElementRef,
	Input,
	OnInit,
	OnDestroy,
	ViewChild,
	Inject,
	HostBinding,
	Renderer2,
} from '@angular/core'
import { ModalService } from './modal.service'

@Component({
	selector: 'ch-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
})
export class ModalComponentChannels implements OnInit, OnDestroy {
	@Input() id!: string
	@Input() preventOutsideClickClose?: boolean = false
	@Input() top?: string = 'inherit'
	@Input() bottom?: string = 'inherit'
	@Input() animation: 'horizontal' | 'vertical' | 'vertical-i' = 'vertical'
	@ViewChild('modal') modal!: ElementRef

	constructor(
		private modalService: ModalService,
		private el: ElementRef,
		private renderer: Renderer2,
		@Inject(DOCUMENT) private document: Document
	) {}

	ngOnInit(): void {
		if (!this.id) {
			console.error('modal must have an id')
			return
		}

		// Add this element on document
		this.renderer.appendChild(this.document.body, this.el.nativeElement)

		// add self (this modal instance) to the modal service so it's accessible from controllers
		this.modalService.add(this)
	}

	@HostBinding('style')
	get addProps() {
		const configPosition = {
			'--modal-top': this.top,
			'--modal-bottom': this.bottom,
		}

		const configAnimation = {
			vertical: {
				'--time': '200ms',
				'--animation-opened': 'toBottom',
				'--animation-closed': 'toTop',
			},
			horizontal: {
				'--time': '1s',
				'--animation-opened': 'toLeft',
				'--animation-closed': 'toRight',
			},
			'vertical-i': {
				'--time': '200ms',
				'--animation-opened': 'toTop-i',
				'--animation-closed': 'toBottom-i',
			},
		}
		return { ...configPosition, ...configAnimation[this.animation] }
	}

	// remove self from modal service when component is destroyed
	ngOnDestroy(): void {
		this.modalService.remove(this.id)
		this.renderer.removeChild(this.document.body, this.el.nativeElement)
	}

	open(): void {
		this.renderer.addClass(this.modal.nativeElement, 'opened')
	}

	closeOutside(): void {
		if (this.preventOutsideClickClose) {
			return
		}
		this.renderer.removeClass(this.modal.nativeElement, 'opened')
	}

	close(): void {
		this.renderer.removeClass(this.modal.nativeElement, 'opened')
	}
}
