<div class="waitlist-admin" [ngClass]="openedVenue ? 'opened' : ''">
	<!-- MODAL SELECT VENUE -->
	<app-modal slug="001" [closeModalSearch]="closeModalSearch" [typeModal]="'venue'">
		<div class="modal__content" content>
			<p class="modal__text">Selecione um estabelecimento (Venue)</p>
			<div class="modal__select" [ngClass]="tooglerVenue ? 'opened' : ''" (click)="openVenue()">
				<label class="modal__label">
					<input
						class="modal__input"
						list="brow"
						type="text"
						[(ngModel)]="selectedVenue"
						name="selectedVenue"
						placeholder="Digite o nome aqui"
						(ngModelChange)="this.selectedVenueUpdate.next($event)"
						value="{{ nameVenueSelected }}"
					/>
					<i class="modal__icon tagmeicon tagmeicon-buscar" (click)="search()"></i>
				</label>
				<datalist id="brow" class="modal__select__list">
					<option
						class="modal__select__item"
						*ngFor="let item of restaurantVenues"
						value="{{ item.name }}"
					></option>
				</datalist>
			</div>
			<div class="modal__last-viewed">
				<p class="modal__last-viewed__title">Últimos visualizados</p>
				<app-loading *ngIf="loading" class="loading" slug="001"></app-loading>
				<ng-container *ngIf="!loading">
					<ul class="tab001" *ngFor="let item of newLastViewed">
						<li class="tab001__item" (click)="tabSearchVenue(item[0])">
							{{ item[1] }}
						</li>
					</ul>
				</ng-container>
			</div>
		</div>
	</app-modal>

	<!-- MODAL OPTIONS -->
	<app-modal slug="001" [closeModalSearch]="openedOptions" [typeModal]="''">
		<div class="modal__content" content>
			<div class="modal__grid">
				<div class="modal__column" *ngFor="let cards of cardButtons">
					<app-card
						slug="001"
						[nameCards]="cards.title"
						[iconCards]="cards.icon"
						[idCards]="cards.id"
						[isOption]="cards.isOption"
						[isLogout]="cards.isLogout"
					>
					</app-card>
				</div>
			</div>
		</div>
	</app-modal>

	<div class="page">
		<app-header-master></app-header-master>

		<div class="page__header">
			<div class="page__wrapper wrapper">
				<div class="waitlist-admin__header">
					<div class="waitlist-admin__header__name" (click)="openModalVenue()">
						<p class="waitlist-admin__header__name__title">{{ nameVenues }}</p>
						<i class="waitlist-admin__header__name__icon tagmeicon tagmeicon-seta"></i>
					</div>
					<i
						class="waitlist-admin__header__icon tagmeicon tagmeicon-options-dots"
						(click)="openModalOptions()"
					></i>
				</div>
			</div>
		</div>
		<div class="page__body">
			<div class="page__wrapper wrapper">
				<div class="waitlist-admin__body">
					<!-- FILA PRESENCIAL -->
					<div class="waitlist-admin__box">
						<app-accordion slug="002" name="Fila presencial" [tempAccordion]="tempAccordion0">
							<ng-template #tempAccordion0>
								<div class="waitlist-admin__alert">
									Capacidade máxima de 99 pessoas na fila de espera.
								</div>
								<div class="waitlist-admin__select" [ngClass]="openedTypes ? 'opened' : ''">
									<label class="waitlist-admin__label"> Tipo </label>
									<div class="waitlist-admin__select__txt" (click)="toogleTypes()">
										{{ selectedType }}
									</div>
									<ul class="waitlist-admin__select__list">
										<li
											class="waitlist-admin__select__item"
											*ngFor="let item of selectTypes"
											(click)="selectType(item.title)"
										>
											{{ item.title }}
										</li>
									</ul>
									<button class="waitlist-admin__button button__light">
										Adicionar nova fila
									</button>
								</div>
								<div>
									<div class="waitlist-admin__num-of-people">
										<div class="waitlist-admin__num-of-people__title">
											<p class="waitlist-admin__num-of-people__text">
												Mínimo de pessoas
											</p>
											<p class="waitlist-admin__num-of-people__text">
												Máximo de pessoas
											</p>
										</div>
										<div class="waitlist-admin__form">
											<div class="waitlist-admin__start">
												<div
													class="waitlist-admin__select"
													[ngClass]="minPeople ? 'opened' : ''"
												>
													<div
														class="waitlist-admin__select__txt"
														(click)="toogleMinPeople()"
													>
														{{ minPeopleSelected }}
													</div>
													<ul class="waitlist-admin__select__list">
														<li
															class="waitlist-admin__select__item"
															*ngFor="let item of numberMinPeople"
															(click)="minPeopleOption(item.value)"
														>
															{{ item.value }}
														</li>
													</ul>
												</div>
											</div>
											<div class="waitlist-admin__end">
												<div
													class="waitlist-admin__select"
													[ngClass]="maxPeople ? 'opened' : ''"
												>
													<div
														class="waitlist-admin__select__txt"
														(click)="toogleMaxPeople()"
													>
														{{ maxPeopleSelected }}
													</div>
													<ul class="waitlist-admin__select__list">
														<li
															class="waitlist-admin__select__item"
															*ngFor="let item of numberMaxPeople"
															(click)="maxPeopleOption(item.value)"
														>
															{{ item.value }}
														</li>
													</ul>
												</div>
											</div>
											<div class="waitlist-admin__link">Excluir</div>
										</div>
									</div>
								</div>
							</ng-template>
						</app-accordion>
					</div>

					<!-- FILA ONLINE -->
					<div class="waitlist-admin__box">
						<app-accordion slug="002" name="Fila online" [tempAccordion]="tempAccordion1">
							<ng-template #tempAccordion1>
								<div class="waitlist-admin__alert">
									IMPORTANTE: A regra para a Fila Online é a mesma configurada na fila
									presencial.
								</div>
								<!-- SWITCH -->
								<label class="switch">
									<input
										type="checkbox"
										class="switch__input"
										name="enabled-configuration"
										[ngModel]="false"
									/>
									<div class="switch__round"></div>
									<p class="switch__txt">Confirguração automática (Desligado)</p>
								</label>

								<div class="waitlist-admin__block">
									<!-- SWITCH -->
									<label class="switch">
										<input
											type="checkbox"
											class="switch__input"
											name="enabled-widget"
											[ngModel]="false"
										/>
										<div class="switch__round"></div>
										<p class="switch__txt">Widget Tagme (Ligado)</p>
									</label>
									<div class="waitlist-admin__num-of-people">
										<div class="waitlist-admin__num-of-people__title">
											<p class="waitlist-admin__num-of-people__text">
												Mínimo de pessoas
											</p>
											<p class="waitlist-admin__num-of-people__text">
												Máximo de pessoas
											</p>
										</div>
										<div class="waitlist-admin__form">
											<div class="waitlist-admin__start">
												<div class="waitlist-admin__select">
													<div class="waitlist-admin__select__txt">0</div>
													<ul class="waitlist-admin__select__list">
														<li class="waitlist-admin__select__item">1</li>
													</ul>
												</div>
											</div>
											<div class="waitlist-admin__end">
												<div class="waitlist-admin__select">
													<div class="waitlist-admin__select__txt">0</div>
													<ul class="waitlist-admin__select__list">
														<li class="waitlist-admin__select__item">2</li>
													</ul>
												</div>
											</div>
											<div class="waitlist-admin__link">Excluir</div>
										</div>
									</div>
								</div>

								<div class="waitlist-admin__block">
									<!-- SWITCH -->
									<label class="switch">
										<input
											type="checkbox"
											class="switch__input"
											name="enabled-google"
											[ngModel]="false"
										/>
										<div class="switch__round"></div>
										<p class="switch__txt">Google (Ligado)</p>
									</label>
									<div class="waitlist-admin__num-of-people">
										<div class="waitlist-admin__num-of-people__title">
											<p class="waitlist-admin__num-of-people__text">
												Mínimo de pessoas
											</p>
											<p class="waitlist-admin__num-of-people__text">
												Máximo de pessoas
											</p>
										</div>
										<div class="waitlist-admin__form">
											<div class="waitlist-admin__start">
												<div class="waitlist-admin__select">
													<div class="waitlist-admin__select__txt">0</div>
													<ul class="waitlist-admin__select__list">
														<li class="waitlist-admin__select__item">1</li>
													</ul>
												</div>
											</div>
											<div class="waitlist-admin__end">
												<div class="waitlist-admin__select">
													<div class="waitlist-admin__select__txt">0</div>
													<ul class="waitlist-admin__select__list">
														<li class="waitlist-admin__select__item">2</li>
													</ul>
												</div>
											</div>
											<div class="waitlist-admin__link">Excluir</div>
										</div>
									</div>
								</div>

								<div class="waitlist-admin__block">
									<!-- SWITCH -->
									<label class="switch">
										<input
											type="checkbox"
											class="switch__input"
											name="enabled-channels"
											[ngModel]="false"
										/>
										<div class="switch__round"></div>
										<p class="switch__txt">Canais parceiros (Ligado)</p>
									</label>
									<div class="waitlist-admin__num-of-people">
										<div class="waitlist-admin__num-of-people__title">
											<p class="waitlist-admin__num-of-people__text">
												Mínimo de pessoas
											</p>
											<p class="waitlist-admin__num-of-people__text">
												Máximo de pessoas
											</p>
										</div>
										<div class="waitlist-admin__form">
											<div class="waitlist-admin__start">
												<div class="waitlist-admin__select">
													<div class="waitlist-admin__select__txt">0</div>
													<ul class="waitlist-admin__select__list">
														<li class="waitlist-admin__select__item">1</li>
													</ul>
												</div>
											</div>
											<div class="waitlist-admin__end">
												<div class="waitlist-admin__select">
													<div class="waitlist-admin__select__txt">0</div>
													<ul class="waitlist-admin__select__list">
														<li class="waitlist-admin__select__item">2</li>
													</ul>
												</div>
											</div>
											<div class="waitlist-admin__link">Excluir</div>
										</div>
									</div>
								</div>
							</ng-template>
						</app-accordion>
					</div>

					<!-- OPERACIONAL -->
					<div class="waitlist-admin__box">
						<app-accordion slug="002" name="Operacional" [tempAccordion]="tempAccordion2">
							<ng-template #tempAccordion2>
								<!-- SWITCH -->
								<label class="switch">
									<input
										type="checkbox"
										class="switch__input"
										name="enabled-position"
										[ngModel]="false"
									/>
									<div class="switch__round"></div>
									<p class="switch__txt">Posição na lista de espera (Ligado)</p>
								</label>
								<div class="waitlist-admin__block">
									<!-- SWITCH -->
									<label class="switch">
										<input
											type="checkbox"
											class="switch__input"
											name="enabled-time"
											[ngModel]="false"
										/>
										<div class="switch__round"></div>
										<p class="switch__txt">
											Exibição do tempo de espera para Cliente (Desligado)
										</p>
									</label>
									<div
										class="waitlist-admin__select"
										[ngClass]="openedFormat ? 'opened' : ''"
									>
										<label class="waitlist-admin__label"> Selecione o formato </label>
										<div class="waitlist-admin__select__txt" (click)="toogleFormat()">
											{{ selectedFormat }}
										</div>
										<ul class="waitlist-admin__select__list">
											<li
												class="waitlist-admin__select__item"
												*ngFor="let item of selectFormats"
												(click)="selectFormat(item.title)"
											>
												{{ item.title }}
											</li>
										</ul>
									</div>
								</div>

								<div class="waitlist-admin__block">
									<!-- SWITCH -->
									<label class="switch">
										<input type="checkbox" class="switch__input" name="enabled" />
										<div class="switch__round"></div>
										<p class="switch__txt">
											Exibição do tempo de espera para atendente (Ligado)
										</p>
									</label>
									<div
										class="waitlist-admin__select"
										[ngClass]="openedEstimatedTime ? 'opened' : ''"
									>
										<label class="waitlist-admin__label"> Selecione o formato </label>
										<div
											class="waitlist-admin__select__txt"
											(click)="toogleEstimatedTime()"
										>
											{{ selectedEstimatedTime }}
										</div>
										<ul class="waitlist-admin__select__list">
											<li
												class="waitlist-admin__select__item"
												*ngFor="let item of selectEstimatedTimeOptions"
												(click)="selectEstimatedTimeName(item.title)"
											>
												{{ item.title }}
											</li>
										</ul>
									</div>
									<div
										class="waitlist-admin__select"
										[ngClass]="openedLate ? 'opened' : ''"
									>
										<label class="waitlist-admin__label">
											Tolerância de Atraso do Cliente (minutos):
										</label>
										<div class="waitlist-admin__select__txt" (click)="toogleLate()">
											{{ selectedLate }}
										</div>
										<ul class="waitlist-admin__select__list">
											<li
												class="waitlist-admin__select__item"
												*ngFor="let item of selectLateTime"
												(click)="selectLate(item.title)"
											>
												{{ item.title }}
											</li>
										</ul>
									</div>
								</div>
							</ng-template>
						</app-accordion>
					</div>

					<!-- ENVIO DE SMS -->
					<div class="waitlist-admin__box">
						<app-accordion
							slug="002"
							name="Envio de mensagens SMS"
							[tempAccordion]="tempAccordion3"
						>
							<ng-template #tempAccordion3>
								<!-- SWITCH -->
								<label class="switch">
									<input
										type="checkbox"
										class="switch__input"
										name="enabled-smsAdmin"
										[ngModel]="false"
									/>
									<div class="switch__round"></div>
									<p class="switch__txt">Envio de SMS config.admin (Ligado)</p>
								</label>

								<div class="waitlist-admin__block">
									<!-- SWITCH -->
									<label class="switch">
										<input
											type="checkbox"
											class="switch__input"
											name="enabled-clientList"
											[ngModel]="false"
										/>
										<div class="switch__round"></div>
										<p class="switch__txt">SMS cliente entrou na lista (Ligado)</p>
									</label>
									<label class="waitlist-admin__label"> Texto máximo 75 caracteres </label>
									<textarea
										class="waitlist-admin__textarea"
										type="text"
										maxlength="75"
										[(ngModel)]="phraseClientSms"
										cols="30"
										rows="10"
									></textarea>
									<span class="waitlist-admin__counter"
										>{{ 75 - phraseClientSms.length }}/75 caracteres</span
									>
								</div>

								<div class="waitlist-admin__block">
									<!-- SWITCH -->
									<label class="switch">
										<input
											type="checkbox"
											class="switch__input"
											name="enabled-callClient"
											[ngModel]="false"
										/>
										<div class="switch__round"></div>
										<p class="switch__txt">SMS chamar cliente (Ligado)</p>
									</label>
									<label class="waitlist-admin__label"> Texto máximo 75 caracteres </label>
									<textarea
										class="waitlist-admin__textarea"
										type="text"
										maxlength="75"
										[(ngModel)]="phraseCallClient"
										cols="30"
										rows="10"
									></textarea>
									<span class="waitlist-admin__counter"
										>{{ 75 - phraseCallClient.length }}/75 caracteres</span
									>
								</div>
								<div class="waitlist-admin__block">
									<!-- SWITCH -->
									<label class="switch">
										<input
											type="checkbox"
											class="switch__input"
											name="enabled-lateClient"
											[ngModel]="false"
										/>
										<div class="switch__round"></div>
										<p class="switch__txt">SMS cliente atrasado (Ligado)</p>
									</label>
									<label class="waitlist-admin__label"> Texto máximo 75 caracteres </label>
									<textarea
										class="waitlist-admin__textarea"
										type="text"
										maxlength="75"
										[(ngModel)]="phraseLateClient"
										cols="30"
										rows="10"
									></textarea>
									<span class="waitlist-admin__counter"
										>{{ 75 - phraseLateClient.length }}/75 caracteres</span
									>
								</div>
							</ng-template>
						</app-accordion>
					</div>

					<!-- ENVIO DE EMAILS -->
					<div class="waitlist-admin__box">
						<app-accordion slug="002" name="Envio de emails" [tempAccordion]="tempAccordion4">
							<ng-template #tempAccordion4>
								<div class="waitlist-admin__emails">
									<label class="waitlist-admin__label">
										Cliente entrou na lista de espera
									</label>
									<a href="" class="waitlist-admin__link"> Editar </a>
								</div>
								<div class="waitlist-admin__emails">
									<label class="waitlist-admin__label"> Chamar cliente </label>
									<a href="" class="waitlist-admin__link"> Editar </a>
								</div>
								<div class="waitlist-admin__emails">
									<label class="waitlist-admin__label"> Cliente atrasado </label>
									<a href="" class="waitlist-admin__link"> Editar </a>
								</div>
								<div class="waitlist-admin__emails">
									<label class="waitlist-admin__label"> Cliente sentou </label>
									<a href="" class="waitlist-admin__link"> Editar </a>
								</div>
								<div class="waitlist-admin__emails">
									<label class="waitlist-admin__label"> Saiu da fila (Cancelamento) </label>
									<a href="" class="waitlist-admin__link"> Editar </a>
								</div>
							</ng-template>
						</app-accordion>
					</div>

					<!-- ALERTAS NA INTERFACE DO CLIENTE -->
					<div class="waitlist-admin__box">
						<app-accordion
							slug="002"
							name="Alertas na interface do cliente"
							[tempAccordion]="tempAccordion5"
						>
							<ng-template #tempAccordion5>
								<div class="waitlist-admin__block">
									<!-- SWITCH -->
									<label class="switch">
										<input
											type="checkbox"
											class="switch__input"
											name="enabled-infoWidget"
											[ngModel]="false"
										/>
										<div class="switch__round"></div>
										<p class="switch__txt">Informações gerais widget (Ligado)</p>
									</label>
									<div class="waitlist-admin__editing">
										<label class="waitlist-admin__label"> Texto </label>
										<div class="waitlist-admin__form">
											<div class="waitlist-admin__language">
												<input
													type="radio"
													id="por"
													name="language"
													class="waitlist-admin__language__input"
												/>
												<label for="por" class="waitlist-admin__language__label"
													>POR</label
												>

												<input
													type="radio"
													id="ing"
													name="language"
													class="waitlist-admin__language__input"
												/>
												<label for="ing" class="waitlist-admin__language__label"
													>ING</label
												>
											</div>
											<div class="waitlist-admin__styles">
												<input
													type="checkbox"
													id="bold"
													name="styles"
													class="waitlist-admin__styles__input"
												/>
												<label for="bold" class="waitlist-admin__styles__label"
													>B</label
												>

												<input
													type="checkbox"
													id="italic"
													name="styles"
													class="waitlist-admin__styles__input"
												/>
												<label for="italic" class="waitlist-admin__styles__label"
													>I</label
												>

												<input
													type="checkbox"
													id="underline"
													name="styles"
													class="waitlist-admin__styles__input"
												/>
												<label for="underline" class="waitlist-admin__styles__label"
													>U</label
												>
											</div>
										</div>
									</div>
									<textarea
										class="waitlist-admin__textarea"
										name=""
										id=""
										cols="30"
										rows="10"
									>
Para grupos maiores de 8 pessoas fale com a nossa equipe de atendimento.</textarea
									>
								</div>

								<div class="waitlist-admin__block">
									<!-- SWITCH -->
									<label class="switch">
										<input
											type="checkbox"
											class="switch__input"
											name="enabled-generalInformation"
											[ngModel]="false"
										/>
										<div class="switch__round"></div>
										<p class="switch__txt">Informações gerais (Ligado)</p>
									</label>
									<div class="waitlist-admin__editing">
										<label class="waitlist-admin__label"> Texto </label>
										<div class="waitlist-admin__form">
											<div class="waitlist-admin__language">
												<input
													type="radio"
													id="por"
													name="language"
													class="waitlist-admin__language__input"
												/>
												<label for="por" class="waitlist-admin__language__label"
													>POR</label
												>

												<input
													type="radio"
													id="ing"
													name="language"
													class="waitlist-admin__language__input"
												/>
												<label for="ing" class="waitlist-admin__language__label"
													>ING</label
												>
											</div>
											<div class="waitlist-admin__styles">
												<input
													type="checkbox"
													id="bold"
													name="styles"
													class="waitlist-admin__styles__input"
												/>
												<label for="bold" class="waitlist-admin__styles__label"
													>B</label
												>

												<input
													type="checkbox"
													id="italic"
													name="styles"
													class="waitlist-admin__styles__input"
												/>
												<label for="italic" class="waitlist-admin__styles__label"
													>I</label
												>

												<input
													type="checkbox"
													id="underline"
													name="styles"
													class="waitlist-admin__styles__input"
												/>
												<label for="underline" class="waitlist-admin__styles__label"
													>U</label
												>
											</div>
										</div>
									</div>
									<textarea
										class="waitlist-admin__textarea"
										name=""
										id=""
										cols="30"
										rows="10"
									>
Para grupos maiores de 8 pessoas fale com a nossa equipe de atendimento.</textarea
									>
								</div>

								<div class="waitlist-admin__block">
									<!-- SWITCH -->
									<label class="switch">
										<input
											type="checkbox"
											class="switch__input"
											name="enabled-priorityQueue"
											[ngModel]="false"
										/>
										<div class="switch__round"></div>
										<p class="switch__txt">Cliente na fila prioridade (Ligado)</p>
									</label>
									<div class="waitlist-admin__editing">
										<label class="waitlist-admin__label"> Texto </label>
										<div class="waitlist-admin__form">
											<div class="waitlist-admin__language">
												<input
													type="radio"
													id="por"
													name="language"
													class="waitlist-admin__language__input"
												/>
												<label for="por" class="waitlist-admin__language__label"
													>POR</label
												>

												<input
													type="radio"
													id="ing"
													name="language"
													class="waitlist-admin__language__input"
												/>
												<label for="ing" class="waitlist-admin__language__label"
													>ING</label
												>
											</div>
											<div class="waitlist-admin__styles">
												<input
													type="checkbox"
													id="bold"
													name="styles"
													class="waitlist-admin__styles__input"
												/>
												<label for="bold" class="waitlist-admin__styles__label"
													>B</label
												>

												<input
													type="checkbox"
													id="italic"
													name="styles"
													class="waitlist-admin__styles__input"
												/>
												<label for="italic" class="waitlist-admin__styles__label"
													>I</label
												>

												<input
													type="checkbox"
													id="underline"
													name="styles"
													class="waitlist-admin__styles__input"
												/>
												<label for="underline" class="waitlist-admin__styles__label"
													>U</label
												>
											</div>
										</div>
									</div>
									<textarea
										class="waitlist-admin__textarea"
										name=""
										id=""
										cols="30"
										rows="10"
									>
Você é um cliente preferencial, fique próximo ao restaurante. É necessário pelo menos metade das pessoas para entrar.</textarea
									>
								</div>

								<div class="waitlist-admin__block">
									<!-- SWITCH -->
									<label class="switch">
										<input
											type="checkbox"
											class="switch__input"
											name="enabled-nextClient"
											[ngModel]="false"
										/>
										<div class="switch__round"></div>
										<p class="switch__txt">Cliente próximo chamado (Ligado)</p>
									</label>
									<div class="waitlist-admin__editing">
										<label class="waitlist-admin__label"> Texto </label>
										<div class="waitlist-admin__form">
											<div class="waitlist-admin__language">
												<input
													type="radio"
													id="por"
													name="language"
													class="waitlist-admin__language__input"
												/>
												<label for="por" class="waitlist-admin__language__label"
													>POR</label
												>

												<input
													type="radio"
													id="ing"
													name="language"
													class="waitlist-admin__language__input"
												/>
												<label for="ing" class="waitlist-admin__language__label"
													>ING</label
												>
											</div>
											<div class="waitlist-admin__styles">
												<input
													type="checkbox"
													id="bold"
													name="styles"
													class="waitlist-admin__styles__input"
												/>
												<label for="bold" class="waitlist-admin__styles__label"
													>B</label
												>

												<input
													type="checkbox"
													id="italic"
													name="styles"
													class="waitlist-admin__styles__input"
												/>
												<label for="italic" class="waitlist-admin__styles__label"
													>I</label
												>

												<input
													type="checkbox"
													id="underline"
													name="styles"
													class="waitlist-admin__styles__input"
												/>
												<label for="underline" class="waitlist-admin__styles__label"
													>U</label
												>
											</div>
										</div>
									</div>
									<textarea
										class="waitlist-admin__textarea"
										name=""
										id=""
										cols="30"
										rows="10"
									>
Por favor, permaneça próximo ao balcão de atendimento.</textarea
									>
								</div>
							</ng-template>
						</app-accordion>
					</div>

					<!-- TAG - LOCAL DE ESPERA -->
					<div class="waitlist-admin__box">
						<app-accordion
							slug="002"
							name="Tag - Local de espera"
							[tempAccordion]="tempAccordion6"
						>
							<ng-template #tempAccordion6>
								<div class="waitlist-admin__grid">
									<div class="waitlist-admin__column" *ngFor="let cards of cardsWait">
										<app-card
											slug="003"
											[nameCards]="cards.title"
											[iconCards]="cards.icon"
										>
										</app-card>
									</div>
								</div>
								<button class="waitlist-admin__button button__light">Criar nova tag</button>
							</ng-template>
						</app-accordion>
					</div>

					<!-- TAG - LOCAL DE ACOMODAÇÃO -->
					<div class="waitlist-admin__box">
						<app-accordion
							slug="002"
							name="Tag - Local de acomodação"
							[tempAccordion]="tempAccordion7"
						>
							<ng-template #tempAccordion7>
								<div class="waitlist-admin__grid">
									<div
										class="waitlist-admin__column"
										*ngFor="let cards of cardsAccommodation"
									>
										<app-card
											slug="003"
											[nameCards]="cards.title"
											[iconCards]="cards.icon"
										>
										</app-card>
									</div>
								</div>
								<button class="waitlist-admin__button button__light">Criar nova tag</button>
							</ng-template>
						</app-accordion>
					</div>

					<!-- TAG - PRIORIDADES -->
					<div class="waitlist-admin__box">
						<app-accordion slug="002" name="Tag - Prioridades" [tempAccordion]="tempAccordion8">
							<ng-template #tempAccordion8>
								<div class="waitlist-admin__grid">
									<div class="waitlist-admin__column" *ngFor="let cards of cardsPriority">
										<app-card
											slug="003"
											[nameCards]="cards.title"
											[iconCards]="cards.icon"
										>
										</app-card>
									</div>
								</div>
								<button class="waitlist-admin__button button__light">Criar nova tag</button>
							</ng-template>
						</app-accordion>
					</div>
					<!-- COMANDAS -->
					<div class="waitlist-admin__box">
						<app-accordion slug="002" name="Comandas" [tempAccordion]="tempAccordion9">
							<ng-template #tempAccordion9>
								<div class="waitlist-admin__block">
									<!-- SWITCH -->
									<label class="switch">
										<input type="checkbox" class="switch__input" name="enabled" />
										<div class="switch__round"></div>
										<p class="switch__txt">Controle de comandas (Ligado)</p>
									</label>
									<div
										class="waitlist-admin__select"
										[ngClass]="openedTypeCommand ? 'opened' : ''"
									>
										<label class="waitlist-admin__label"> Tipo de comanda </label>
										<div
											class="waitlist-admin__select__txt"
											(click)="toogleTypeCommand()"
										>
											{{ selectedTypeCommand }}
										</div>
										<ul class="waitlist-admin__select__list">
											<li
												class="waitlist-admin__select__item"
												*ngFor="let item of selectTypeCommandOptions"
												(click)="selectTypeCommand(item.title)"
											>
												{{ item.title }}
											</li>
										</ul>
									</div>
									<label class="waitlist-admin__label">
										Número inicial das Comandas (Obrigatório)
									</label>
									<input type="text" class="waitlist-admin__input" placeholder="150" />
									<label class="waitlist-admin__label"> Número final das Comandas </label>
									<input type="text" class="waitlist-admin__input" placeholder="190" />
									<div
										class="waitlist-admin__select"
										[ngClass]="openedFirstTime ? 'opened' : ''"
									>
										<label class="waitlist-admin__label">
											Horário Fim do Primeiro Turno (Obrigatório)
										</label>
										<div class="waitlist-admin__select__txt" (click)="toogleFirstTime()">
											{{ selectedFirstTime }}
										</div>
										<ul class="waitlist-admin__select__list">
											<li
												class="waitlist-admin__select__item"
												*ngFor="let item of selectFirstTime"
												(click)="selectFirstTimeName(item.title)"
											>
												{{ item.title }}
											</li>
										</ul>
									</div>
									<div class="waitlist-admin__select">
										<label
											class="waitlist-admin__label"
											[ngClass]="openedSecondTime ? 'opened' : ''"
										>
											Horário Fim do Segundo Turno (Obrigatório)
										</label>
										<div class="waitlist-admin__select__txt" (click)="toogleSecondTime()">
											{{ selectedSecondTime }}
										</div>
										<ul class="waitlist-admin__select__list">
											<li
												class="waitlist-admin__select__item"
												*ngFor="let item of selectSecondTime"
												(click)="selectSecondTimeName(item.title)"
											>
												{{ item.title }}
											</li>
										</ul>
									</div>
								</div>
							</ng-template>
						</app-accordion>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
