import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http'
import { Observable, tap } from 'rxjs'
import { UserService } from '../page/user/user.service'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(private userService: UserService) {}
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = this.userService.getToken
		if (token) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${token}`,
				},
			})
		}
		return next.handle(request).pipe(
			tap({
				error: (err: any) => {
					if (err instanceof HttpErrorResponse) {
						if (err.status === 401) {
							this.userService.logout()
						}
					}
				},
			})
		)
	}
}
