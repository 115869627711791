<div class="print">
	<div class="print__header no-print">
		<b>Informações</b>
		<button (click)="close()" class="print__close"><i class="fa fa-close"></i></button>
	</div>

	<div class="print__body">
		<button (click)="print()" class="print__btn no-print">Imprimir</button>

		<div class="print__content">
			<div *ngFor="let order of orders" style="margin-top: 20px">
				<p class="print__title">Nome</p>
				<p class="print__inf">{{ order.client?.name }} {{ order.client?.lastName }}</p>

				<p class="print__title">Data e Horário</p>
				<p class="print__inf">
					{{ order.reservationDay | date : 'dd/MM/YYYY' }} às {{ order.reservationTime }}
				</p>

				<ng-container *ngIf="getLastPayment(order) as lastPayment">
					<p class="print__title">Valor da Conta</p>
					<p class="print__inf">{{ lastPayment.orderInitialValue / 100 | currency : 'BRL' }}</p>

					<p class="print__title">Gorjeta</p>
					<p class="print__inf">{{ lastPayment.gratuityValue / 100 | currency : 'BRL' }}</p>

					<p class="print__title">Taxas</p>
					<p class="print__inf">{{ getTaxes(lastPayment) | currency : 'BRL' }}</p>

					<p class="print__title">Valor a Receber</p>
					<p class="print__inf">{{ lastPayment.venueValue / 100 | currency : 'BRL' }}</p>

					<hr class="print__hr" />

					<div class="print__row">
						<p class="print__paid-value green">Valor Pago</p>
						<div class="print__box">Pago às {{ getPaidAt(order) }}</div>
					</div>

					<p class="print__inf green fontTwenty">
						{{ lastPayment.orderTotalValue / 100 | currency : 'BRL' }}
					</p>

					<hr class="print__hr" />
				</ng-container>

				<div class="print__origin">Origem: {{ order.partner?.name }}</div>
			</div>
		</div>
	</div>
</div>
