import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
	@Input() slug: string = '001'
	@Input() closeModalSearch: boolean = true
	@Input() typeModal: string = ''

	constructor() {}

	ngOnInit(): void {}
}
