import { Location } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-back-button',
	templateUrl: './back-button.component.html',
	styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {
	@Input() page: string = ''
	@Input() addClass: string = ''
	constructor(private location: Location) {}

	ngOnInit(): void {}

	public goBack() {
		this.location.back()
	}
}
