<div class="page">
	<div class="page__wrapper wrapper">
		<app-back-button></app-back-button>
	</div>

	<div class="page__body">
		<div class="page__wrapper wrapper">
			<div class="create">
				<!-- FORM -->
				<form class="create__form" [formGroup]="form" (ngSubmit)="handleSubmit()">
					<!-- VENUE ID -->
					<label class="create__label" for="venueId">
						<span>VenueId</span>
					</label>
					<div
						id="venueId"
						name="venueId"
						type="text"
						class="create__input create__input--disabled"
						placeholder="Ex.: Uma sobremesa"
					>
						{{ venueId }}
					</div>
					<!-- SWITCH -->
					<!-- <label class="switch">
						<input
							type="checkbox"
							class="switch__input"
							name="enabled"
							formControlName="enabled"
						/>
						<div class="switch__round"></div>
						<p class="switch__txt">{{ switchText }}</p>
					</label> -->
					<label class="create__label" for="transactionValue">
						<span>Valor da transação </span>
					</label>

					<input
						id="transactionValue"
						formControlName="transactionValue"
						name="transactionValue"
						type="number"
						class="create__input"
						placeholder="Ex.: 75"
					/>
					<label class="create__label" for="retentionPartnerPercent">
						<span>Porcentagem da retenção do parceiro </span>
					</label>
					<input
						id="retentionPartnerPercent"
						formControlName="retentionPartnerPercent"
						name="retentionPartnerPercent"
						type="number"
						class="create__input"
						placeholder="Ex.: 15"
					/>
					<label class="create__label" for="retentionTagmePercent">
						<span>Porcentagem de retenção da Tagme </span>
					</label>
					<input
						id="retentionTagmePercent"
						formControlName="retentionTagmePercent"
						name="retentionTagmePercent"
						type="number"
						class="create__input"
						placeholder="Ex.: 1"
					/>
					<!-- <label class="create__label" for="acquirerPercent">
						<span>Porcentagem do Adquirente </span>
					</label>
					<input
						id="acquirerPercent"
						formControlName="acquirerPercent"
						name="acquirerPercent"
						type="number"
						class="create__input"
						placeholder="Ex.: 3.75"
					/> -->
					<label class="create__label" for="discountPercent">
						<span>Porcentagem de desconto</span>
					</label>
					<input
						id="discountPercent"
						formControlName="discountPercent"
						name="discountPercent"
						type="number"
						class="create__input"
						placeholder="Ex.: 6"
					/>
					<label class="create__label" for="gratuityPercent">
						<span>Porcentagem de gorjeta</span>
					</label>
					<input
						id="gratuityPercent"
						formControlName="gratuityPercent"
						name="gratuityPercent"
						type="number"
						class="create__input"
						placeholder="Ex.: 12"
					/>
					<button type="submit" class="create__button button">Salvar</button>
					<!-- [ngClass]="buttonDisabled ? ' disabled' : ''" -->
				</form>
			</div>
		</div>
	</div>
</div>

<ch-modal [id]="modal.error" top="15%" [preventOutsideClickClose]="true">
	<app-modal-simple [message]="errorMessage"></app-modal-simple>
</ch-modal>

<ch-modal [id]="modal.success" top="15%" [preventOutsideClickClose]="true">
	<app-modal-simple message="Pagamento salvo com sucesso!"></app-modal-simple>
</ch-modal>
