import { Component } from '@angular/core'
import * as Papa from 'papaparse'
import { Voucher } from './vouchers'
import { VouchersApi } from 'src/app/services/vouchers.service'
import { HttpStatus } from 'src/app/services/get-async.util'
import { environment } from 'src/environments/environment'

@Component({
	templateUrl: `vouchers.page.html`,
	styleUrls: [`vouchers.page.scss`],
})
export class ItauVouchersPage {
	vouchers: Voucher[] = []
	loading = false
	hasErrors = false
	status: HttpStatus = 'idle'
	results: { successVouchers: any[]; failureVouchers: any[] } = {
		successVouchers: [],
		failureVouchers: [],
	}
	error: string = ''

	constructor(private vouchersApi: VouchersApi) {}

	onFileSelected(event: any) {
		const file: File = event.target.files[0]
		const fileReader: FileReader = new FileReader()
		fileReader.onloadend = e => {
			const csvData = fileReader.result as string
			this.convertCsvToJson(csvData)
		}
		fileReader.readAsText(file)
	}

	convertCsvToJson(csvData: string) {
		const parsedData = Papa.parse(csvData, { header: true, skipEmptyLines: true })
		this.vouchers = parsedData.data.map(
			(obj: any) =>
				new Voucher(
					obj.NOME,
					obj.EMAIL,
					obj.TELEFONE,
					obj.ID_RESTAURANTE,
					obj.NOME_RESTAURANTE,
					obj.VALOR,
					obj.QUANTIDADE,
					obj.CCO_EMAILS,
					obj.TIPO
				)
		)
		this.hasErrors = this.vouchers.some(voucher => voucher.errors.length > 0)
	}

	importVouchers() {
		if (this.hasErrors) return
		this.status = 'pending'
		if (environment.production) {
			this.vouchersApi.importVocuhersJson({ clientsVoucher: this.vouchers }).subscribe({
				next: res => {
					this.status = 'resolved'
					this.results = res
				},
				error: res => {
					this.status = 'rejected'
					this.error = res?.error?.message
				},
			})
		} else {
			const menuPersonnaliteOne = '65da5047d2d898a6716a0626'
			this.vouchersApi
				.importGiftJson({ clientsVoucher: this.vouchers, giftId: menuPersonnaliteOne })
				.subscribe({
					next: res => {
						this.status = 'resolved'
						this.results = res
					},
					error: res => {
						this.status = 'rejected'
						this.error = res?.error?.message
					},
				})
		}
	}
}
