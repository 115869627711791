<div class="calendar">
	<div class="calendar-header">
		<div class="calendar-header__title">{{ today | date : 'MMMM yyyy' | titlecase }}</div>
		<div class="calendar-header__right">
			<button (click)="changeMonth(-1)"><img src="assets/icons/chevron-left.svg" /></button>
			<button (click)="changeMonth(+1)"><img src="assets/icons/chevron-right.svg" /></button>
		</div>
	</div>
	<div class="calendar-body">
		<ng-container *ngFor="let day of days; let i = index">
			<span class="calendar-body__weekday" *ngIf="i < 7">
				{{ day | date : 'EEE' | uppercase | slice : 0 : -1 }}
			</span>
		</ng-container>
		<div
			*ngFor="let day of days"
			class="calendar-body__day"
			[class.calendar-body__not-month]="greaterThanToday(day) || notMonth(day)"
			[class.calendar-body__selected-day]="isSelected(day)"
			[class.calendar-body__between-day]="isBetween(day)"
			[class.calendar-body__between-day--first]="isFirstBetween(day)"
			[class.calendar-body__between-day--last]="isLastBetween(day)"
			(click)="selectDay(day)"
		>
			{{ day | date : 'd' }}
		</div>
	</div>
</div>
