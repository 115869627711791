import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'

@Component({
	selector: 'app-card002',
	templateUrl: './card002.component.html',
	styleUrls: ['./card002.component.scss'],
})
export class Card002Component implements OnInit {
	@Input() nameCardBenefit: string = ''
	@Input() imgCardBenefit: string = ''
	@Input() idBenefit: string = ''
	@Input() idCategory: string = ''
	@Input() activeCardBenefit: string = ''
	@Input() nameCardCategory: string = ''
	@Input() imgCardCategory: string = ''
	@Input() activeCardCategory: string = ''
	@Input() isOption: boolean = false
	@Input() isLogout: boolean = false

	constructor(private router: Router) {}

	ngOnInit(): void {}

	public editCategory(idCategory: any) {
		this.router.navigate(['create-category/' + idCategory])
	}
}
