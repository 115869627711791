import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { UserService } from '../user.service'

@Injectable({
	providedIn: 'root',
})
export class AuthGuardVenue {
	constructor(private userService: UserService, private router: Router) {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		const venueId = route.paramMap.get('venueId')
		if (!this.userService.isLoggedIn) {
			this.userService.setRedirectURL(state.url)
			this.router.navigate(['/login'])
			return false
		}
		if (this.userService.isAdmin) {
			return true
		}
		if (this.userService.isVenueAdmin) {
			return this.userService.isVenueEligible(venueId)
		}
		return false
	}
}
