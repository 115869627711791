import { BehaviorSubject, Observable, tap } from 'rxjs'

export type HttpStatus = 'idle' | 'pending' | 'resolved' | 'rejected'
export type GetAsyncState<Type> = {
	status: HttpStatus
	data: Type | null
	error: string | null
}

export function getAsync<Type>(observableCallback: Observable<Type>, initialState?: Record<string, any>) {
	const stateSub = new BehaviorSubject<GetAsyncState<Type>>({
		status: 'pending',
		data: null,
		error: null,
		...initialState,
	})
	const state$ = stateSub.asObservable()

	observableCallback.subscribe({
		next: data => {
			stateSub.next({
				status: 'resolved',
				data: data,
				error: null,
			})
		},
		error: error => {
			stateSub.next({
				status: 'rejected',
				data: null,
				error: error.error.message,
			})
		},
	})

	return state$
}
