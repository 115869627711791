import { Component, Input, OnInit, TemplateRef } from '@angular/core'

@Component({
	selector: 'app-accordion',
	templateUrl: './accordion.component.html',
	styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit {
	@Input() slug: string = ''
	@Input() name: string = ''
	@Input() icon: string = ''
	@Input() class: string = ''
	@Input() tempAccordion: TemplateRef<any> | null = null

	constructor() {}

	ngOnInit(): void {}
}
