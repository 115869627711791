import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { VenuesManagementService } from '../venues-management.service'
import { VenuesConfigApi } from 'src/app/services/venues-config/venues-config.service'
import { UpdateCheckinDto, UpdatePaymentDto } from 'src/app/services/venues-config/venues-config.interfaces'
import { Router } from '@angular/router'
import { ModalService } from 'src/app/components/modalChannels/modal.service'
import { Subject, takeUntil } from 'rxjs'

@Component({
	templateUrl: './edit-payment.page.html',
	styleUrls: ['./edit-payment.page.scss'],
})
export class EditPaymentsPage implements OnInit, OnDestroy {
	form!: FormGroup
	venueId: string | null = this.venuesManagementService.venueId
	partnerId: string | null = this.venuesManagementService.partnerId
	errorMessage: string | null = null
	modal = {
		error: 'modalErrorId',
		success: 'modalSuccessId',
	}

	private destroy$ = new Subject<void>()

	constructor(
		private fb: FormBuilder,
		private venuesManagementService: VenuesManagementService,
		private venuesConfigApi: VenuesConfigApi,
		private modalService: ModalService
	) {}

	ngOnInit(): void {
		this.form = this.fb.group({
			transactionValue: [],
			retentionPartnerPercent: [],
			retentionTagmePercent: [],
			acquirerPercent: [],
			discountPercent: [],
			gratuityPercent: [],
		})
		this.venuesManagementService.ensurePrimaryKey()
		this.venuesManagementService.venueConfig$.pipe(takeUntil(this.destroy$)).subscribe({
			next: venueConfig => {
				if (!venueConfig?.payments) return
				const { payments } = venueConfig
				const {
					transactionValue,
					retentionPartnerPercent,
					retentionTagmePercent,
					acquirerPercent,
					discountPercent,
					gratuityPercent,
				} = payments
				this.form.patchValue({
					transactionValue,
					retentionPartnerPercent,
					retentionTagmePercent,
					acquirerPercent,
					discountPercent,
					gratuityPercent,
				})
			},
			error: err => {
				this.errorMessage = err.error.message
				this.modalService.open(this.modal.error)
			},
		})
	}

	ngOnDestroy(): void {
		this.destroy$.next()
		this.destroy$.complete()
	}

	handleSubmit() {
		if (this.form.invalid) return
		const updatePayment = {
			venueId: this.venueId,
			partnerId: this.partnerId,
			transactionValue: this.form.value.transactionValue,
			retentionPartnerPercent: this.form.value.retentionPartnerPercent,
			retentionTagmePercent: this.form.value.retentionTagmePercent,
			// acquirerPercent: this.form.value.acquirerPercent,
			discountPercent: this.form.value.discountPercent,
			gratuityPercent: this.form.value.gratuityPercent,
		} as UpdatePaymentDto
		this.venuesConfigApi.updatePayment(updatePayment).subscribe({
			next: () => {
				this.modalService.open(this.modal.success)
			},
			error: err => {
				this.errorMessage = err.error.message
				this.modalService.open(this.modal.error)
			},
		})
	}
}
