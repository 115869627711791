<div class="page">
	<app-header-master></app-header-master>

	<div class="page__body">
		<div class="page__wrapper wrapper">
			<div class="projects">
				<div class="projects__item" *ngIf="isAdmin">
					<app-accordion
						slug="001"
						name=""
						icon="tagmeicon tagmeicon-tagme-horizontal"
						class="tagme"
						[tempAccordion]="tempTagmeMaster"
					>
					</app-accordion>
				</div>
				<div class="projects__item" *ngIf="isAdminOrConcierge">
					<app-accordion
						slug="001"
						name="Programa Menu Bradesco"
						icon="tagmeicon tagmeicon-bradesco-logo-novo"
						class="progMenu"
						[tempAccordion]="tempBradesco"
					>
					</app-accordion>
				</div>
				<div class="projects__item" *ngIf="isAdmin">
					<app-accordion
						slug="001"
						name="Menu Personnalité"
						icon=""
						class="iupp"
						[tempAccordion]="tempIupp"
					>
					</app-accordion>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #tempBradesco class="projects__grid">
	<div class="projects__column" *ngFor="let cards of cardButtonsBradesco">
		<app-card
			slug="001"
			[nameCards]="cards.title"
			[iconCards]="cards.icon"
			[idCards]="cards.id"
			template="progMenu"
		>
		</app-card>
	</div>
</ng-template>

<ng-template #tempTagmeMaster class="projects__grid">
	<div class="projects__column" *ngFor="let cards of cardButtonsTagme">
		<app-card
			slug="001"
			[nameCards]="cards.title"
			[iconCards]="cards.icon"
			[idCards]="cards.id"
			template="tagme"
		>
		</app-card>
	</div>
</ng-template>

<ng-template #tempIupp class="projects__grid">
	<div class="projects__column" *ngFor="let cards of cardButtonsIupp">
		<app-card
			slug="001"
			[nameCards]="cards.title"
			[iconCards]="cards.icon"
			[idCards]="cards.id"
			template="iupp"
		>
		</app-card>
	</div>
</ng-template>
