<app-card001
	*ngIf="slug === '001'"
	[nameCards]="nameCards"
	[iconCards]="iconCards"
	[idCards]="idCards"
	[isOption]="isOption"
	[isLogout]="isLogout"
	[template]="template"
>
	<ng-content select="[content]" content></ng-content>
</app-card001>

<app-card002
	*ngIf="slug === '002'"
	[nameCardBenefit]="nameCardBenefit"
	[imgCardBenefit]="imgCardBenefit"
	[idCategory]="idCategory"
	[idBenefit]="idBenefit"
	[activeCardBenefit]="activeCardBenefit"
	[nameCardCategory]="nameCardCategory"
	[imgCardCategory]="imgCardCategory"
	[activeCardCategory]="activeCardCategory"
	[isOption]="isOption"
	[isLogout]="isLogout"
></app-card002>

<app-card003 *ngIf="slug === '003'" [nameCards]="nameCards" [iconCards]="iconCards"> </app-card003>
