<div class="payment-filter-details-wrapper">
	<div class="button-wrapper">
		<h2 class="button-wrapper__title">Período</h2>
		<app-payment-button
			(buttonClicked)="openCalendar()"
			[firstDay]="firstDay | date : 'dd/MM/YYYY'"
			[lastDay]="lastDay | date : 'dd/MM/YYYY'"
		></app-payment-button>
	</div>

	<div class="button-wrapper">
		<h2 class="button-wrapper__title">Canais</h2>
		<app-select-box
			[items]="channels"
			icon="tagmeicon-seta"
			(itemWasSelected)="setChannel($event)"
		></app-select-box>
	</div>

	<div class="button-wrapper">
		<h2 class="button-wrapper__title">Encontrar cliente pelo nome</h2>
		<app-payment-search (submitted)="setName($event)"></app-payment-search>
	</div>
</div>
<div class="boxBtn">
	<button class="boxBtn__btn" [disabled]="!isAnyCardSelected" (click)="handlePrintClick()">
		<i
			class="tagmeicon tagmeicon-imprimir-mini boxBtn__btn__icon"
			[ngClass]="{ colorSelected: isAnyCardSelected, colorNoSelected: !isAnyCardSelected }"
		></i>
		Imprimir
	</button>
	<button class="boxBtn__btn" [disabled]="!isAnyCardSelected" (click)="openDetails()">
		<i
			class="tagmeicon tagmeicon-maquininha boxBtn__btn__icon"
			[ngClass]="{ colorSelected: isAnyCardSelected, colorNoSelected: !isAnyCardSelected }"
		></i>
		{{ totalSelectedValue / 100 | currency : 'BRL' }}
	</button>
</div>

<ch-modal [id]="calendarId" top="25%">
	<app-calendar-multi
		[id]="calendarId"
		[firstDay]="firstDay"
		[lastDay]="lastDay"
		(firstDaySelected)="selectDay($event, 'first')"
		(lastDaySelected)="selectDay($event, 'last')"
	></app-calendar-multi>
</ch-modal>
