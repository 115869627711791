import { Component, OnInit } from '@angular/core'

@Component({
	selector: 'app-tags002',
	templateUrl: './tags002.component.html',
	styleUrls: ['./tags002.component.scss'],
})
export class Tags002Component implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
