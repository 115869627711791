import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { map, Observable, Subject, Subscription, switchMap, tap } from 'rxjs'
import {
	IDays,
	IPaymentFilter,
} from 'src/app/components/payment/payment-filter-details/payment-filter-details.component'
import { Item } from 'src/app/components/select-box/select-box.component'
import { Order, OrderPayment } from 'src/app/interfaces/order.interface'
import { DateUtils } from 'src/app/services/date.util'
import { HttpStatus, getAsync } from 'src/app/services/get-async.util'
import { environment } from 'src/environments/environment'
import { PaymentsService } from '../payment.service'
import { VenuesCacheService } from '../venue-cache.service'
import { SSE } from 'sse.js'
import { UserService } from '../../user/user.service'
import { ModalService } from 'src/app/components/modalChannels/modal.service'

@Component({
	templateUrl: './payment-details.page.html',
	styleUrls: ['../venue-payment-base.scss', './payment-details.page.scss'],
})
export class PaymentDetailsPage implements OnInit, OnDestroy {
	public orders: OrderPayment[] | null = null
	public selectedOrderToPrint: OrderPayment[] | null = null
	public filteredOrders: OrderPayment[] | null = null
	public statusOrder!: HttpStatus
	public errorOrder!: string | null
	public venueId!: string
	public venue$!: Observable<any>
	public isAdminUser = this.userService.isAdmin
	public channels: Item[] = createChannelsList(null)
	private searchDaySub = new Subject<IDays>()
	public searchDay$ = this.searchDaySub.asObservable()
	public lastDayEmitted!: IDays

	public statusRefund!: HttpStatus
	private orderIdForRefund: string | null = null
	public modalRefundId = 'modalRefundId'
	public modalGratuityId = 'modalGratuityId'
	public statusGratuity!: HttpStatus
	public modalPrintId = 'modalPrintId'
	public modalDetailsId = 'modalDetailsId'
	public selectedOrdersToPrint: OrderPayment[] = []
	public selectedOrderToUpdateGratuityValue: OrderPayment | null = null

	subs = new Subscription()
	eventSource$!: any
	isAnyCardSelected = false
	selectedCards: { [key: string]: boolean } = {}
	totalSelectedValue = 0
	selectedOrderValues: { [key: string]: number } = {}

	constructor(
		private paymentsService: PaymentsService,
		private activatedRoute: ActivatedRoute,
		private userService: UserService,
		private venuesCacheService: VenuesCacheService,
		private modalService: ModalService
	) {}

	ngOnInit(): void {
		this.venueId = this.activatedRoute.snapshot.paramMap.get('venueId') ?? ''
		this.venue$ = this.venuesCacheService.getVenueById(this.venueId)
		this.subs.add(
			this.searchDay$
				.pipe(
					switchMap(days =>
						getAsync<OrderPayment[]>(
							this.paymentsService
								.getPaymentOrderAdmin<OrderPayment[]>(
									this.venueId,
									DateUtils.formatDate(days.firstDay),
									DateUtils.formatDate(days.lastDay)
								)
								.pipe(
									map(orders => orders.filter(order => order.paymentStatus !== 'NOT_DONE'))
								)
						)
					)
				)
				.subscribe(state => {
					this.orders = state.data
					this.channels = createChannelsList(this.orders)
					this.statusOrder = state.status
					this.errorOrder = state.error
					this.filteredOrders = this.orders
				})
		)

		this.eventSource$ = new SSE(`${environment.channelsAPI.baseURL}payments/sse`, {
			headers: { Authorization: `Bearer ${this.userService.getToken}` },
		})

		this.eventSource$.addEventListener('message', ({ data }) => {
			if (environment.production ? data.venueId === this.venueId : true) {
				this.searchDaySub.next(this.lastDayEmitted)
			}
		})
		this.eventSource$.stream()
	}

	refundPayemnt() {
		if (!this.orderIdForRefund) {
			alert('Não existe paymentId para essa order')
			return
		}
		getAsync(this.paymentsService.cancelPayment(this.orderIdForRefund)).subscribe(state => {
			this.statusRefund = state.status
			if (state.status === 'resolved') {
				this.closeModalRefund()
				this.searchDaySub.next(this.lastDayEmitted)
			}
			if (state.status === 'rejected') {
				this.closeModalRefund()
				alert(state.error)
			}
		})
	}

	updateGratuityValue() {
		if (!this.selectedOrderToUpdateGratuityValue) {
			alert('Algum dado está faltando')
			return
		}
		const orderId = this.selectedOrderToUpdateGratuityValue._id
		const removeGrauityValue = !this.selectedOrderToUpdateGratuityValue?.removeGratuityValue
		getAsync(this.paymentsService.updateGratuityValue(orderId, removeGrauityValue)).subscribe(state => {
			this.statusGratuity = state.status
			if (state.status === 'resolved') {
				this.modalService.close(this.modalGratuityId)
				this.searchDaySub.next(this.lastDayEmitted)
			}
			if (state.status === 'rejected') {
				this.modalService.close(this.modalGratuityId)
				alert(state.error)
			}
		})
	}

	openModalDetails() {
		this.modalService.open(this.modalDetailsId)
	}

	closeModalDetails() {
		this.modalService.close(this.modalDetailsId)
	}

	openModalGratuityValue(event: OrderPayment) {
		this.selectedOrderToUpdateGratuityValue = event
		this.modalService.open(this.modalGratuityId)
	}

	openModalRefund(event: { orderId: string }) {
		this.orderIdForRefund = event.orderId
		this.modalService.open(this.modalRefundId)
	}

	openModalPrint(event: { order: any }) {
		this.selectedOrderToPrint = event.order
		this.modalService.open(this.modalPrintId)
	}

	closeModalRefund() {
		this.modalService.close(this.modalRefundId)
	}

	closeModalPrint() {
		this.modalService.close(this.modalPrintId)
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
		this.eventSource$.close()
	}

	setOrder($event: IDays) {
		this.resetOrderSelections()
		this.searchDaySub.next($event)
		this.lastDayEmitted = $event
	}

	setFilter(filter: IPaymentFilter) {
		if (!this.orders) {
			return
		}
		this.filteredOrders = this.orders.filter((order: Order) => {
			const fullname = `${order.client?.name} ${order.client?.lastName}`.toLowerCase()
			return (
				(!filter.partnerId || order.partner._id === filter.partnerId) &&
				(!filter.name || fullname.includes(filter.name.toLowerCase()))
			)
		})
	}

	onCardSelectionChanged(orderId: string, selected: boolean): void {
		this.selectedCards[orderId] = selected

		const order = this.orders?.find(order => order._id === orderId)

		const lastPayment = Array.isArray(order?.payment)
			? order!.payment[order!.payment.length - 1]
			: order?.payment

		if (selected && lastPayment) {
			this.selectedOrderValues[orderId] = lastPayment.orderTotalValue || 0
		} else {
			delete this.selectedOrderValues[orderId]
		}

		this.totalSelectedValue = Object.values(this.selectedOrderValues).reduce(
			(add, value) => add + value,
			0
		)
		this.isAnyCardSelected = Object.keys(this.selectedOrderValues).length > 0
	}

	openPrintModalForSelectedOrders() {
		this.selectedOrdersToPrint = this.orders?.filter(order => this.selectedCards[order._id]) || []
		this.modalService.open(this.modalPrintId)
	}

	openDetailsModalForSelectedOrders() {
		this.selectedOrdersToPrint = this.orders?.filter(order => this.selectedCards[order._id]) || []
		this.modalService.open(this.modalDetailsId)
	}

	resetOrderSelections() {
		this.selectedCards = {}
		this.isAnyCardSelected = false
		this.selectedOrderValues = {}
		this.totalSelectedValue = 0
	}
}

function createChannelsList(orders: Order[] | null): Item[] {
	if (!orders) {
		return [{ name: 'Todos', value: '' }]
	}
	const ordersKey = [
		{ name: 'Todos', value: '' },
		...orders.map(order => ({ name: order.partner.name, value: order.partner._id })),
	]
	const uniqueArr = [...new Set(ordersKey.map(item => JSON.stringify(item)))].map(item => JSON.parse(item))
	return uniqueArr
}
