import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { finalize } from 'rxjs'
import { PartnersId } from 'src/shared/util'
import { CategoryService } from './category.service'

@Component({
	selector: 'app-category',
	templateUrl: './category.component.html',
	styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit {
	public loading: boolean = true

	public categoryCards$ = this.categoryService
		.getCategorysData(PartnersId.BRADESCO)
		.pipe(finalize(() => (this.loading = false)))

	constructor(private categoryService: CategoryService, private router: Router) {}

	ngOnInit(): void {}

	public createCategory() {
		this.router.navigate(['create-category'])
	}
}
