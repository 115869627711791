import { Component, EventEmitter, Output } from '@angular/core'

@Component({
	selector: 'app-calendar',
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent {
	today: Date = new Date()
	days: Date[] = []
	selectedDay = new Date()
	@Output() daySelected = new EventEmitter<Date>()

	ngOnInit() {
		this.buildCalendar()
	}

	changeMonth(offsetMonth: number) {
		this.today.setMonth(this.today.getMonth() + offsetMonth)
		this.today = new Date(this.today.getTime())
		this.buildCalendar()
	}

	greaterThanToday(day: Date) {
		const NOW = new Date()
		return day.getTime() > NOW.getTime()
	}

	notMonth(day: Date) {
		return this.today.getMonth() !== day.getMonth()
	}

	selectDay(day: Date) {
		if (this.greaterThanToday(day)) {
			return
		}

		this.selectedDay = day
		if (day.getMonth() < this.today.getMonth()) {
			this.changeMonth(-1)
		} else if (day.getMonth() > this.today.getMonth()) {
			this.changeMonth(+1)
		}
		this.daySelected.emit(day)
	}

	isSelected(day: Date) {
		return day.toDateString() === this.selectedDay.toDateString()
	}

	buildCalendar() {
		const year = this.today.getFullYear()
		const month = this.today.getMonth()

		const firstDayOfWeek = 1 // segunda
		const lastDayOfWeek = 0 // domingo

		// Vai subtraindo -1 até chegarmos no primeiro dia da semana
		const dateBegin = new Date(year, month, 1)
		while (dateBegin.getDay() !== firstDayOfWeek) {
			dateBegin.setDate(dateBegin.getDate() - 1)
		}

		// Vai somando +1 até chegarmos no último dia da semana
		const dateEnd = new Date(year, month + 1, 0)
		while (dateEnd.getDay() !== lastDayOfWeek) {
			dateEnd.setDate(dateEnd.getDate() + 1)
		}

		this.days = []
		for (let data = new Date(dateBegin.getTime()); data <= dateEnd; data.setDate(data.getDate() + 1)) {
			this.days.push(new Date(data.getTime()))
		}
	}
}
