import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { CreateBenefitDto } from './dto/create-benefit.dto'
import { UpdateBenefitDto } from './dto/update-benefit.dto'
import { BenefitDto } from './general-benefit/general-benefit.dto'
import { environment as env } from '../../../environments/environment'
import { BenefitsExceptionDto } from './dto/update-exception.dto'

@Injectable({
	providedIn: 'root',
})
export class BenefitService {
	constructor(private http: HttpClient) {}

	createBenefit(benefit: CreateBenefitDto, partnerId: string) {
		const body = { ...benefit, partnerId }
		return this.http.post<BenefitDto>(`${env.channelsAPI.baseURL}benefits`, body)
	}

	updateBenefit(id: string, benefit: UpdateBenefitDto) {
		return this.http.patch<BenefitDto>(`${env.channelsAPI.baseURL}benefits/${id}`, benefit)
	}

	updateException(id: string, benefit: BenefitsExceptionDto) {
		return this.http.patch<BenefitDto>(`${env.channelsAPI.baseURL}benefits/exception/${id}`, benefit)
	}

	getBenefitId(id: string) {
		return this.http.get<BenefitDto>(`${env.channelsAPI.baseURL}benefits/${id}`)
	}

	getAllBenefits(partnerId: string) {
		return this.http.get<any[]>(`${env.channelsAPI.baseURL}benefits`, { headers: { partnerId } })
	}
}
