import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntypedFormGroup, FormGroupDirective } from '@angular/forms'

@Component({
	selector: 'app-modal-venue',
	templateUrl: './modal-venue.component.html',
	styleUrls: ['./modal-venue.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalVenueComponent implements OnInit {
	searchForm!: UntypedFormGroup
	@Input() hasVenues: any
	@Input() venues: any
	@Input() lastVenues: any
	constructor(private rootFormGroup: FormGroupDirective) {}

	@Output() onCloseModal = new EventEmitter<void>()
	@Output() onSearch = new EventEmitter<void>()
	@Output() onClickVenue = new EventEmitter<any>()
	@Output() onEraseHistory = new EventEmitter<void>()

	ngOnInit(): void {
		this.searchForm = this.rootFormGroup.control
	}
	closeModal() {
		this.onCloseModal.emit()
	}
	handleSearch() {
		this.onSearch.emit()
	}
	clickLastVenues(item: any) {
		this.onClickVenue.emit(item)
	}
	eraseHistory() {
		this.onEraseHistory.emit()
	}
}
