import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Venue } from 'src/app/page/venue-management/venues.interface'

@Component({
	selector: 'app-checkin-header',
	templateUrl: './checkin-header.component.html',
	styleUrls: ['./checkin-header.component.scss'],
})
export class CheckinHeaderComponent {
	@Input() venue!: Venue
	@Output() historyClicked = new EventEmitter<void>()

	constructor() {}

	clickHistory() {
		this.historyClicked.emit()
	}
}
