import { Component, OnInit } from '@angular/core'
import { VenuesManagementService } from '../venues-management.service'
import { ModalService } from 'src/app/components/modalChannels/modal.service'
import { VenuesImages } from 'src/app/services/venues-config/venues-config.interfaces'
import { combineLatest, finalize, forkJoin, map, switchMap, tap } from 'rxjs'
import { CroppedClickedType, ImagesCropped, Types } from 'src/app/components/logo/logo.component'
import { ImageUploadApi } from 'src/app/services/image-upload/image-upload.service'
import { VenuesConfigApi } from 'src/app/services/venues-config/venues-config.service'

@Component({
	templateUrl: 'edit-images.page.html',
	styleUrls: ['edit-images.page.scss'],
})
export class EditImagesPage implements OnInit {
	venueId = this.venuesManagementService.venueId as string
	partnerId = this.venuesManagementService.partnerId as string
	errorMessage: string | null = null
	venueImages: VenuesImages | null = null
	imageToDelete: string | null = null
	imageCropped: null | ImagesCropped = null
	imagesConfig: null | Array<ImagesCropped> = null
	type: null | Types = null
	action: CroppedClickedType['action'] | undefined
	timestamp = Date.now()
	loadingApi = false

	deleteImagesUrl:
		| {
				originalUrl: string
				optimizedUrl?: string
		  }
		| undefined

	vm$ = combineLatest([
		this.venuesManagementService.venueConfig$,
		this.venuesManagementService.partner$,
	]).pipe(
		map(([venueConfig, partner]) => ({ venueConfig, partner })),
		tap(result => {
			this.timestamp = Date.now()
			this.venueImages = result.venueConfig?.images
		})
	)

	modal = {
		error: 'modalErrorId',
		success: 'modalSuccessId',
		confirmCrooped: 'confirmCroppedId',
		deleteImage: 'deleteImage',
		loading: 'loadingId',
	}

	constructor(
		private venuesManagementService: VenuesManagementService,
		private imageUploadApi: ImageUploadApi,
		public modalService: ModalService,
		private venuesConfigApi: VenuesConfigApi
	) {}

	ngOnInit(): void {
		this.venuesManagementService.ensurePrimaryKey()
	}

	openConfirmCroppedModal({ imageCropped, imagesConfig, action }: CroppedClickedType, type: Types) {
		this.imageCropped = imageCropped
		this.imagesConfig = imagesConfig
		this.type = type
		this.action = action
		this.modalService.open(this.modal.confirmCrooped)
	}

	chooseCreateOrUpdateImageOptimzed() {
		if (this.action === 'updateOptimized') {
			this.updateImageOptimized()
		}
		if (this.action === 'createOptimizedFromOriginal') {
			this.createImageOptimizedFromOriginal()
		}
		if (this.action === 'createOriginalAndOptimized') {
			this.createImageOptimizedAndCreateOriginal()
		}
	}

	updateImageOptimized() {
		if (!this.imageCropped?.optimizedUrl || !this.imageCropped.croppedImage) {
			console.error('Sem optimizedUrl')
			return
		}
		this.loadingApi = true
		this.imageUploadApi
			.updateOptimized({
				filePath: this.imageCropped?.optimizedUrl,
				base64: this.extractBase64string(this.imageCropped?.croppedImage),
			})
			.pipe(
				switchMap(() => this.venuesManagementService.updateVenueConfig()),
				finalize(() => {
					this.modalService.close(this.modal.confirmCrooped)
					this.loadingApi = false
				})
			)
			.subscribe()
	}

	createImageOptimizedFromOriginal() {
		if (!this.imageCropped?.imageUrl || !this.imageCropped.croppedImage) {
			console.error('Sem imageURL ou croppedImage')
			return
		}
		this.loadingApi = true
		this.imageUploadApi
			.createOptimized({
				partnerId: this.partnerId,
				venueId: this.venueId,
				base64: this.extractBase64string(this.imageCropped?.croppedImage),
			})
			.pipe(
				switchMap(pathOptimized => {
					return this.venuesConfigApi.updateImages({
						venueId: this.venueId,
						partnerId: this.partnerId,
						images: this.replaceCoverUrlToOptimzedOrOriginalURL({
							pathOptimized: pathOptimized.path,
						}),
					})
				}),
				switchMap(() => this.venuesManagementService.updateVenueConfig()),
				finalize(() => {
					this.modalService.close(this.modal.confirmCrooped)
					this.loadingApi = false
				})
			)
			.subscribe()
	}

	createImageOptimizedAndCreateOriginal() {
		if (!this.imageCropped?.croppedImage || !this.imageCropped?.uploadOriginal || !this.venueImages) {
			console.error('Sem imageURL ou croppedImage ou uploadOriginal')
			return
		}

		if (this.type === 'banners' || this.type === 'thumb') {
			this.venueImages[this.type].push({
				coverUrl: undefined,
				optimizedCoverUrl: undefined,
				order: this.venueImages[this.type].length + 1,
			} as any)
		}
		this.loadingApi = true
		forkJoin([
			this.imageUploadApi.createOriginal({
				partnerId: this.partnerId,
				venueId: this.venueId,
				base64: this.extractBase64string(this.imageCropped.uploadOriginal),
			}),
			this.imageUploadApi.createOptimized({
				partnerId: this.partnerId,
				venueId: this.venueId,
				base64: this.extractBase64string(this.imageCropped?.croppedImage),
			}),
		])
			.pipe(
				switchMap(([pathOriginal, pathOptimized]) => {
					return this.venuesConfigApi.updateImages({
						venueId: this.venueId,
						partnerId: this.partnerId,
						images: this.replaceCoverUrlToOptimzedOrOriginalURL({
							pathOriginal: pathOriginal.path,
							pathOptimized: pathOptimized.path,
						}),
					})
				}),
				switchMap(() => this.venuesManagementService.updateVenueConfig()),
				finalize(() => {
					this.modalService.close(this.modal.confirmCrooped)
					this.loadingApi = false
				})
			)
			.subscribe()
	}

	openDeleteModal(event: { imageUrl: string }, type: Types) {
		this.modalService.open(this.modal.deleteImage)
		this.type = type
		this.imageToDelete = event.imageUrl
	}

	deleteImage() {
		if (!this.venueImages || this.imageToDelete == null) return
		let updatedImages = structuredClone(this.venueImages)
		if (this.type === 'banners' || this.type === 'thumb') {
			updatedImages[this.type] = updatedImages[this.type].filter(
				image => image.coverUrl != this.imageToDelete
			)
			updatedImages = this.setNewOrder(updatedImages, this.type)
		} else if (this.type === 'logo') {
			updatedImages.logo.logoUrl = undefined as any
			updatedImages.logo.optimizedLogoUrl = undefined as any
		}
		this.loadingApi = true
		this.venuesConfigApi
			.updateImages({
				partnerId: this.partnerId,
				venueId: this.venueId,
				images: updatedImages,
			})
			.pipe(
				switchMap(() => this.venuesManagementService.updateVenueConfig()),
				finalize(() => {
					this.modalService.close(this.modal.deleteImage)
					this.loadingApi = false
				})
			)
			.subscribe()
	}

	saveOrder(bannersOrThumbs: Array<Pick<ImagesCropped, 'imageUrl' | 'order'>>, type: Types) {
		const updatedImages = structuredClone(this.venueImages)
		if (!updatedImages) return
		if (type === 'banners' || type === 'thumb') {
			updatedImages[type] = updatedImages[type].map(item => {
				const image = bannersOrThumbs.find(b => b.imageUrl === item.coverUrl)
				if (image) return { ...item, order: image.order as number }
				return item
			})
		}

		this.modalService.open(this.modal.loading)
		this.venuesConfigApi
			.updateImages({
				partnerId: this.partnerId,
				venueId: this.venueId,
				images: updatedImages,
			})
			.pipe(
				switchMap(() => this.venuesManagementService.updateVenueConfig()),
				finalize(() => this.modalService.close(this.modal.loading))
			)
			.subscribe()
	}

	private setNewOrder(venueImages: VenuesImages, type: Types) {
		const updatedImages = structuredClone(venueImages)
		if (type === 'banners' || type === 'thumb')
			updatedImages[type] = updatedImages[type].map((image, idx) => ({
				...image,
				order: idx + 1,
			}))

		return updatedImages
	}

	private replaceCoverUrlToOptimzedOrOriginalURL({
		pathOriginal,
		pathOptimized,
	}: {
		pathOriginal?: string
		pathOptimized?: string
	}) {
		const copyImages = structuredClone(this.venueImages) as VenuesImages
		if (this.type === 'thumb' || this.type === 'banners') {
			let index = copyImages[this.type].length - 1
			if (!pathOriginal) {
				index = copyImages[this.type].findIndex(
					config => config.coverUrl === this.imageCropped?.imageUrl
				)
			}
			if (pathOptimized) {
				copyImages[this.type][index].optimizedCoverUrl = pathOptimized
			}
			if (pathOriginal) {
				copyImages[this.type][index].coverUrl = pathOriginal
			}
		}
		if (this.type === 'logo') {
			if (!copyImages.logo) {
				copyImages.logo = {
					logoUrl: undefined as any,
					optimizedLogoUrl: undefined as any,
				}
			}
			if (pathOptimized) {
				copyImages.logo.optimizedLogoUrl = pathOptimized
			}
			if (pathOriginal) {
				copyImages.logo.logoUrl = pathOriginal
			}
		}
		return copyImages
	}

	private extractBase64string(imageBase64: string): string {
		// Decodes the base64 file to extract the stringfied file and the file extension
		const matches = imageBase64.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/)
		if (!matches) {
			throw new Error('não conseguiu extrair base64')
		}
		return matches[2]
	}
}
