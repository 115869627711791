<ul class="breadcrumbs">
	<ng-container *ngIf="breadcrumbs$ | async as breadcrumbs">
		<li
			class="breadcrumbs__item"
			*ngFor="let breadcrumb of breadcrumbs"
			[routerLink]="[breadcrumb.url]"
			routerLinkActive="item--active"
			[routerLinkActiveOptions]="{ exact: true }"
		>
			{{ breadcrumb.title }}
		</li>
	</ng-container>
</ul>
