import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment as env } from 'src/environments/environment' //ALTERAR VARIÁVEIS PARA PARCEIRO ITAÚ
import { Observable, delay, of } from 'rxjs'
import {
	ReportGiftItau,
	ReportGiftItauConverted,
	ReportItauRedemptionsConverted,
	ReportItauRedemptionsResponse,
} from './report.interface'
import { formatUtil } from 'src/app/services/format.util'
import { formatDateToDDMMYYYY } from 'src/app/services/date.util'

export type Option = {
	icon: string
	name: string
	slug: string
	preview?: boolean
	sendEmail?: boolean
}

export type Sector = {
	title: string
	options: Option[]
}
@Injectable({ providedIn: 'root' })
export class ReportsItauService {
	public sectors: Sector[] = [
		{
			title: 'Canais',
			options: [
				{
					icon: 'tagmeicon tagmeicon-presente',
					name: 'Reservas com vouchers',
					slug: 'reservas-itau',
				},

				// {
				// 	icon: 'tagmeicon tagmeicon-presente',
				// 	name: 'Reservas Itaú Shop (sem vouchers)',
				// 	slug: 'reservas-itau-shop',
				// },
			],
		},
		{
			title: 'Itaú Shop',
			options: [
				{
					icon: 'tagmeicon tagmeicon-presente',
					name: 'Reservas Itaú Shop (sem vouchers)',
					slug: 'reservas-itau-shop',
				},
			],
		},
		{
			title: 'Financeiro',
			options: [
				{
					icon: 'tagmeicon tagmeicon-voucher-mini',
					name: 'Emissão de gift',
					slug: 'emissao-gift',
				},
				// 		{
				// 			icon: 'tagmeicon tagmeicon-cutlery',
				// 			name: 'Reservas com resgate',
				// 			slug: 'reservas-itau-resgate',
				// 		},
			],
		},
	]

	public options = this.sectors.flatMap(sector => sector.options)

	constructor(private http: HttpClient) {}

	chooseReportToCall(
		slug: string,
		query: {
			startDate: string
			endDate: string
			partnerId?: string
			filterDate?: number
			venue?: string
		},
		format: 'csv' | 'json' = 'csv'
	): Observable<any> {
		const responseType = (format === 'csv' ? 'blob' : 'json') as any
		switch (slug) {
			case 'reservas-itau':
				return this.http.get(
					`${env.channelsAPI.baseURL}reports/menuPerson/benefits?startDate=${query.startDate}&endDate=${query.endDate}&format=${format}`,
					{ responseType }
				)
			case 'reservas-itau-shop':
				return this.http.get(
					`${env.channelsAPI.baseURL}reports/partner/menu-personnalite?startDate=${query.startDate}&endDate=${query.endDate}&format=${format}`,
					{ responseType }
				)

			case 'emissao-gift':
				return this.http.get(
					`${env.giftURL}/report/vouchers?startDate=${query.startDate}&expirationDate=${query.endDate}&venue=${query.venue}`,
					{ responseType }
				)
			case 'reservas-itau-resgate':
				/*return this.http.get(
					`${env.channelsAPI.baseURL}reports/bradesco/benefitsAndCelebrationsVenue?startDate=${query.startDate}&endDate=${query.endDate}&format=${format}`,
					{ responseType }
				)*/
				return of([
					{
						_id: '65cf8a390942264012262e66',
						venueId: '5c052d64fa271c9ee3c602f5',
						venueName: 'CT Boucherie Leblon',
						name: 'Mary Smith',
						email: 'mary@mail.com',
						phone: '5521999999998',
						partySize: 8,
						orderDay: '18/02/2024',
						orderTime: '13:00',
						validatedDay: '18/02/2024',
						validatedTime: '12:55',
						intent: 'reservation',
						status: 'VALIDATED',
						menuQuantityRedemption: 1,
						voucherCode: '123456',
						menuQuantity: null,
						menuPrice: 135,
						menuTransferValue: 128.25,
						menuVegetarianQuantity: null,
						menuVegetarianPrice: null,
						menuVegetarianTransferValue: null,
						wineQuantity: null,
						winePrice: null,
						wineTransferValue: null,
						totalTransferValue: 128.25,
					},
					{
						_id: '65cf8a390942264012262e65',
						venueId: '5c052d64fa271c9ee3c602f5',
						venueName: 'CT Boucherie Leblon',
						name: 'Mary Smith',
						email: 'mary@mail.com',
						phone: '5521999999998',
						partySize: 8,
						orderDay: '18/02/2024',
						orderTime: '13:00',
						validatedDay: '18/02/2024',
						validatedTime: '12:55',
						intent: 'reservation',
						status: 'VALIDATED',
						menuQuantityRedemption: null,
						voucherCode: '123456',
						menuQuantity: 1,
						menuPrice: 135,
						menuTransferValue: 128.25,
						menuVegetarianQuantity: null,
						menuVegetarianPrice: null,
						menuVegetarianTransferValue: null,
						wineQuantity: null,
						winePrice: null,
						wineTransferValue: null,
						totalTransferValue: 128.25,
					},
					{
						_id: '65ca96c60942264012262b76',
						venueId: '5c052d64fa271c9ee3c602f5',
						venueName: 'CT Boucherie Leblon',
						name: 'Peter Will',
						email: 'peter@mail.com',
						phone: '5521999999997',
						partySize: 2,
						orderDay: '17/02/2024',
						orderTime: '19:00',
						validatedDay: '17/02/2024',
						validatedTime: '16:28',
						intent: 'reservation',
						status: 'VALIDATED',
						menuQuantityRedemption: null,
						voucherCode: null,
						menuQuantity: 1,
						menuPrice: 135,
						menuTransferValue: 135,
						menuVegetarianQuantity: null,
						menuVegetarianPrice: null,
						menuVegetarianTransferValue: null,
						wineQuantity: null,
						winePrice: null,
						wineTransferValue: null,
						totalTransferValue: 135,
					},
					{
						_id: '65c50e3c094226401226265f',
						venueId: '603e3ed29c4deb3500e6d4d7',
						venueName: 'Manekineko CasaShopping',
						name: 'John Doe',
						email: 'john@mail.com',
						phone: '5521999999999',
						partySize: 2,
						orderDay: '02/03/2024',
						orderTime: '19:00',
						validatedDay: null,
						validatedTime: null,
						intent: 'reservation',
						status: 'CHK_DONE',
						menuQuantityRedemption: null,
						voucherCode: null,
						menuQuantity: 1,
						menuPrice: 135,
						menuTransferValue: 128.25,
						menuVegetarianQuantity: 1,
						menuVegetarianPrice: 135,
						menuVegetarianTransferValue: 128.25,
						wineQuantity: 1,
						winePrice: 105,
						wineTransferValue: 99.75,
						totalTransferValue: 356.25,
					},
					{
						_id: '65d143070942264012262f99',
						venueId: '603e3ed29c4deb3500e6d4d7',
						venueName: 'Manekineko CasaShopping',
						name: 'John Doe',
						email: 'john@mail.com',
						phone: '5521999999999',
						partySize: 2,
						orderDay: '18/02/2024',
						orderTime: '13:00',
						validatedDay: '18/02/2024',
						validatedTime: '13:06',
						intent: 'reservation',
						status: 'VALIDATED',
						menuQuantityRedemption: null,
						voucherCode: null,
						menuQuantity: 2,
						menuPrice: 135,
						menuTransferValue: 128.25,
						menuVegetarianQuantity: null,
						menuVegetarianPrice: null,
						menuVegetarianTransferValue: null,
						wineQuantity: null,
						winePrice: null,
						wineTransferValue: null,
						totalTransferValue: 256.5,
					},
				])
			default:
				console.warn('Nenhum slug encontrado')
				return of([])
		}
	}

	sendItauResgatadoEmail(body: {
		monthYear: string // Dezembro/2023,
		dateLimit: string // Até 7 dias após o envio o e-eamil
		endDate: string //   Dia 20 do mês de envio (Se o dia 20 cair no fds ou feriado, anteciapar para o dia útil mais próximo.)
		venuesToSend: Array<{
			venueId: string
			venueName: string
			totalValue: number //totalTransferValue por Venue
			qtdVoucher: number // somatório de menuQuantityRedemption + menuQuantity + menuVegetarianQuantity POR venue
			// Manekineko: qtdvoucher = 3, totalValue = 256.5 + 356.25
		}>
	}) {
		/*return this.http.post(`${env.channelsAPI.baseURL}email/progmenu/send/benefits`, body)*/
		return of({
			success: [
				{
					venueId: '12b',
					venueName: 'Bistro',
					message: 'E-mail enviado para: teste1@mail.com, teste2@email.com',
				},
				{
					venueId: '13b',
					venueName: 'Jappa',
					message: 'E-mail enviado para: teste1@mail.com, teste2@email.com',
				},
			],
			failures: [
				{
					venueId: '14b',
					venueName: 'Jappa',
					message: 'Não tem e-mail cadastrado. Sendgrid deu erro.',
					retry: true,
				},
				{
					venueId: '15b',
					venueName: 'Jappa 2',
					message: 'Não tem e-mail cadastrado. Sendgrid deu erro.',
				},
			],
		}).pipe(delay(2000))
	}

	reportsItauGiftAdapter(data: ReportGiftItau): ReportGiftItauConverted {
		return {
			Código: data.code,
			Nome: data.customerFullName,
			email: data.customerEmail,
			//Telefone: data.phone,
			'Valor do Gift': data.giftNominalValue ? formatUtil.formatCurrency(data.giftNominalValue) : '',
			'Nome do Gift': data.gift.name,
			//'Número do Lote': data.lotNumber,
			'Data inicial': formatDateToDDMMYYYY(new Date(data.startDate)),
			'Data de Validade': formatDateToDDMMYYYY(new Date(data.expirationDate)),
			'Valor Bruto Recebido': data.tagmeGrossRevenueValue
				? formatUtil.formatCurrency(data.tagmeGrossRevenueValue)
				: '',
			'Valor Líquido Recebido': data.tagmeNetRevenueValue
				? formatUtil.formatCurrency(data.tagmeNetRevenueValue)
				: '',
			'Valor de Repasse': data.venueTransferValue
				? formatUtil.formatCurrency(data.venueTransferValue)
				: '',
		}
	}

	reportsItauRedemptionAdapter(data: ReportItauRedemptionsResponse): ReportItauRedemptionsConverted {
		const statusMap = {
			CHK_DONE: 'Confirmado',
			VALIDATED: 'Validado',
			CHK_CANCEL: 'Cancelado',
		}
		const intentMap = {
			reservation: 'Reserva',
			checkin: 'Check-In',
		}
		return {
			'ID do Pedido': data._id,
			'ID da Venue': data.venueId,
			Venue: data.venueName,
			Nome: data.name,
			Email: data.email,
			Telefone: data.phone,
			'Quantidade de pessoas': data.partySize,
			'Data do pedido': data.orderDay,
			'Hora do pedido': data.orderTime,
			Origem: intentMap[data.intent],
			Status: statusMap[data.status] || 'Desconhecido',
			'Data da validação': data.validatedDay,
			'Hora da Validação': data.validatedTime,

			'Quantidade de Vouchers': data.menuQuantityRedemption,

			'Código do voucher': data.voucherCode,
			'Preço do Menu': data.menuPrice ? formatUtil.formatCurrency(data.menuPrice) : '',
			'Quantidade do Menu': data.menuQuantity,
			'Valor de repasse do Menu': data.menuTransferValue
				? formatUtil.formatCurrency(data.menuTransferValue)
				: '',

			'Quantidade do Menu Vegetariano': data.menuVegetarianQuantity,
			'Preço do Menu Vegetariano': data.menuVegetarianPrice
				? formatUtil.formatCurrency(data.menuVegetarianPrice)
				: '',
			'Valor de repasse do Menu Vegetariano': data.menuVegetarianTransferValue
				? formatUtil.formatCurrency(data.menuVegetarianTransferValue)
				: '',

			'Quantidade do Vinho': data.wineQuantity,
			'Preço do Vinho': data.winePrice ? formatUtil.formatCurrency(data.winePrice) : '',
			'Valor de Repasse do Vinho': data.wineTransferValue
				? formatUtil.formatCurrency(data.wineTransferValue)
				: '',

			'Valor total de repasse': data.totalTransferValue
				? formatUtil.formatCurrency(data.totalTransferValue)
				: '',
		}
	}
}
