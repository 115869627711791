import { Component } from '@angular/core'
import { RouterExtService } from './services/router.service'

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	title = 'admin'
	constructor(private routerExt: RouterExtService) {}
}
