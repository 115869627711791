import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ConciergeAdminService } from './concierge-admin.service'

@Component({
	selector: 'app-concierge-admin',
	templateUrl: './concierge-admin.component.html',
	styleUrls: ['./concierge-admin.component.scss'],
})
export class ConciergeAdminComponent implements OnInit {
	public loading: boolean = true

	public userInfosConcierge: any[] = [
		// { userName: 'João Paulo Alves', userTel: '21 99315-7878', userEmail:'joão@tagme.com.br', userPass:'123',userId:1 },
		// { userName: 'Jeff Bezos', userTel: '21 99444-5555', userEmail:'jeffinho@amazon.com', userPass:'abc',userId:2 },
		// { userName: 'Elon Musk', userTel: '21 9714-6958', userEmail:'elontwitter@bitcoin.com', userPass:'qwe',userId:3 },
	]

	constructor(private conciergeAdminService: ConciergeAdminService, private router: Router) {}

	ngOnInit(): void {
		this.getConcierges()
	}

	public async getConcierges() {
		await this.conciergeAdminService.getConciergesData('conciergeAdmin').subscribe(res => {
			this.userInfosConcierge = Object.values(res)
			this.loading = false
		})
	}

	public createConciergeAdmin() {
		this.router.navigate(['create-concierge-admin'])
	}
}
