import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../../environments/environment'

@Injectable({
	providedIn: 'root',
})
export class CreateConciergeAdminService {
	constructor(private http: HttpClient) {}
	private env = environment

	public postInfoConciergeAdmin(data: any) {
		return this.http.post(`${this.env.channelsAPI.baseURL}`, data)
	}

	public patchInfoConciergeAdminToEdit(data: any, id: any) {
		return this.http.post(`${this.env.channelsAPI.baseURL}`, data)
	}

	public getInfoConciergeAdmin(id: any) {
		return this.http.get(`${this.env.channelsAPI.baseURL}benefits/${id}`)
	}

	public removeInfoConciergeAdmin(conciergeToRemove: any) {
		return this.http.delete(`${this.env.channelsAPI.baseURL}` + conciergeToRemove)
	}
}
