<div class="page">
	<app-header-master></app-header-master>

	<div class="page__body">
		<div class="page__wrapper wrapper">
			<app-back-button [addClass]="'element'"></app-back-button>

			<div class="category">
				<h2 class="category__title">Programa Menu Bradesco</h2>

				<div class="category__box">
					<app-accordion slug="002" [name]="'Gestão de categorias'" [tempAccordion]="tempAccordion">
						<ng-template #tempAccordion>
							<div class="category__grid" *ngIf="categoryCards$ | async as categoryCards">
								<div class="category__column" *ngFor="let item of categoryCards">
									<app-card
										slug="002"
										[idCategory]="item._id"
										[nameCardCategory]="item.name"
										[imgCardCategory]="'tagmeicon tagmeicon-star-fit'"
										[activeCardCategory]="item.enabled"
									>
									</app-card>
								</div>
							</div>
							<app-loading *ngIf="loading" class="loading" slug="001"></app-loading>
							<button class="category__button button__light" (click)="createCategory()">
								Criar nova categoria
							</button>
						</ng-template>
					</app-accordion>
				</div>
			</div>
		</div>
	</div>
</div>
