<button class="payments-btn" (click)="clickButton()">
	<p>
		{{ firstDay }} <ng-container *ngIf="isToday">(hoje)</ng-container>
		<ng-container *ngIf="lastDay !== firstDay">
			até
			{{ lastDay }}
		</ng-container>
	</p>
	<i class="payments-btn__icon tagmeicon tagmeicon-seta"></i>
</button>
