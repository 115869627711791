<div class="hVenue">
	<div class="hVenue__wrapper wrapper">
		<div class="hVenue__partner" (click)="clickTitle()" *ngIf="showIcon">
			<p class="hVenue__title">{{ name }}</p>
			<i class="hVenue__icon-seta tagmeicon tagmeicon-seta"></i>
		</div>
		<div class="hVenue__partner" *ngIf="!showIcon">
			<p class="hVenue__title">{{ name }}</p>
		</div>
		<div class="hVenue__options">
			<i class="hVenue__icon tagmeicon tagmeicon-options-dots"></i>
		</div>
	</div>
</div>
