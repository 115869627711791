<div class="reservation-admin" [ngClass]="openedVenue ? 'opened' : ''">
	<!-- MODAL SELECT VENUE -->
	<app-modal slug="001" [closeModalSearch]="closeModalSearch" [typeModal]="'venue'">
		<div class="modal__content" content>
			<p class="modal__text">Selecione um estabelecimento (Venue)</p>
			<div class="modal__select" [ngClass]="tooglerVenue ? 'opened' : ''" (click)="openVenue()">
				<label class="modal__label">
					<input
						class="modal__input"
						list="brow"
						type="text"
						[(ngModel)]="selectedVenue"
						name="selectedVenue"
						placeholder="Digite o nome aqui"
						(ngModelChange)="this.selectedVenueUpdate.next($event)"
						value="{{ nameVenueSelected }}"
					/>
					<i class="modal__icon tagmeicon tagmeicon-buscar" (click)="search()"></i>
				</label>
				<datalist id="brow" class="modal__select__list">
					<option
						class="modal__select__item"
						*ngFor="let item of restaurantVenues"
						value="{{ item.name }}"
					></option>
				</datalist>
			</div>
			<div class="modal__last-viewed">
				<p class="modal__last-viewed__title">Últimos visualizados</p>
				<app-loading *ngIf="loading" class="loading" slug="001"></app-loading>
				<ng-container *ngIf="!loading">
					<ul class="tab001" *ngFor="let item of newLastViewed">
						<li class="tab001__item" (click)="tabSearchVenue(item[0])">
							{{ item[1] }}
						</li>
					</ul>
				</ng-container>
			</div>
		</div>
	</app-modal>

	<!-- MODAL OPTIONS -->
	<app-modal slug="001" [closeModalSearch]="openedOptions" [typeModal]="''">
		<div class="modal__content" content>
			<div class="modal__grid">
				<div class="modal__column" *ngFor="let cards of cardButtons">
					<app-card
						slug="001"
						[nameCards]="cards.title"
						[iconCards]="cards.icon"
						[idCards]="cards.id"
						[isOption]="cards.isOption"
						[isLogout]="cards.isLogout"
					>
					</app-card>
				</div>
			</div>
		</div>
	</app-modal>

	<div class="page">
		<app-header-master></app-header-master>

		<div class="page__header">
			<div class="page__wrapper wrapper">
				<div class="reservation-admin__header">
					<div class="reservation-admin__header__name" (click)="openModalVenue()">
						<p class="reservation-admin__header__name__title">{{ nameVenues }}</p>
						<i class="reservation-admin__header__name__icon tagmeicon tagmeicon-seta"></i>
					</div>
					<i
						class="reservation-admin__header__icon tagmeicon tagmeicon-options-dots"
						(click)="openModalOptions()"
					></i>
				</div>
			</div>
		</div>
		<div class="page__body">
			<div class="page__wrapper wrapper">
				<div class="reservation-admin__body">
					<p class="reservation-admin__body__text">Em breve!</p>
				</div>
			</div>
		</div>
	</div>
</div>
