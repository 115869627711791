import { Component } from '@angular/core'
import * as Papa from 'papaparse'
import { VoucherBBI } from './vouchers-bbi'
import { VouchersApi } from 'src/app/services/vouchers.service'
import { HttpStatus } from 'src/app/services/get-async.util'
import { take } from 'rxjs'

@Component({
	templateUrl: `vouchers-bbi.page.html`,
	styleUrls: [`vouchers-bbi.page.scss`],
})
export class VouchersBBIPage {
	selectedType = 'thunder'
	filename = ''
	vouchers: VoucherBBI[] = []
	loading = false
	hasErrors = false
	status: HttpStatus = 'idle'
	results: { successVouchers: any[]; failureVouchers: any[] } = {
		successVouchers: [],
		failureVouchers: [],
	}
	error: string = ''

	constructor(private vouchersApi: VouchersApi) {}

	reset() {
		this.filename = ''
		this.vouchers = []
		this.loading = false
		this.hasErrors = false
		this.status = 'idle'
		this.results = {
			successVouchers: [],
			failureVouchers: [],
		}
		this.error = ''
	}

	onFileSelected(event: any) {
		this.reset()
		const file: File = event.target.files[0]
		this.filename = file.name
		this.selectedType = file.name.includes('thunder') ? 'thunder' : 'quadrato'
		const fileReader: FileReader = new FileReader()
		fileReader.onloadend = e => {
			const csvData = fileReader.result as string
			this.convertCsvToJson(csvData)
		}
		fileReader.readAsText(file)
	}

	convertCsvToJson(csvData: string) {
		const parsedData = Papa.parse(csvData, { header: true, skipEmptyLines: true })
		this.vouchers = parsedData.data.map(
			(obj: any) => new VoucherBBI(obj.cupom.replace(/\t/g, ''), this.selectedType, this.filename)
		)
		this.hasErrors = this.vouchers.some(voucher => voucher.errors.length > 0)
	}

	importVouchers() {
		if (this.hasErrors) return
		this.status = 'pending'
		this.vouchersApi
			.importVouchersBBI({ vouchersBBI: this.vouchers, filename: this.filename })
			.pipe(take(1))
			.subscribe({
				next: res => {
					this.status = 'resolved'
					this.results = res
				},
				error: res => {
					this.status = 'rejected'
					this.error = res?.error?.message
				},
			})
	}
}
