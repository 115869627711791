import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'

@Component({
	selector: 'app-modal001',
	templateUrl: './modal001.component.html',
	styleUrls: ['./modal001.component.scss'],
})
export class Modal001Component implements OnInit {
	@Input() closeModalSearch: boolean = true
	@Input() typeModal: string = ''

	constructor(private router: Router) {}

	ngOnInit(): void {}

	public closeModal() {
		if (this.typeModal.toLowerCase() == 'venue') {
			this.router.navigate(['home'])
		} else {
			this.closeModalSearch = true
		}
	}
}
