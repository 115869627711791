<div class="page">
	<div class="page__wrapper wrapper">
		<app-back-button></app-back-button>
	</div>

	<ch-modal [id]="savedModal" top="25%">
		<div style="background-color: white; padding: 30px; border-radius: 4px">
			<h1 style="color: black">
				{{ saved }}
			</h1>
			<button
				class="create__button button"
				style="margin-top: 10px"
				(click)="modalService.close(savedModal)"
			>
				Fechar
			</button>
		</div>
	</ch-modal>

	<ch-modal [id]="loadingModal" top="25%" [preventOutsideClickClose]="true">
		<div style="background-color: white; padding: 30px; border-radius: 4px">
			<h1 style="color: black">{{ loadingMessage }}</h1>
		</div>
	</ch-modal>

	<div class="page__body">
		<div class="page__wrapper wrapper">
			<div class="create">
				<p class="create__title">
					{{ benefitTitle }} <span *ngIf="saved">({{ saved }})</span>
				</p>

				<!--<div class="create__photo">
					<div *ngIf="!imageSrc" class="create__photo__box"> 
					<i class="create__photo__box__icon tagmeicon tagmeicon-foto"></i> 
				 	</div> 
				 	<img 
				 		*ngIf="imageSrc" 
				 		class="create__photo__box" 
				 		id="selectedImage" 
				 		[src]="imageSrc" 
				 		alt="your image" 
				 	/> 
				 	<p class="create__photo__msg"> 
				 		Arraste e solte<br /> 
						a foto aqui 
				 	</p> 
				 </div> 
				 <div class="create__box"> 
				 	<label for="files" class="create__link" > Escolher foto </label> 
				 	<input id="files" class="create__box__input" type="file"  /> 
				 </div> -->

				<!-- FORM -->
				<form class="create__form" [formGroup]="benefitForm" (ngSubmit)="saveBenefit()">
					<!-- VENUE ID -->
					<ng-container *ngIf="venueId">
						<label class="create__label" for="venueId">
							<span>VenueId</span>
						</label>
						<div
							id="venueId"
							name="venueId"
							type="text"
							class="create__input create__input--disabled"
							placeholder="Ex.: Uma sobremesa"
						>
							{{ venueId }}
						</div>
					</ng-container>
					<!-- SWITCH -->
					<label class="switch">
						<input
							type="checkbox"
							class="switch__input"
							name="enabled"
							formControlName="enabled"
						/>
						<div class="switch__round"></div>
						<p class="switch__txt">{{ benefitSwitchText }}</p>
					</label>
					<label class="create__label" for="nameId">
						<span>Nome do benefício</span>
					</label>
					<input
						id="nameId"
						formControlName="name"
						name="name"
						type="text"
						class="create__input"
						placeholder="Ex.: Sobremesa"
					/>

					<label class="create__label" for="descriptionId">
						<span>Descrição do benefício</span>
					</label>
					<input
						id="descriptionId"
						formControlName="description"
						name="description"
						type="text"
						class="create__input"
						placeholder="Ex.: Uma sobremesa"
					/>
					<label class="create__label" for="descriptionLongId">
						<span>Descrição longa do benefício</span>
					</label>
					<textarea
						id="descriptionLongId"
						name="descriptionLong"
						formControlName="descriptionLong"
						type="text"
						class="create__input"
						placeholder="Ex.: O restaurante oferece tal sobremesa em acordo com o termo de serviço....."
					></textarea>

					<ng-container *ngIf="benefitForm.get('menuId')">
						<label class="create__label" for="menuId">
							<span>ID do Menu</span>
						</label>
						<input
							id="menuId"
							name="menu"
							formControlName="menuId"
							type="text"
							class="create__input"
							placeholder="Ex.: ID da subcategoria do Menu"
						/>
					</ng-container>

					<label class="create__label" for="orderId">
						<span>Ordem</span>
					</label>
					<input
						id="orderId"
						name="order"
						formControlName="order"
						type="number"
						class="create__input"
						placeholder="Ex.: 1"
					/>

					<label class="create__label" for="valueId">
						<span>Valor do benefício</span>
					</label>
					<input
						id="valueId"
						formControlName="value"
						name="value"
						type="text"
						class="create__input"
						placeholder="Ex.: 30,00"
					/>

					<label class="create__label" for="transferValueId">
						<span>Valor de repasse (para o restaurante)</span>
					</label>
					<input
						id="transferValueId"
						formControlName="transferValue"
						name="transferValue"
						type="text"
						class="create__input"
						placeholder="Ex.: 3,00"
					/>

					<label class="create__label" for="revenueValueId">
						<span>Valor a receber (do parceiro)</span>
					</label>
					<input
						id="revenueValueId"
						formControlName="revenueValue"
						name="transferValue"
						type="text"
						class="create__input"
						placeholder="Ex.: 3,00"
					/>
					<button type="submit" class="create__button button">Salvar</button>
					<!-- [ngClass]="buttonDisabled ? ' disabled' : ''" -->
				</form>
			</div>
		</div>
	</div>
</div>
