<div *ngIf="msg">
	<div class="msg"></div>
	<div class="modal">
		<i (click)="close()" class="tagmeicon tagmeicon-close"></i>
		<div *ngIf="status==='pending'">
			<app-loading class="loading" slug="001"></app-loading>
		</div>
		<div *ngIf="status==='resolved'">
			<p>Voucher criado com sucesso</p>
		</div>
		<div *ngIf="status==='rejected'">
			<p>Algo de errado</p>
		</div>
	</div>
</div>
<app-header-master></app-header-master>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
	<div class="form">
		<div class="field__label" style="margin: 16px 0" *ngIf="vouchers.length>0">
			Vouchers criados:
			<p style="font-weight: 400" *ngFor="let voucher of vouchers">{{voucher}}</p>
		</div>
		<div class="field__label">
			Nome completo
			<div class="field__wrapper">
				<i class="field__icon left tagmeicon tagmeicon-nome"></i>
				<input
					class="field__input"
					type="text"
					formControlName="name"
					name="name"
					placeholder="Nome"
				/>
			</div>
		</div>

		<div class="field__label">
			Email
			<div class="field__wrapper">
				<i class="field__icon left tagmeicon tagmeicon-email"></i>
				<input
					class="field__input"
					type="mail"
					formControlName="email"
					name="email"
					placeholder="person@email.com"
				/>
			</div>
		</div>

		<div class="field__label">
			Telefone
			<div class="field__wrapper">
				<i class="field__icon left tagmeicon tagmeicon-app"></i>
				<input
					class="field__input"
					formControlName="phone"
					name="phone"
					placeholder="Telefone com DDD"
					type="text"
					mask="(00) 00000-0000"
				/>
			</div>
		</div>

		<div class="field__label">
			Venue ID
			<div class="field__wrapper">
				<i class="field__icon left tagmeicon tagmeicon-mesas"></i>
				<input
					class="field__input"
					type="text"
					formControlName="venueId"
					name="venueId"
					placeholder="ID da venue"
				/>
			</div>
		</div>

		<div class="field__label">
			Valor
			<div class="field__wrapper">
				<i class="field__icon left tagmeicon tagmeicon-maquininha"></i>
				<input
					class="field__input"
					type="text"
					formControlName="price"
					name="price"
					placeholder="00,00"
					currencyMask
					[options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
				/>
			</div>
		</div>

		<div class="field__label">
			Nome da campanha (opcional)
			<div class="field__wrapper">
				<i class="field__icon left tagmeicon tagmeicon-megafone-chamar"></i>
				<input
					class="field__input"
					type="text"
					formControlName="note"
					name="note"
					placeholder="Campanha..."
				/>
			</div>
		</div>

		<div class="field__label">
			Observação
			<div class="field__wrapper">
				<i class="field__icon left tagmeicon tagmeicon-voucher-mini"></i>
				<input
					class="field__input"
					type="text"
					formControlName="note"
					name="note"
					placeholder="obs..."
				/>
			</div>
		</div>

		<button
			class="button button-form"
			[class.disabled]="form.invalid"
			type="submit"
			[disabled]="form.invalid"
		>
			Salvar
		</button>
	</div>
</form>
