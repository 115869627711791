import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { CreateCategoryService } from './create-category.service'

@Component({
	selector: 'app-create-category',
	templateUrl: './create-category.component.html',
	styleUrls: ['./create-category.component.scss'],
})
export class CreateCategoryComponent implements OnInit {
	public buttonDisabled: boolean = true
	public imageSrc: any = ''
	public brandName: any = 'Selecione a bandeira do cartão'
	public typeName: any = 'Selecione o tipo'
	public brandSelected: boolean = false
	public typeSelected: boolean = false
	public range: any[] = []
	public missingData: any[] = []
	public nameOfBenefitsEdit: any[] = []
	public selectedBenefits: any[] = []
	public insertedRange: boolean = false
	public id: number = 0
	public idBenefits: number = 0
	public editCategory = false
	public showBenefit: boolean = false
	public bodyPostCategory: { [key: string]: any[] } = {}
	public loading: boolean = false
	public openedBenefit: boolean = false
	public openedBrand: boolean = false
	public openedType: boolean = false
	public enteredByGetInfoCategoryFunction: boolean = false
	public getIdCategory: any = ''
	public getNameBenefit: any[] = []
	public dataEditCategory: any
	public benefits: any[] = []
	public brands: any[] = [
		{ name: 'AMEX' },
		{ name: 'Visa' },
		{ name: 'Master' },
		{ name: 'Elo' },
		{ name: 'Diners' },
	]

	constructor(
		private router: Router,
		private createCategoryService: CreateCategoryService,
		private activatedRoute: ActivatedRoute
	) {
		if (Object.keys(this.activatedRoute.snapshot.params).length == 0) {
			this.editCategory = false
		} else {
			this.getIdCategory = this.activatedRoute.snapshot.params
			this.getIdCategory = this.getIdCategory.id
			this.editCategory = true
		}
	}

	ngOnInit(): void {
		if (this.editCategory) {
			this.functionForEditCategory()
		}
	}

	public async functionForEditCategory() {
		await this.getInfoCategory(this.getIdCategory)
		this.buttonDisabled = false
	}

	public readURL(event: any): void {
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0]

			const reader = new FileReader()
			reader.onload = e => (this.imageSrc = reader.result)

			reader.readAsDataURL(file)
		}
	}

	public async getInfoCategory(idCategory: any) {
		await this.createCategoryService.getInfoCategory(idCategory).subscribe(res => {
			this.dataEditCategory = res
			this.range = (this.dataEditCategory.ranges || []).map((item: any) => ({
				...item,
				idRange: Math.random(),
			}))
			this.brandName = this.capitalizeFirstLetter(this.dataEditCategory.brand)
			this.typeName = this.dataEditCategory.type
			this.brandSelected = true
			this.typeSelected = true
			this.showBenefit = true
			this.insertedRange = true
			this.loading = false
			this.getBenefits(true)
		})
	}

	// public deleteCategory(){
	//   this.createCategoryService.removeCategory(this.dataEditCategory._id).subscribe((res) => {
	//     console.log(res)
	//   })
	//   this.router.navigate(["category"])
	// }

	public capitalizeFirstLetter(word: string) {
		return word.charAt(0).toUpperCase() + word.slice(1)
	}

	public selectBrand(brandName: string) {
		this.brandName = brandName
		this.brandSelected = true
		this.togglerBrand()
		this.verify()
	}

	public selectType(typeName: string) {
		this.typeName = typeName
		this.typeSelected = true
		this.togglerType()
		this.verify()
	}

	public async togglerBrand() {
		this.openedBrand = !this.openedBrand
	}

	public async togglerType() {
		this.openedType = !this.openedType
	}

	public async togglerBenefit() {
		this.openedBenefit = !this.openedBenefit
		if (this.openedBenefit) {
			await this.getBenefits(false)
		}
	}

	public async getBenefits(edit: boolean) {
		await this.createCategoryService.getBenefitsList().subscribe(res => {
			this.benefits = Object.values(res)
			this.benefits = Object.values(this.benefits.filter(item => item.enabled == true))
			if (edit) {
				this.benefits = Object.values(res)
				for (var i = 0; i < this.dataEditCategory.benefits.length; i++) {
					var nameBenefit = Object.values(
						this.benefits.filter(item => item._id == this.dataEditCategory.benefits[i])
					)
					this.selectBenefit(nameBenefit[0]._id, nameBenefit[0].name, nameBenefit[0].icon, false)
				}
			}
		})
	}

	public addRange() {
		var first = (<HTMLInputElement>document.getElementById('firstRange')).value
		var second = (<HTMLInputElement>document.getElementById('secondRange')).value

		if (first !== '' && !first.match(/[a-z]/i)) {
			this.id = this.id + 1
			var idRange = this.id

			if (!second.match(/[a-z]/i)) {
				if (second == '') {
					var unique = parseInt(first)
					this.range.push({ unique, idRange })
				} else {
					var start = parseInt(first)
					var end = parseInt(second)

					this.range.push({ start, end, idRange })
				}
			} else {
				alert('Somente números, sem letras')
			}
			this.insertedRange = true
		} else {
			alert("Range único ? Adicione os números no 'Range(início)', somente números")
		}
		this.verify()
	}

	public removeRange(i: number) {
		this.range = this.range.filter(item => item.idRange !== i)
		if (this.range.length < 1) {
			this.insertedRange = false
		}
		this.verify()
	}

	public selectBenefit(benefitsId: string, benefitsName: string, benefitsIcon: string, edit: boolean) {
		if (this.selectedBenefits.filter(item => item.benefitsId === benefitsId).length < 1) {
			this.selectedBenefits.push({ benefitsId, benefitsName, benefitsIcon })
			this.showBenefit = true
			this.verify()
		}
		if (edit) {
			this.togglerBenefit()
		}
	}

	public removeBenefit(i: number) {
		this.selectedBenefits = this.selectedBenefits.filter(item => item.benefitsId !== i)
		if (this.selectedBenefits.length < 1) {
			this.showBenefit = false
		}
		this.verify()
	}

	public infosBenefit(data: any) {
		this.missingData = Object.values(data).filter(item => item === '')
		this.verify()
	}

	public verify() {
		if (this.missingData.length > 0 || !this.insertedRange || !this.showBenefit || !this.brandSelected) {
			this.buttonDisabled = true
		} else {
			this.buttonDisabled = false
		}
	}

	public async saveCreateCategory(data: any) {
		data['brand'] = this.brandName.toLowerCase()
		//data['type'] = this.typeName
		data['ranges'] = this.range.map(function (item) {
			let { idRange, ...others } = item
			return others
		})
		data['benefits'] = this.selectedBenefits.map(item => item.benefitsId)

		if (this.editCategory) {
			await this.sendInfosToEditCategory(this.getIdCategory, data)
		} else {
			await this.sendInfosToCreateCategory(data)
		}
	}

	public async sendInfosToCreateCategory(data: any) {
		await this.createCategoryService.postInfoCategory(data).subscribe(
			res => {
				setTimeout(() => {
					this.router.navigate(['category'])
				}, 300)
			},
			error => {
				alert(error.error.message)
			}
		)
	}

	public async sendInfosToEditCategory(idCategory: any, data: any) {
		await this.createCategoryService.editCategory(idCategory, data).subscribe(
			res => {
				setTimeout(() => {
					this.router.navigate(['category'])
				}, 300)
			},
			error => {
				alert(error.error.message)
			}
		)
	}
}
