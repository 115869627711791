import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ConciergeService } from './concierge.service'

@Component({
	selector: 'app-concierge',
	templateUrl: './concierge.component.html',
	styleUrls: ['./concierge.component.scss'],
})
export class ConciergeComponent implements OnInit {
	constructor(private conciergeService: ConciergeService, private router: Router) {}

	public buttonEnabled: boolean = false

	public inputs: any[] = [
		{ title: 'Login', icon: 'tagmeicon tagmeicon-nome', type: 'text', name: 'username' },
		{ title: 'Senha', icon: 'tagmeicon tagmeicon-chave-acesso', type: 'password', name: 'password' },
	]

	ngOnInit(): void {}

	public getLoginData(data: any) {
		Object.values(data).filter(item => item === '').length < 1
			? (this.buttonEnabled = true)
			: (this.buttonEnabled = false)
	}

	public async sendLoginData(data: any) {
		if (this.buttonEnabled) {
			data['partnerId'] = '595166383625c506d4868f28'
			await this.conciergeService.postDataLogin(data).subscribe(
				res => {},
				error => {
					alert(error.error.message)
				}
			)

			this.router.navigate(['home'])
		}
	}
}
