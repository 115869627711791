<!-- <app-checkin-popup template="rejected"></app-checkin-popup> -->
<ng-container *ngIf="venue$ | async as venue">
	<app-checkin-header [venue]="venue" (historyClicked)="showCalendar()"></app-checkin-header>
</ng-container>

<div class="checkin__background">
	<div class="checkin__breadcrumb g-max-width">
		<app-breadcrumb></app-breadcrumb>
	</div>
	<div class="checkin__body g-max-width">
		<ng-container *ngIf="dateTextOrder$ | async as dateText">
			<p>{{ dateText }}</p>
		</ng-container>
		<ng-container *ngIf="orders?.length">
			<app-checkin-card
				(buttonClicked)="handleButton($event, order._id)"
				*ngFor="let order of orders"
				[checkinOrder]="order"
			></app-checkin-card>
		</ng-container>
		<ng-container *ngIf="orders?.length === 0">
			<strong>Não foram encontrados Check-Ins.</strong>
		</ng-container>
		<br /><br />
		<ng-container *ngIf="!env.production">
			<ng-container *ngIf="dateTextGift$ | async as dateText">
				<p>{{ dateText }}</p>
			</ng-container>
			<ng-container *ngIf="gifts?.length">
				<app-gift-card *ngFor="let gift of gifts" [gifts]="gift"></app-gift-card>
			</ng-container>
			<ng-container *ngIf="gifts?.length === 0">
				<strong>Não foram encontrados Gifts para esse dia.</strong>
			</ng-container>
		</ng-container>
	</div>
</div>

<ch-modal [id]="modalsId.modalHistory" top="25%">
	<app-calendar-multi
		[id]="calendarId"
		[firstDay]="firstDay"
		[lastDay]="lastDay"
		(firstDaySelected)="selectDay($event, 'first')"
		(lastDaySelected)="selectDay($event, 'last')"
	></app-calendar-multi>
</ch-modal>

<ch-modal [id]="modalsId.modalValidated" top="15%">
	<app-checkin-modal
		template="VALIDATED"
		(yesClicked)="handleYes($event)"
		(goBackClicked)="closeModal(modalsId.modalValidated)"
	></app-checkin-modal>
</ch-modal>

<ch-modal [id]="modalsId.modalRejected" top="15%">
	<app-checkin-modal
		template="CHK_CANCEL"
		(yesClicked)="handleYes($event)"
		(goBackClicked)="closeModal(modalsId.modalRejected)"
	></app-checkin-modal>
</ch-modal>
