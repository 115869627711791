import { Component } from '@angular/core'

@Component({
	template: `
		<label class="field__label">
			E-mail
			<div class="field__wrapper">
				<input class="field__input" />
			</div>
		</label>
	`,
})
export class SandBoxPage {}
