<div class="reservation" [ngClass]="openedVenue ? 'opened' : ''">
	<!-- MODAL SELECT VENUE -->
	<app-modal slug="001" [closeModalSearch]="closeModalSearch" [typeModal]="'venue'">
		<div class="modal__content" content>
			<p class="modal__text">Selecione um estabelecimento (Venue)</p>
			<div class="modal__select" [ngClass]="tooglerVenue ? 'opened' : ''" (click)="openVenue()">
				<label class="modal__label">
					<input
						class="modal__input"
						list="brow"
						type="text"
						[(ngModel)]="selectedVenue"
						name="selectedVenue"
						placeholder="Digite o nome aqui"
						(ngModelChange)="this.selectedVenueUpdate.next($event)"
						value="{{ nameVenueSelected }}"
					/>
					<i class="modal__icon tagmeicon tagmeicon-buscar" (click)="search()"></i>
				</label>
				<datalist id="brow" class="modal__select__list">
					<option
						class="modal__select__item"
						*ngFor="let item of restaurantVenues"
						value="{{ item.name }}"
					></option>
				</datalist>
			</div>
			<div class="modal__last-viewed">
				<p class="modal__last-viewed__title">Últimos visualizados</p>
				<app-loading *ngIf="loading" class="loading" slug="001"></app-loading>
				<ng-container *ngIf="!loading">
					<ul class="tab001" *ngFor="let item of newLastViewed">
						<li class="tab001__item" (click)="tabSearchVenue(item[0])">
							{{ item[1] }}
						</li>
					</ul>
				</ng-container>
			</div>
		</div>
	</app-modal>

	<!-- MODAL OPTIONS -->
	<app-modal slug="001" [closeModalSearch]="openedOptions" [typeModal]="''">
		<div class="modal__content" content>
			<div class="modal__grid">
				<div class="modal__column" *ngFor="let cards of cardButtons">
					<app-card
						slug="001"
						[nameCards]="cards.title"
						[iconCards]="cards.icon"
						[idCards]="cards.id"
						[isOption]="cards.isOption"
						[isLogout]="cards.isLogout"
					>
					</app-card>
				</div>
			</div>
		</div>
	</app-modal>

	<!-- MODAL VALIDAÇÂO -->
	<app-modal slug="001" [closeModalSearch]="openedValidation" [typeModal]="''">
		<div class="modal__content" content>
			<div class="modal__infos" *ngIf="!nemModalValidation || seeVoucher">
				<!-- ficará oculto para abrir a confirmação - linha 82 -->
				<p class="modal__text">Vanessa Otani</p>
				<p class="modal__people">
					2 pessoas -
					<span class="modal__people__waitingTime"> 03:45 minutos </span>
					na espera
				</p>
				<!-- VOUCHER PENDENTE -->
				<div class="modal__benefits pending" *ngIf="!nemModalValidation">
					<!-- esta class altera de acordo com o status -->
					<p class="modal__benefits__title">Cliente Programa Menu Bradesco</p>
					<p class="modal__benefits__text">
						Benefício: <br />
						<span class="modal__benefits__this"> Uma sobremesa </span>
					</p>
					<p class="modal__benefits__voucher">Voucher 09833691</p>
				</div>

				<!-- VOUCHER CONFIRMADO -->
				<div class="modal__benefits confirm" *ngIf="validatedVoucherModal || seeVoucher">
					<p class="modal__benefits__title">Cliente Programa Menu Bradesco</p>
					<p class="modal__benefits__text">
						Benefício: <br />
						<span class="modal__benefits__this"> Uma sobremesa </span>
					</p>
					<p class="modal__benefits__voucher">Voucher validado 09833691</p>
				</div>
				<button
					class="modal__button button"
					*ngIf="!nemModalValidation"
					(click)="confirmValidation()"
				>
					<!-- aparecerá apenas no PENDENTE -->
					Confirmar validação
				</button>
			</div>

			<!-- CONFIRMAR VALIDAÇÃO DO VOUCHER -->
			<div class="modal__confirm" *ngIf="nemModalValidation && !seeVoucher">
				<!-- ficará oculto enquanto o modal__infos estiver aberto - linha 53 -->
				<img src="./../../../assets/images/imagem-de-confirmacao.png" />
				<p class="modal__question">
					Voucher de
					<span class="question__name">Vanessa Otani</span>
					validado?
				</p>
				<div class="modal__buttons">
					<button class="modal__button button__light" (click)="voucherValidate()">Sim</button>
					<button class="modal__button button__light" (click)="voucherNoValidate()">
						Desistir
					</button>
				</div>
			</div>
		</div>
	</app-modal>

	<!-- MODAL ACTIONS -->
	<app-modal slug="001">
		<div class="modal__content" content>
			<div class="modal__infos">
				<p class="modal__text">Rafael Caride</p>
				<p class="modal__people">
					2 pessoas -
					<span class="modal__people__waitingTime"> 03:45 minutos </span>
					na espera
				</p>
				<div class="modal__table">
					<label for="" class="modal__label">
						<p class="modal__label__text">Comanda</p>
						<i class="modal__icon tagmeicon tagmeicon-mesa"></i>
						<input type="text" class="modal__input" placeholder="#1564" />
					</label>
					<label for="" class="modal__label">
						<p class="modal__label__text">Nº Mesa</p>
						<i class="modal__icon tagmeicon tagmeicon-mesa-mini"></i>
						<input type="text" class="modal__input" placeholder="12" />
					</label>
					<button class="modal__button button__light">Salvar</button>
				</div>
				<div class="modal__benefits voucher">
					<p class="modal__benefits__title">Cliente Programa Menu Bradesco</p>
					<p class="modal__benefits__text">
						Benefício: <br />
						<span class="modal__benefits__this"> Uma sobremesa </span>
					</p>
					<p class="modal__benefits__voucher">Voucher 09833691</p>
					<p class="modal__benefits__notice">Voucher validado automaticamente ao sentar cliente</p>
				</div>
				<div class="modal__actions">
					<p class="modal__actions__text">Ações</p>
					<div class="modal__buttons">
						<button class="modal__button button__del--inverse">Excluir</button>
						<button class="modal__button button__confirm--inverse">Chamar</button>
						<button class="modal__button button__blue">Sentar</button>
					</div>
				</div>
			</div>
		</div>
	</app-modal>

	<div class="page">
		<app-header-master></app-header-master>

		<div class="page__header">
			<div class="page__wrapper wrapper">
				<div class="reservation__header">
					<div class="reservation__header__name" (click)="openModalVenue()">
						<p class="reservation__header__name__title">{{ nameVenues }}</p>
						<i class="reservation__header__name__icon tagmeicon tagmeicon-seta"></i>
					</div>
					<i
						class="reservation__header__icon tagmeicon tagmeicon-options-dots"
						(click)="openModalOptions()"
					></i>
				</div>
			</div>
		</div>
		<div class="page__body">
			<div class="page__wrapper wrapper">
				<div class="reservation__body">
					<!-- DATE -->
					<div class="reservation__body__date">
						<p class="reservation__body__date__text">Qui, 22/03/2018 • 21:48:43</p>
					</div>

					<!-- MENU -->
					<ul class="reservation__body__menu">
						<li class="reservation__body__menu__item" *ngFor="let item of menus">
							<i class="reservation__body__menu__item__icon {{ item.icon }}"></i>
							<p class="reservation__body__menu__item__text">
								{{ item.title }}
							</p>
						</li>
					</ul>

					<!-- MENU SELECTED -->
					<div class="reservation__body__menuContent">
						<div class="reservation__body__menuContent__title">
							<i
								class="reservation__body__menuContent__title__icon tagmeicon tagmeicon-mesa"
							></i>
							Mesas
						</div>
						<div class="reservation__body__menuContent__desc">
							Ocupação geral: 58/96 lugares<br />
							Tolerância de atraso: 5 minutos
						</div>
						<ul class="reservation__body__menuContent__subtitle">
							<li
								class="reservation__body__menuContent__item"
								*ngFor="let item of menuContentSubtitle"
							>
								<span class="reservation__body__menuContent__caracter {{ item.span }}"></span>
								<p class="reservation__body__menuContent__text">{{ item.title }}</p>
							</li>
						</ul>

						<!-- MESAS PARA ALMOÇO OU JANTAR -->
						<div class="tab">
							<label *ngFor="let item of tabs" class="tab__label">
								<input
									type="radio"
									id="{{ item.id }}"
									class="tab__input"
									name="tables"
									(click)="clickTabBox(item.id)"
								/>
								<div class="tab__box {{ item.selected }}">
									<p class="tab__text">
										{{ item.title }}
									</p>
								</div>
							</label>
						</div>

						<!-- CADA CLIENTE DETALHADAMENTE -->
						<div class="tabContent">
							<div class="tabContent__left">
								<div class="tabContent__client">
									<p class="tabContent__client__name">Vanessa Otani</p>
									<div class="tabContent__client__checks"></div>
								</div>
								<div class="tabContent__people">
									<p class="tabContent__people__infos">
										2
										<span class="tabContent__light"> Pessoas </span>
									</p>
									<p class="tabContent__people__infos">
										21:30
										<span class="tabContent__light"> confirm. </span>
									</p>
								</div>
							</div>
							<div class="tabContent__right">
								<div class="tabContent__enviroment">
									<p class="tabContent__enviroment__text">
										Ambiente
										<span class="tabContent__strong"> Salão Nobre (mesa 16) </span>
									</p>
									<p class="tabContent__enviroment__text">
										Origem
										<span class="tabContent__strong"> Menu Bradesco </span>
									</p>
								</div>
								<div class="tabContent__benefits pending" *ngIf="!voucherValidated">
									<!-- PENDENTE aparecerá até o momento em que for validado o voucher -->
									<p class="tabContent__benefits__text">
										Cliente com benefícios do Menu Bradesco
									</p>
									<button
										class="tabContent__button button__del"
										(click)="openModalValidation()"
									>
										Validar
										<!-- ao clicar neste abrirá o modal validação -->
									</button>
								</div>
								<div class="tabContent__benefits confirm" *ngIf="voucherValidated">
									<!-- CONFIRMADO ficará no lugar do PENDENTE -->
									<p class="tabContent__benefits__text">Voucher Bradesco Validado</p>
									<button
										class="tabContent__button button__del"
										(click)="seeVoucherValidated()"
									>
										Ver mais
										<!-- ao clicar neste abrirá o modal validação confirmada -->
									</button>
									<p class="tabContent__benefits__tip">
										Dica:
										<span class="tabContent__benefits__tip__text">
											Sente mais o cliente.
										</span>
									</p>
								</div>
								<div class="tabContent__actions">
									<button
										class="tabContent__button {{ item.class }}"
										*ngFor="let item of tabContentButton"
									>
										{{ item.title }}
										<i class="tabContent__button__icon tagmeicon {{ item.icon }}"></i>
									</button>
									<!-- ao clicar em qualquer um desses botões, abrirá o modal ACTIONS -->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
