import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { map, Observable } from 'rxjs'

type BreadcrumbsRouter = {
	url: string
	title: string
}

@Component({
	selector: 'app-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
	breadcrumbs$!: Observable<BreadcrumbsRouter[]>
	constructor(private activatedRoute: ActivatedRoute) {}

	ngOnInit(): void {
		const venueId = this.activatedRoute.snapshot.paramMap.get('venueId')
		if (venueId) {
			this.breadcrumbs$ = this.activatedRoute.data.pipe(
				map(data => data['breadcrumbs']),
				map((breadcrumbs: BreadcrumbsRouter[]) =>
					breadcrumbs.map(item => ({
						...item,
						url: item.url.replace(':venueId', venueId),
					}))
				)
			)
		}
	}
}
