<div class="page">
	<div class="page__wrapper wrapper">
		<app-back-button></app-back-button>
	</div>

	<div class="page__body">
		<div class="page__wrapper wrapper">
			<div class="create">
				<!-- FORM -->
				<form class="create__form" [formGroup]="form" (ngSubmit)="handleSubmit()">
					<!-- VENUE ID -->
					<label class="create__label" for="venueId">
						<span>VenueId</span>
					</label>
					<div
						id="venueId"
						name="venueId"
						type="text"
						class="create__input create__input--disabled"
						placeholder="Ex.: Uma sobremesa"
					>
						{{ venueId }}
					</div>
					<!-- SWITCH -->
					<div class="boxSwitch">
						<div style="width: 250px">
							<label class="switch">
								<input
									type="checkbox"
									class="switch__input"
									name="enabled"
									formControlName="enabled"
								/>
								<div class="switch__round"></div>
								<p class="switch__txt">
									{{ form.value.enabled ? 'CheckIn-(ligado)' : 'CheckIn-(desligado)' }}
								</p>
							</label>
						</div>
						<div style="width: 250px">
							<label class="switch">
								<input
									type="checkbox"
									class="switch__input"
									name="confirmPayment"
									formControlName="confirmPayment"
								/>
								<div class="switch__round"></div>
								<p class="switch__txt">
									{{ form.value.confirmPayment ? 'Habilitar validação de checkin para
									pagamento-(ligado)' : 'Habilitar validação de checkin para
									pagamento--(desligado)' }}
								</p>
							</label>
						</div>
					</div>

					<label class="create__label" for="startTimeId">
						<span>Horário do início do Check-in</span>
					</label>
					<input
						id="startTimeId"
						formControlName="startTime"
						name="startTime"
						type="text"
						class="create__input"
						placeholder="Ex.: 08:00"
					/>
					<label class="create__label" for="endTimeId">
						<span>Horário do fim do Check-in</span>
					</label>
					<input
						id="endTimeId"
						formControlName="endTime"
						name="startTime"
						type="text"
						class="create__input"
						placeholder="Ex.: 23:00"
					/>
					<button type="submit" class="create__button button">Salvar</button>
					<!-- [ngClass]="buttonDisabled ? ' disabled' : ''" -->
				</form>
			</div>
		</div>
	</div>
</div>

<ch-modal [id]="modal.error" top="15%" [preventOutsideClickClose]="true">
	<app-modal-simple [message]="errorMessage"></app-modal-simple>
</ch-modal>

<ch-modal [id]="modal.success" top="15%" [preventOutsideClickClose]="true">
	<app-modal-simple message="Check-in salvo com sucesso!"></app-modal-simple>
</ch-modal>
