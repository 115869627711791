<div class="header-master">
	<div class="header-master__wrapper wrapper">
		<div class="header-master__left" (click)="goHome()">
			<i class="header-master__left__logo tagmeicon tagmeicon-tagme-horizontal"></i>
			<p class="header-master__left__text">admin master</p>
		</div>
		<div class="header-master__right" (click)="logout()">
			<i class="header-master__right__icon tagmeicon tagmeicon-exportar"></i>
		</div>
	</div>
</div>
