import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { catchError, forkJoin, interval, map, of, startWith, Subscription, switchMap } from 'rxjs'
import { Gift, Order } from 'src/app/interfaces/order.interface'
import { STATUS_ORDER } from 'src/app/interfaces/status.interface'
import { ModalService } from '../../components/modalChannels/modal.service'
import { Venue } from '../venue-management/venues.interface'
import { DateUtils } from 'src/app/services/date.util'
import { CheckInService } from './checkin.service'
import { environment } from 'src/environments/environment'

enum ModalIds {
	HISTORY = 'modalHistoryId',
	VALIDATED = 'modalValidatedId',
	REJECTED = 'modalRejectedId',
}

export type IDays = {
	firstDay: Date
	lastDay: Date
}

@Component({
	templateUrl: './checkin.page.html',
	styleUrls: ['./checkin.page.scss'],
})
export class CheckinPage implements OnInit, OnDestroy {
	env = environment
	modalsId = {
		modalHistory: ModalIds.HISTORY,
		modalValidated: ModalIds.VALIDATED,
		modalRejected: ModalIds.REJECTED,
	} as const

	date$ = this.checkinService.date$
	orders: Order | null = null
	gifts: Gift | null = null

	isHistory = false
	selectedOrder: string | null = null
	selectedGift: string | null = null

	updateOrders$ = this.checkinService.updateOrders$
	updateGift$ = this.checkinService.updateGift$
	private subs = new Subscription()

	everyThirtySeconds$ = interval(3000_000).pipe(startWith(0))

	venueId!: string | null

	venue$: Venue | null = null

	calendarId = 'calendar_payment_id' as const
	firstDay = new Date()
	lastDay = new Date()

	constructor(
		private modalService: ModalService,
		private checkinService: CheckInService,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.venueId = this.route.snapshot.paramMap.get('venueId')
		this.subs.add(this.updateOrderByDate())
		this.subs.add(this.updateOrderByEvent())
		this.subs.add(this.setIsHistory())
		this.venue$ = this.checkinService.getVenueById(this.venueId as string)
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	private fetchOrdersAndGift() {
		return this.date$.pipe(
			map(({ firstDate, lastDate }) => [
				DateUtils.formatDateCheckin(firstDate),
				DateUtils.formatDateCheckin(lastDate),
			]),
			switchMap(([formattedFirstDate, formattedLastDate]) =>
				forkJoin([
					this.checkinService
						.getOrders(this.venueId as string, formattedFirstDate, formattedLastDate, 'checkin')
						.pipe(catchError(() => of([]))),
					this.checkinService
						.getGifts(this.venueId as string, formattedFirstDate, formattedLastDate)
						.pipe(catchError(() => of([]))),
				])
			)
		)
	}

	private handleOrdersAndGifts(orders: Array<Order>, gifts: Array<Gift>) {
		this.orders = orders.map(order => {
			const isHistory = new Date(order.reservationDay).toDateString() !== new Date().toDateString()
			return {
				...order,
				isHistory,
			}
		})
		this.gifts = gifts
	}

	private updateOrderByDate() {
		if (!this.venueId) {
			return
		}

		return this.everyThirtySeconds$.pipe(switchMap(() => this.fetchOrdersAndGift())).subscribe({
			next: ([orders, gifts]) => this.handleOrdersAndGifts(orders, gifts),
		})
	}

	private updateOrderByEvent() {
		return this.updateOrders$.pipe(switchMap(() => this.fetchOrdersAndGift())).subscribe({
			next: ([orders, gifts]) => this.handleOrdersAndGifts(orders, gifts),
		})
	}

	private setIsHistory() {
		return this.date$
			.pipe(map(date => new Date().toDateString() !== date.lastDate.toDateString()))
			.subscribe(value => (this.isHistory = value))
	}

	get dateTextOrder$() {
		return this.date$.pipe(
			map(date => {
				if (
					new Date().toDateString() === date.firstDate.toDateString() &&
					new Date().toDateString() === date.lastDate.toDateString()
				) {
					return 'Check-In Hoje'
				}
				return `Check-In entre: ${DateUtils.humanReadableDate(
					date.firstDate
				)} e ${DateUtils.humanReadableDate(date.lastDate)}`
			})
		)
	}

	get dateTextGift$() {
		return this.date$.pipe(
			map(date => {
				if (
					new Date().toDateString() === date.firstDate.toDateString() &&
					new Date().toDateString() === date.lastDate.toDateString()
				) {
					return 'Gifts Hoje'
				}
				return `Gifts entre: ${DateUtils.humanReadableDate(
					date.firstDate
				)} e ${DateUtils.humanReadableDate(date.lastDate)}`
			})
		)
	}

	showCalendar() {
		this.modalService.open(this.modalsId.modalHistory)
	}

	handleYes(type: STATUS_ORDER) {
		if (type === 'VALIDATED') {
			this.modalService.close(this.modalsId.modalValidated)
			this.checkinService.validate(this.selectedOrder as string).subscribe({
				next: () => {
					this.checkinService.updateOrder()
				},
				error: () => {},
			})
		} else if (type === 'CHK_CANCEL') {
			this.modalService.close(this.modalsId.modalRejected)
			this.checkinService.reject(this.selectedOrder as string).subscribe({
				next: res => {
					this.checkinService.updateOrder()
					// console.log(res)
				},
			})
		}
	}

	closeModal(modalId: ModalIds) {
		this.modalService.close(modalId)
	}

	selectDay(day: Date, type: 'first' | 'last') {
		if (type === 'first') {
			this.firstDay = day
		} else if (type === 'last') {
			this.lastDay = day

			this.checkinService.setDate(this.firstDay, this.lastDay)
			this.modalService.close(this.modalsId.modalHistory)
		}
	}

	handleButton(type: STATUS_ORDER, orderId: string) {
		this.selectedOrder = orderId
		if (type === 'VALIDATED') {
			this.modalService.open(this.modalsId.modalValidated)
		} else if (type === 'CHK_CANCEL') {
			this.modalService.open(this.modalsId.modalRejected)
		}
	}
}
