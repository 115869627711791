import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Option, ReportsService } from '../report-menu-bradesco/reports.service'
import { FormBuilder, FormControl, Validators } from '@angular/forms'
import { DateUtils, formatDateToDDMMYYYY } from 'src/app/services/date.util'
import { FileSaverService } from 'ngx-filesaver'
import { PartnersId } from 'src/shared/util'
import { HttpStatus } from 'src/app/services/get-async.util'
import { calculateDateLimit, formatMonthYear, calculateEndDate } from 'src/app/services/date.util'
import { formatUtil } from 'src/app/services/format.util'
import { ModalService } from 'src/app/components/modalChannels/modal.service'

@Component({
	selector: 'app-report-export',
	templateUrl: './report-export.component.html',
	styleUrls: ['./report-export.component.scss'],
})
export class ReportExportBradescoComponent implements OnInit {
	public idSector = 0
	public filterMonth = false
	public datePeriod = true
	public searchVoucher = false
	public filterCardAndRest = false
	public searchTab = true
	public waitlist = []
	public options = this.reportsService.options
	public selectedOption: Option = this.options[0]
	public status: HttpStatus = 'idle'
	public filter = [{ title: 'Mensal' }, { title: 'Semanal' }, { title: 'Diário' }]
	public tabRestAndCard = [
		{ title: 'Ações restaurante', id: 'restaurant' },
		{ title: 'Reserva cartão', id: 'card' },
	]
	public periods = [
		{ title: 'Período inicial', icon: 'tagmeicon tagmeicon-calendario-24', function: 'firstPeriod' },
		{ title: 'Período final', icon: 'tagmeicon tagmeicon-calendario-24', function: 'endPeriod' },
	]
	public report: any = []
	public monthYear = ''
	public dateLimit!: Date
	public endDate = ''
	public emailSendResult: any
	public isSendingEmail = false
	public sendEmailButton!: boolean

	modal = {
		retry: 'modalRetryId',
		success: 'modalSuccessId',
	}
	form = this.fb.nonNullable.group({
		startDate: [
			DateUtils.getPreviousMonthDates().startDate.toISOString().substring(0, 10),
			[Validators.required],
		],
		endDate: [
			DateUtils.getPreviousMonthDates().endDate.toISOString().substring(0, 10),
			[Validators.required],
		],
		filter: [],
	})

	selectVoucherBBIStatus = this.fb.nonNullable.control('active')
	constructor(
		private activatedRoute: ActivatedRoute,
		private reportsService: ReportsService,
		private fb: FormBuilder,
		private fileSaverService: FileSaverService,
		public modalService: ModalService
	) {}

	ngOnInit(): void {
		const slug = this.activatedRoute.snapshot.paramMap.get('slug')
		this.selectedOption = this.options.find(option => option.slug === slug) ?? this.options[0]
		this.sendEmailButton =
			this.selectedOption.slug === 'amenidades-restaurante' ||
			this.selectedOption.slug === 'celebrations-restaurante'
	}

	handleDownloadCsv() {
		this.status = 'pending'
		this.reportsService
			.chooseReportToCall(
				this.selectedOption.slug,
				{
					startDate: this.form.controls.startDate.value,
					endDate: DateUtils.addDay(this.form.controls.endDate.value, 1),
					partnerId: PartnersId.BRADESCO,
					...(this.selectedOption.slug === 'vouchers-bbi' && {
						status: this.selectVoucherBBIStatus.value,
					}),
				},
				'csv'
			)
			?.subscribe({
				next: (res: any) => {
					this.status = 'resolved'
					this.fileSaverService.save(res, `${this.selectedOption.slug}.csv`)
				},
				error: () => {
					this.status = 'rejected'
				},
			})
	}

	handlePreviewJson() {
		this.assignEndDate()
		this.status = 'pending'
		this.reportsService
			.chooseReportToCall(
				this.selectedOption.slug,
				{
					startDate: this.form.controls.startDate.value,
					endDate: DateUtils.addDay(this.form.controls.endDate.value, 1),
					partnerId: PartnersId.BRADESCO,
					...(this.selectedOption.slug === 'vouchers-bbi' && {
						status: this.selectVoucherBBIStatus.value,
					}),
				},
				'json'
			)
			?.subscribe({
				next: res => {
					this.status = 'resolved'
					this.report = res
				},
				error: error => {
					this.status = 'rejected'
				},
			})
	}

	private assignEndDate() {
		const endDate = new Date(this.form.controls.endDate.value)
		this.dateLimit = calculateDateLimit(new Date())
		this.monthYear = formatMonthYear(endDate)
		this.endDate = calculateEndDate(new Date(), this.selectedOption.slug)
	}

	public searchVoucherTab() {
		this.searchTab = true
	}

	public searchLocalTab() {
		this.searchTab = false
	}

	public async getDataVocuher(data: any) {
		// console.log('b',data)
		// await this.reportExportService.postInfoReportExport(data).subscribe((res) => {
		// },
		// (error)=>{
		//   alert(error.error.message)
		// })
	}

	openModalEmail() {
		this.modalService.open(this.modal.success)
	}

	openRetryModal() {
		this.modalService.open(this.modal.retry)
	}

	closeModal() {
		this.modalService.close(this.modal.success)
		this.modalService.close(this.modal.retry)
	}

	sendEmails() {
		this.isSendingEmail = true
		if (this.selectedOption.slug === 'amenidades-restaurante') {
			this.sendEmailsAmenidades()
		} else if (this.selectedOption.slug === 'celebrations-restaurante') {
			this.sendEmailsCelebrations()
		}
	}

	sendEmailsAmenidades() {
		const emailData = {
			monthYear: this.monthYear,
			dateLimit: formatDateToDDMMYYYY(this.dateLimit),
			endDate: this.endDate,
			venuesToSend: this.report.map(venue => {
				let mesaVipException = 0
				let mesaVip = 0
				let dessert = 0

				venue.benefits.forEach(benefit => {
					if (benefit.type === 'mesa_vip') {
						if (benefit.value === 'Entrada') {
							mesaVipException += benefit.qtd
						} else {
							mesaVip += benefit.qtd
						}
					} else if (benefit.type === 'dessert') {
						dessert += benefit.qtd
					}
				})

				return {
					venueId: venue.venueId,
					venueName: venue.venueName,
					benefits: {
						mesaVipException: mesaVipException,
						mesaVip: mesaVip,
						dessert: dessert,
					},
					totalValue: formatUtil.formatCurrency(venue.totalTransferValue),
				}
			}),
		}

		this.reportsService.sendAmenidadesEmail(emailData).subscribe(response => {
			this.emailSendResult = response
			this.closeModal()
			this.isSendingEmail = false
		})
	}

	sendEmailsCelebrations() {
		const emailData = {
			monthYear: this.monthYear,
			dateLimit: formatDateToDDMMYYYY(this.dateLimit),
			endDate: this.endDate,
			venuesToSend: this.report.map(venue => {
				let celebrations = 0

				venue.benefits.forEach(benefit => {
					if (benefit.type === 'celebrations') {
						celebrations += benefit.qtd
					}
				})

				return {
					venueId: venue.venueId,
					venueName: venue.venueName,
					benefits: {
						celebrations: celebrations,
					},
					totalValue: formatUtil.formatCurrency(venue.totalTransferValue),
				}
			}),
		}

		this.reportsService.sendCelebrationsEmail(emailData).subscribe(response => {
			this.emailSendResult = response
			this.closeModal()
			this.isSendingEmail = false
		})
	}

	public shouldShowRetry(): boolean {
		return this.emailSendResult?.failures?.some(failure => failure.retry)
	}

	retryFailedEmails() {
		this.isSendingEmail = true
		const failuresToRetry = this.emailSendResult.failures.filter(failure => failure.retry)

		if (this.selectedOption.slug === 'amenidades-restaurante') {
			const venuesToSend = failuresToRetry.map(failure => {
				let mesaVipException = 0
				let mesaVip = 0
				let dessert = 0

				if (failure.benefits && Array.isArray(failure.benefits)) {
					failure.benefits.forEach(benefit => {
						if (benefit.type === 'mesa_vip') {
							if (benefit.value === 'Entrada') {
								mesaVipException += benefit.qtd
							} else {
								mesaVip += benefit.qtd
							}
						} else if (benefit.type === 'dessert') {
							dessert += benefit.qtd
						}
					})
				}

				return {
					venueId: failure.venueId,
					venueName: failure.venueName,
					benefits: {
						mesaVipException: mesaVipException,
						mesaVip: mesaVip,
						dessert: dessert,
					},
					totalValue: failure.totalTransferValue
						? formatUtil.formatCurrency(failure.totalTransferValue)
						: 'R$ 0,00',
				}
			})

			if (venuesToSend.length > 0) {
				const emailData = {
					monthYear: this.monthYear,
					dateLimit: formatDateToDDMMYYYY(this.dateLimit),
					endDate: this.endDate,
					venuesToSend: venuesToSend,
				}

				this.reportsService.sendAmenidadesEmail(emailData).subscribe(response => {
					this.emailSendResult = response
					this.closeModal()
				})
			}
		} else if (this.selectedOption.slug === 'celebrations-restaurante') {
			const venuesToSend = failuresToRetry.map(failure => {
				let celebrations = 0

				if (failure.benefits && Array.isArray(failure.benefits)) {
					failure.benefits.forEach(benefit => {
						if (benefit.type === 'celebrations') {
							celebrations += benefit.qtd
						}
					})
				}

				return {
					venueId: failure.venueId,
					venueName: failure.venueName,
					benefits: {
						celebrations: celebrations,
					},
					totalValue: failure.totalTransferValue
						? formatUtil.formatCurrency(failure.totalTransferValue)
						: 'R$ 0,00',
				}
			})

			if (venuesToSend.length > 0) {
				const emailData = {
					monthYear: this.monthYear,
					dateLimit: formatDateToDDMMYYYY(this.dateLimit),
					endDate: this.endDate,
					venuesToSend: venuesToSend,
				}

				this.reportsService.sendCelebrationsEmail(emailData).subscribe(response => {
					this.emailSendResult = response
					this.closeModal()
					this.isSendingEmail = false
				})
			}
		}
	}
}
