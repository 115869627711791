import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-payment-infos',
	templateUrl: './payment-infos.component.html',
	styleUrls: ['./payment-infos.component.scss'],
})
export class PaymentInfosComponent implements OnInit {
	@Input() partner!: 'bradesco' | 'itau' | 'santander' | 'latam'
	@Input() general: boolean = false
	constructor() {}

	ngOnInit(): void {}
}
