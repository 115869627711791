import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment as env } from 'src/environments/environment'
import { delay, of } from 'rxjs'

export type Option = {
	icon: string
	name: string
	slug: string
	preview?: boolean
	sendEmail?: boolean
}

export type Sector = {
	title: string
	options: Option[]
}
@Injectable({ providedIn: 'root' })
export class ReportsService {
	public sectors: Sector[] = [
		{
			title: 'Canais',
			options: [
				{
					icon: 'tagmeicon tagmeicon-cpf',
					name: 'Cadastros de cliente por período',
					slug: 'cadastros-de-cliente-por-periodo',
				},
				{
					icon: 'tagmeicon tagmeicon-sobremesas',
					name: 'Sobremesas visa',
					slug: 'sobremesas-visa',
				},
				{
					icon: 'tagmeicon tagmeicon-data',
					name: 'Qtd pessoas e reservas', // report qtd reservas e pessoas
					slug: 'qtd-pessoas-e-resevas',
				},
				{
					icon: 'tagmeicon tagmeicon-cartao-de-credito',
					name: 'Reserva por cartão',
					slug: 'cartoes-cadastrados',
				},

				{
					icon: 'tagmeicon tagmeicon-notes-24',
					name: 'Vouchers BBI',
					slug: 'vouchers-bbi',
					preview: true,
				},
			],
		},
		{
			title: 'Financeiro',
			options: [
				{
					icon: 'tagmeicon tagmeicon-presente',
					name: 'Reservas Celebration (Dados completos)',
					slug: 'reservas-celebration',
				},
				{
					icon: 'tagmeicon tagmeicon-cutlery',
					name: 'Uso de amenidades e celebrations (Restaurante)', // report pm benefits venue
					slug: 'amenidades-celebrations-restaurante',
					preview: true,
				},
				{
					icon: 'tagmeicon tagmeicon-cutlery',
					name: 'Uso de amenidades (Restaurante)', // report pm benefits venue
					slug: 'amenidades-restaurante',
					preview: true,
					sendEmail: true,
				},
				{
					icon: 'tagmeicon tagmeicon-cutlery',
					name: 'Uso de celebrations (Restaurante)', // report pm benefits venue
					slug: 'celebrations-restaurante',
					preview: true,
					sendEmail: true,
				},
				{
					icon: 'tagmeicon tagmeicon-relatorio-cog-8',
					name: 'Uso de amenidades  e celebrations (Banco)', // benefits report pm bank
					slug: 'amenidades-celebrations-banco',
				},
			],
		},
	]

	public options = this.sectors.flatMap(sector => sector.options)

	constructor(private http: HttpClient) {}

	chooseReportToCall(
		slug: string,
		query: { startDate: string; endDate: string; partnerId?: string; status?: string },
		format: 'csv' | 'json' = 'csv'
	) {
		const responseType = (format === 'csv' ? 'blob' : 'json') as any
		switch (slug) {
			case 'cadastros-de-cliente-por-periodo':
				return this.http.get(
					`${env.channelsAPI.baseURL}reports/signup?startDate=${query.startDate}&endDate=${query.endDate}&partnerId=${query.partnerId}&format=${format}`,
					{ responseType }
				)
			case 'cartoes-cadastrados':
				return this.http.get(
					`${env.channelsAPI.baseURL}reports/newCards?startDate=${query.startDate}&endDate=${query.endDate}&partnerId=${query.partnerId}&format=${format}`,
					{ responseType }
				)
			case 'amenidades-celebrations-banco':
				return this.http.get(
					`${env.channelsAPI.baseURL}reports/bradesco/benefitsBank?startDate=${query.startDate}&endDate=${query.endDate}&format=${format}`,
					{ responseType }
				)
			case 'amenidades-celebrations-restaurante':
				return this.http.get(
					`${env.channelsAPI.baseURL}reports/bradesco/benefitsAndCelebrationsVenue?startDate=${query.startDate}&endDate=${query.endDate}&format=${format}`,
					{ responseType }
				)
			case 'amenidades-restaurante':
				return this.http.get(
					`${env.channelsAPI.baseURL}reports/bradesco/benefitsVenue?startDate=${query.startDate}&endDate=${query.endDate}&format=${format}`,
					{ responseType }
				)
			case 'celebrations-restaurante':
				return this.http.get(
					`${env.channelsAPI.baseURL}reports/bradesco/celebrationsVenue?startDate=${query.startDate}&endDate=${query.endDate}&format=${format}`,
					{ responseType }
				)
			case 'qtd-pessoas-e-resevas':
				return this.http.get(
					`${env.channelsAPI.baseURL}reports/bradesco/qtdReservation?startDate=${query.startDate}&endDate=${query.endDate}&format=${format}`,
					{ responseType }
				)
			case 'sobremesas-visa':
				return this.http.get(
					`${env.channelsAPI.baseURL}reports/bradesco/dessert?startDate=${query.startDate}&endDate=${query.endDate}&format=${format}`,
					{ responseType }
				)

			case 'qtd-pessoas-e-resevas':
				return this.http.get(
					`${env.channelsAPI.baseURL}reports/bradesco/qtdReservation?startDate=${query.startDate}&endDate=${query.endDate}&format=${format}`,
					{ responseType }
				)

			case 'reservas-celebration':
				return this.http.get(
					`${env.channelsAPI.baseURL}reports/bradesco/celebrationsComplete?startDate=${query.startDate}&endDate=${query.endDate}&format=${format}`,
					{ responseType }
				)

			case 'vouchers-bbi':
				return this.http.get(
					`${env.channelsAPI.baseURL}reports/bradesco/vouchersBBI?startDate=${query.startDate}&endDate=${query.endDate}&format=${format}&status=${query.status}`,
					{ responseType }
				)
			default:
				console.warn('Nenhum slug encontrado')
				return
		}
	}

	sendAmenidadesEmail(body: {
		monthYear: string // Dezembro/2023,
		dateLimit: string // Até 7 dias após o envio o e-eamil
		endDate: string //  Bradesco Mesa Vip: Dia 20 do mês de envio (Se o dia 20 cair no fds ou feriado, anteciapar para o dia útil mais próximo.)
		venuesToSend: Array<{
			venueId: string
			venueName: string
			benefits: {
				mesaVipException: number
				mesaVip: number
				dessert: number
			}
			totalValue: number // R$ 100,00 (formatado)
		}>
	}) {
		return this.http.post(`${env.channelsAPI.baseURL}email/progmenu/send/benefits`, body)
		return of({
			success: [
				{
					venueId: '12b',
					venueName: 'Bistro',
					message: 'E-mail enviado para: teste1@mail.com, teste2@email.com',
				},
				{
					venueId: '13b',
					venueName: 'Jappa',
					message: 'E-mail enviado para: teste1@mail.com, teste2@email.com',
				},
			],
			failures: [
				{
					venueId: '14b',
					venueName: 'Jappa',
					message: 'Não tem e-mail cadastrado. Sendgrid deu erro.',
					retry: true,
				},
				{
					venueId: '15b',
					venueName: 'Jappa 2',
					message: 'Não tem e-mail cadastrado. Sendgrid deu erro.',
				},
			],
		}).pipe(delay(2000))
	}

	sendCelebrationsEmail(body: {
		monthYear: string // Dezembro/2023,
		dateLimit: string // Até 7 dias após o envio o e-eamil
		endDate: string //Bradesco Celebrations: Último dia útil do mês.
		venuesToSend: Array<{
			venueId: string
			venueName: string
			benefits: {
				celebrations: number
			}
			totalValue: number // R$ 100,00 (formatado)
		}>
	}) {
		return this.http.post(`${env.channelsAPI.baseURL}email/progmenu/send/celebrations`, body)
		return of({
			success: [
				{
					venueId: '12b',
					venueName: 'Bistro',
					message: 'E-mail enviado para: teste1@mail.com, teste2@email.com',
				},
				{
					venueId: '13b',
					venueName: 'Jappa',
					message: 'E-mail enviado para: teste1@mail.com, teste2@email.com',
				},
			],
			failures: [
				{
					venueId: '14b',
					venueName: 'Jappa',
					message: 'E-mail não enviado para:',
				},
				{
					venueId: '14c',
					venueName: 'Jappa teste',
					message: 'E-mail não enviado para:',
					retry: true,
				},
			],
		}).pipe(delay(2000))
	}
}
