import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
	selector: 'app-card-description',
	templateUrl: './card-description.component.html',
	styleUrls: ['./card-description.component.scss'],
})
export class CardDescriptionComponent implements OnInit {
	@Input() id: string = ''
	@Input() name: string = ''
	@Input() img: string = ''
	@Input() description: string = ''
	@Input() venueId: string | null = null
	@Input() icon = 'tagmeicon-presente'
	@Output() clickedIcon = new EventEmitter()

	constructor() {}

	ngOnInit(): void {}

	clickIcon() {
		this.clickedIcon.emit()
	}
}
