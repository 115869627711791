import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { Observable } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class ConciergeService {
	constructor(private http: HttpClient) {}
	private env = environment

	public postDataLogin(data: any): Observable<any> {
		return this.http.post(`${this.env.channelsAPI.baseURL}login/concierge`, data)
	}
}
