import { Component, Input, OnInit } from '@angular/core'

export type Template = 'progMenu' | 'tagme' | 'iupp' | 'default'

@Component({
	selector: 'app-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
	@Input() slug: string = ''
	@Input() nameCards: string = ''
	@Input() iconCards: string = ''
	@Input() idCards: string = ''
	@Input() nameCardBenefit: string = ''
	@Input() imgCardBenefit: string = ''
	@Input() idBenefit: string = ''
	@Input() idCategory: string = ''
	@Input() activeCardBenefit: string = ''
	@Input() nameCardCategory: string = ''
	@Input() imgCardCategory: string = ''
	@Input() activeCardCategory: string = ''
	@Input() isOption: boolean = false
	@Input() isLogout: boolean = false
	@Input() template: Template = 'default'

	constructor() {}

	ngOnInit(): void {}
}
