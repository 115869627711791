<!-- LOADING
    <div class="conciergeLoading">
    <div class="page">
        <div class="page__header">
            <div class="page__wrapper wrapper">
                <h2 class="conciergeLoading__logo">
                    <i class="conciergeLoading__logo__icon tagmeicon tagmeicon-logo-bradesco"></i>
                </h2>
            </div>
        </div>
        <div class="page__body">
            <div class="page__wrapper wrapper">
                <p class="conciergeLoading__text">
                    Aguarde um momento, <br />
                    estamos carregando <br />
                    o <span class="concierge__text__red">Programa Menu</span> em <br />
                    modo concierge.
                </p>
                <div class="conciergeLoading__loading">
                    <div class="conciergeLoading__loading__circle"></div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="concierge opened">
	<div class="page">
		<!-- LOGIN CONCIERGE -->
		<div class="page__body">
			<div class="page__wrapper wrapper">
				<section class="concierge__login">
					<form class="concierge__login__form" #getLogin="ngForm">
						<label class="concierge__login__form__label" *ngFor="let item of inputs">
							{{ item.title }}
							<i class="concierge__login__form__icon {{ item.icon }}"></i>
							<input
								ngModel
								type="{{ item.type }}"
								class="concierge__login__form__input"
								name="{{ item.name }}"
								(keyup)="getLoginData(getLogin.value)"
							/>
						</label>
						<button
							class="concierge__login__form__button"
							[ngClass]="buttonEnabled ? 'button enabled' : 'button__gray'"
							(click)="sendLoginData(getLogin.value)"
						>
							Continuar
						</button>
						<a href="" class="concierge__login__form__link">Esqueci minha senha</a>
					</form>
				</section>
			</div>
		</div>
	</div>
</div>
