<table class="benefitTable">
	<thead>
		<tr>
			<th>Venue Name</th>
			<th *ngFor="let benefitType of benefitTypes">{{ benefitType }}</th>
			<th>Total Transfer Unit Value</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let row of tableData">
			<td>{{ row['Venue Name'] }}</td>
			<td *ngFor="let benefitType of benefitTypes">{{ row[benefitType] }}</td>
			<td>{{ row['Total Transfer Unit Value'] | currency : 'BRL' : 'symbol' : '1.2-2' }}</td>
		</tr>
	</tbody>
</table>
