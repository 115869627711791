import { Component, EventEmitter, Input, Output } from '@angular/core'
import { HttpStatus } from 'src/app/services/get-async.util'

@Component({
	selector: 'app-payment-refund-modal',
	templateUrl: './payment-refund.modal.component.html',
	styleUrls: ['./payment-refund-modal.component.scss'],
})
export class PaymentRefundModalComponent {
	@Input() status!: HttpStatus
	@Output() yesClicked = new EventEmitter<void>()
	@Output() goBackClicked = new EventEmitter<void>()

	yes() {
		this.yesClicked.emit()
	}

	goBack() {
		this.goBackClicked.emit()
	}
}
