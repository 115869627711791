export class Voucher {
	errors: Array<{ field: string; message: string }> = []
	name: string
	email: string
	phone: string
	venueId: string
	venueName: string
	price: number
	quantity: number
	bccEmails: string[]
	type: string
	constructor(
		name: string,
		email: string,
		phone: string,
		venueId: string,
		venueName: string,
		price: string,
		quantity: number,
		bccEmails: string,
		type: string
	) {
		if (!name?.length) this.errors.push({ field: 'NOME', message: 'Nome não pode ser vazio.' })
		if (!email?.length) this.errors.push({ field: 'EMAIL', message: 'Email não pode ser vazio.' })
		if (!phone?.length) this.errors.push({ field: 'TELEFONE', message: 'Nome não pode ser vazio.' })
		if (!venueId?.length)
			this.errors.push({ field: 'ID_RESTAURANTE', message: 'ID_RESTAURANTE não pode ser vazio.' })
		if (!price.toString()?.length)
			this.errors.push({ field: 'VALOR', message: 'VALOR não pode ser vazio.' })
		if (!quantity?.toString()?.length)
			this.errors.push({ field: 'QUANTIDADE', message: 'QUANTIDADE não pode ser vazio.' })
		if (!bccEmails?.length)
			this.errors.push({ field: 'CCO_EMAILS', message: 'CCO_EMAILS não pode ser vazio.' })
		if (!type?.length) this.errors.push({ field: 'TIPO', message: 'TIPO não pode ser vazio.' })
		if (!['ENCANTAR', 'RETRATAR'].includes(type))
			this.errors.push({
				field: 'TIPO',
				message: 'TIPO não pode ser diferente de ENCANTAR ou RETRATAR.',
			})
		this.name = name
		if (!this.isEmail(email)) {
			this.errors.push({ field: 'email', message: 'E-mail não é válido.' })
			this.email = ''
		}
		this.email = email
		;(this.phone = phone.replace(/\D/g, '').trim()), (this.venueId = venueId)
		this.venueName = venueName
		this.price = parseFloat(price.replace(',', '.'))
		this.quantity = Number(quantity)
		if (Number.isNaN(this.quantity)) {
			this.errors.push({ field: 'QUANTIDADE', message: 'QUANTIDADE não pode ser NaN.' })
		}
		this.bccEmails = bccEmails?.split(',').map(email => email.trim())
		if (this.bccEmails.includes(this.email)) {
			this.errors.push({
				field: 'CCO_EMAILS',
				message: 'CCO_EMAILS não pode conter EMAIL. Precisam ser únicos',
			})
		}
		this.type = type
	}
	private isEmail(email: string) {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		return regex.test(email)
	}
}
