import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'

@Component({
	selector: 'app-concierge001',
	templateUrl: './concierge001.component.html',
	styleUrls: ['./concierge001.component.scss'],
})
export class Concierge001Component implements OnInit {
	@Input() userName: string = ''
	@Input() userTel: string = ''
	@Input() userEmail: string = ''
	@Input() userPass: string = ''
	@Input() userId: any = ''

	constructor(private router: Router) {}

	ngOnInit(): void {}

	public editUser(idUser: any) {
		this.router.navigate(['create-concierge-admin/' + idUser])
	}
}
