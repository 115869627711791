import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'
@Injectable({
	providedIn: 'root',
})
export class ConciergeAdminService {
	constructor(private http: HttpClient) {}
	private env = environment

	public getConciergesData(conciergeAdmin: any) {
		return this.http.get(`${this.env.channelsAPI.baseURL}benefits/`)
	}
}
