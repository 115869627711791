<!-- Add checkin-validated or checkin-rejected class on wrapper div to change its style  -->
<div class="gift">
	<div class="gift__col">
		<div class="gift__row">
			<p class="gift__name">{{ gifts.customer?.name }} {{ gifts.customer?.lastName }}</p>
			<div class="gift__box">Validado</div>
		</div>
		<div class="gift__row">
			<p class="gift__text" style="margin-top: 8px">
				{{ gifts.redeemedAt | date : 'dd/MM/yyyy' : 'America/Sao_Paulo' }}
				<span class="gift__text" style="margin-left: 17px">{{
					gifts.redeemedAt | date : 'HH:mm' : 'America/Sao_Paulo'
				}}</span>
			</p>
		</div>
		<div class="gift__origin">
			<img class="gift__icon" src="assets/icons/internet-mini.svg" />
			Origem: {{ gifts.code }}
		</div>
	</div>
	<div class="gift__col">
		<div class="gift__benefits">
			<p class="gift__benefits__title">Código do voucher</p>
			<div class="gift__benefits__box">
				<p class="gift__benefits__box__text">{{ gifts.code }}</p>
			</div>
		</div>
	</div>
	<div class="gift__benefits" style="width: 100%; margin-right: 30px">
		<p class="gift__benefits__title">Benefício</p>
		<div class="gift__benefits__box">
			<p class="gift__benefits__box__textTwo">{{ gifts.gift.name }} - {{ gifts.gift.description }}</p>
		</div>
	</div>
	<div class="gift__col gift__col--push-right" style="height: 100%; display: grid">
		<div class="gift__col gift__col--btns">
			<button class="gift__btn gift__btn--validated" disabled>Validado</button>
		</div>
	</div>
</div>
