import { Component } from '@angular/core'
import { ModalService } from '../../modalChannels/modal.service'

@Component({
	selector: 'app-payment-filter-info',
	templateUrl: './payment-filter-info.component.html',
	styleUrls: ['./payment-filter-info.component.scss'],
})
export class PaymentFilterComponent {
	select!: 'firstDay' | 'lastDay'
	firstDay = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
	lastDay = new Date(new Date().getFullYear(), new Date().getMonth(), 0)
	modalMultiCalendarId = 'modalMultiCalendarId'

	constructor(private modalSerivce: ModalService) {}

	openCalendar() {
		this.modalSerivce.open(this.modalMultiCalendarId)
	}

	setFirstDay(firstDay: Date) {
		this.firstDay = firstDay
	}
	setLastDay(lastDay: Date) {
		this.lastDay = lastDay
	}
}
