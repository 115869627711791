<table class="benefitTable">
	<thead>
		<tr>
			<th *ngFor="let header of headers">
				{{ header }}

				<span class="info" *ngIf="getHeaderDescription(header)"
					>🛈
					<span class="tooltiptext">{{ getHeaderDescription(header) }}</span>
				</span>
			</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let item of info">
			<td *ngFor="let header of headers">{{ item[header] }}</td>
		</tr>
	</tbody>
</table>
