<div class="page">
	<div class="page__wrapper wrapper">
		<app-back-button></app-back-button>
	</div>

	<div class="page__body">
		<div class="page__wrapper wrapper">
			<div class="edit">
				<!-- FORM -->
				<div class="edit__form">
					<!-- VENUE ID -->
					<label class="edit__label" for="venueId">
						<span>VenueId</span>
					</label>
					<div
						id="venueId"
						name="venueId"
						type="text"
						class="edit__input edit__input--disabled"
						placeholder="Ex.: Uma sobremesa"
					>
						{{ venueId }}
					</div>

					<div *ngFor="let sector of sectors">
						<div>
							<strong style="text-transform: uppercase">{{ sector }}</strong>
							<div *ngIf="emails[sector]">
								<div style="padding: 5px 0">
									<label class="switch">
										<input
											type="checkbox"
											class="switch__input"
											[(ngModel)]="emails[sector].enabled"
										/>
										<div class="switch__round"></div>
										<p class="switch__txt" *ngIf="emails[sector].enabled ">Ativado</p>
										<p class="switch__txt" *ngIf="!(emails[sector].enabled) ">
											Desativado
										</p>
									</label>
								</div>

								<div *ngFor="let email of emails[sector].emails;let i = index">
									Email
									<ul class="edit__input">
										<li>{{ email }}</li>
										<i
											class="edit__icon tagmeicon tagmeicon-close"
											(click)="removeEmail(sector, i)"
										></i>
									</ul>
								</div>
							</div>
						</div>
						<form (ngSubmit)="addEmail(sector)">
							<label class="edit__label">Adicionar Email {{sector}}</label>
							<div style="display: flex; gap: 10px">
								<input
									[(ngModel)]="newEmail[sector]"
									class="edit__input"
									name="newEmail{{sector}}"
								/>
								<button type="submit" class="add__button button__light">Adicionar</button>
							</div>
							<br />
						</form>
					</div>

					<button class="edit__button button" (click)="save()">Salvar</button>
				</div>
			</div>
		</div>
	</div>
</div>

<ch-modal [id]="modal.error" top="15%" [preventOutsideClickClose]="true">
	<app-modal-simple [message]="errorMessage"></app-modal-simple>
</ch-modal>

<ch-modal [id]="modal.success" top="15%" [preventOutsideClickClose]="true">
	<app-modal-simple message="Emails salvo com sucesso!"></app-modal-simple>
</ch-modal>
