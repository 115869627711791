<h2 class="title">Período</h2>
<div class="buttons">
	<div class="buttons__calendar">
		<app-payment-button
			(buttonClicked)="openCalendar()"
			[firstDay]="firstDay | date : 'dd/MM/YYYY'"
			[lastDay]="lastDay | date : 'dd/MM/YYYY'"
		></app-payment-button>
	</div>
	<button class="buttons__details button__light" [routerLink]="['detalhes-dia']">Detalhes do dia</button>
</div>

<ch-modal [id]="modalMultiCalendarId" top="25%">
	<app-calendar-multi
		[id]="modalMultiCalendarId"
		[firstDay]="firstDay"
		[lastDay]="lastDay"
		(firstDaySelected)="setFirstDay($event)"
		(lastDaySelected)="setLastDay($event)"
	></app-calendar-multi>
</ch-modal>
