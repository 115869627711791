import { AfterViewInit, Component, OnInit } from '@angular/core'
import { Observable, catchError, of, tap } from 'rxjs'
import { PartnersId } from 'src/shared/util'
import { BenefitService } from './benefit.service'
import { Router } from '@angular/router'
import { VenuesManagementService } from '../venue-management/venues-management.service'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { ModalService } from 'src/app/components/modalChannels/modal.service'
import { HttpStatus } from 'src/app/services/get-async.util'

@Component({
	selector: 'app-benefit',
	templateUrl: './benefit.component.html',
	styleUrls: ['./benefit.component.scss'],
})
export class BenefitComponent implements OnInit, AfterViewInit {
	status: HttpStatus = 'idle'
	modal = {
		partner: 'modal-partner-id',
	} as const
	partnerForm!: UntypedFormGroup
	partnerTitle: string | null = null
	benefitCards$!: Observable<any[]>
	constructor(
		private benefitService: BenefitService,
		private router: Router,
		private venuesManagementService: VenuesManagementService,
		private fb: UntypedFormBuilder,
		private modalService: ModalService
	) {}
	ngOnInit(): void {
		this.partnerForm = this.fb.group({ partnerId: [null, Validators.required] })
		this.venuesManagementService.venueId = null
		this.venuesManagementService.benefitId = null
	}

	ngAfterViewInit(): void {
		this.modalService.open(this.modal.partner)
	}

	firtStep() {
		this.choosePartnerId()
		this.modalService.close(this.modal.partner)
		this.status = 'pending'
		this.benefitCards$ = this.benefitService.getAllBenefits(this.partnerForm.value.partnerId).pipe(
			tap(() => (this.status = 'resolved')),
			catchError(() => {
				this.status = 'rejected'
				return of([])
			})
		)
	}

	choosePartnerId() {
		const { partnerId } = this.partnerForm.value
		this.venuesManagementService.partnerId = partnerId
		if (partnerId === PartnersId.BRADESCO) {
			this.partnerTitle = 'Programa Menu Bradesco'
		}
		if (partnerId === PartnersId.ESFERA) {
			this.partnerTitle = 'Esfera Santander'
		}
	}

	openEditBenefit(id: string) {
		this.venuesManagementService.benefitId = id
		this.router.navigate(['/tagme/edit-benefit'])
	}

	openCreateBenefit() {
		this.venuesManagementService.benefitId = null
		this.router.navigate(['/tagme/create-benefit'])
	}
}
