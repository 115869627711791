import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment as env } from 'src/environments/environment'
import { UserService } from '../page/user/user.service'

@Injectable()
export class CustomHeadersInterceptor implements HttpInterceptor {
	constructor(private userService: UserService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const clientId = this.userService.getUser?._id
		return next.handle(request.clone({ setHeaders: { ...(clientId ? { clientId } : {}) } }))
	}
}
