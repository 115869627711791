<!--<div class="pDetails">
	<div class="pDetails__logo bradesco"></div>
	
	<ul class="pDetails__details">
		<li class="pDetails__item item--pos">
			<p class="pDetails__text">Total pago por clientes</p>
			<p class="pDetails__value">R$ 22.000,00</p>
		</li>
		<li class="pDetails__item item--neg">
			<p class="pDetails__text">Total desconto</p>
			<p class="pDetails__value">-R$ 3.000,00</p>
		</li>
		<li class="pDetails__item item--neg">
			<p class="pDetails__text">Total adquirência</p>
			<p class="pDetails__value">R$ 1.100,00</p>
		</li>
		<li class="pDetails__item item--pos">
			<p class="pDetails__text">Total pago por mesa vip</p>
			<p class="pDetails__value">R$ 5.600,00</p>
		</li>
		<li class="pDetails__item item--pos">
			<p class="pDetails__text">Total pago por sobremesas</p>
			<p class="pDetails__value">R$ 3.100,00</p>
		</li>
		<li class="pDetails__item item--pos">
			<p class="pDetails__text">Total geral a receber</p>
			<p class="pDetails__value">R$ 29.800,00</p>
		</li>
	</ul>
</div>-->
<div class="box">
	<div class="box__header">
		<button (click)="close()" class="box__close"><i class="fa fa-close"></i></button>
	</div>

	<div class="box__body">
		<div class="sum">
			<p class="sum__text">
				Somatório: <span class="sum__total">{{ calculateTotal() | currency : 'BRL' }}</span>
			</p>
		</div>
		<div *ngFor="let order of orders" style="border-bottom: 1px solid #eaeaea" class="box__body__inside">
			<!-- Último pagamento da ordem -->
			<ng-container *ngIf="getLastPayment(order) as lastPayment">
				<div class="box__row">
					<p class="box__inf">{{ order.client?.name }} {{ order.client?.lastName }}</p>

					<div class="box__box">Pago às {{ getPaidAt(order) }}</div>
				</div>

				<p class="box__inf">
					{{ order.reservationDay | date : 'dd/MM/YYYY' }}
				</p>

				<p class="box__inf green" style="margin-top: 8px">
					Valor pago: {{ lastPayment.orderTotalValue / 100 | currency : 'BRL' }}
				</p>
			</ng-container>
		</div>
	</div>
</div>
