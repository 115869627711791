import { Component, OnDestroy, OnInit } from '@angular/core'
import { VenuesManagementService } from '../venues-management.service'
import { VenuesConfigApi } from 'src/app/services/venues-config/venues-config.service'
import { ModalService } from 'src/app/components/modalChannels/modal.service'
import { Subject, takeUntil } from 'rxjs'

@Component({
	templateUrl: 'edit-email.page.html',
	styleUrls: ['edit-email.page.scss'],
})
export class EditEmailsPage implements OnInit, OnDestroy {
	venueId: string | null = this.venuesManagementService.venueId
	partnerId: string | null = this.venuesManagementService.partnerId
	errorMessage: string | null = null
	emails: { [key: string]: { enabled: boolean; emails: string[] } } = {}
	email: string[] = []
	newEmail: { [key: string]: string } = {}

	sectors = ['financeiro'] //sector

	modal = {
		error: 'modalErrorId',
		success: 'modalSuccessId',
	}

	private destroy$ = new Subject<void>()

	constructor(
		private venuesManagementService: VenuesManagementService,
		private venuesConfigApi: VenuesConfigApi,
		private modalService: ModalService
	) {}

	ngOnInit(): void {
		this.venuesManagementService.venueConfig$.pipe(takeUntil(this.destroy$)).subscribe(config => {
			this.sectors.forEach(setor => {
				if (!this.emails[setor]) {
					this.emails[setor] = { enabled: false, emails: [] }
				}
			})
			//sector
			if (config.emails) {
				if (config.emails.financial) {
					this.emails['financeiro'] = config.emails.financial
				}
				/*if (config.emails.payment) {
					this.emails['pagamento'] = config.emails.payment
				}*/
			}
		})
	}

	ngOnDestroy(): void {
		this.destroy$.next()
		this.destroy$.complete()
	}

	removeEmail(category: string, index: number): void {
		this.emails[category].emails.splice(index, 1)
		this.emails[category].emails = [...this.emails[category].emails]
	}

	addEmail(category: string): void {
		if (this.newEmail[category]) {
			if (!this.emails[category].emails) {
				this.emails[category].emails = []
			}
			this.emails[category].emails.push(this.newEmail[category])
			this.newEmail[category] = ''
		}
	}

	save() {
		if (this.venueId && this.partnerId) {
			let emailsToSend = {
				financial: this.emails['financeiro'],
				payment: this.emails['pagamento'],
			}

			this.venuesConfigApi
				.updateEmails({ venueId: this.venueId, partnerId: this.partnerId }, emailsToSend)
				.subscribe({
					next: () => {
						this.modalService.open(this.modal.success)
					},
					error: err => {
						this.errorMessage = err.error.message
						this.modalService.open(this.modal.error)
					},
				})
		}
	}
}
