import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core'

export type Item = {
	name: string
	value: string
}
@Component({
	selector: 'app-select-box [items]',
	templateUrl: './select-box.component.html',
	styleUrls: ['./select-box.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectBoxComponent implements OnInit {
	isOpen = false
	@Input() items!: Item[] | null
	@Output() itemWasSelected = new EventEmitter<Item>()
	@Input() selectedItem!: Item | null | undefined

	constructor() {}

	ngOnInit(): void {
		if (!this.selectedItem) {
			this.selectedItem = this.items ? this.items[0] : undefined
		}
	}

	itemSelected(item: Item) {
		this.selectedItem = this.items?.find(it => it.value === item.value)
		this.itemWasSelected.emit(item)
		this.isOpen = false
	}

	toggleOpen() {
		this.isOpen = !this.isOpen
	}
}
