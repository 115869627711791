import { Injectable } from '@angular/core'
import { BehaviorSubject, combineLatest, delay, map, of, tap } from 'rxjs'
import { Venue } from '../venues.interface'
import { VenuesApi } from '../../../services/venues.service'
import { BenefitsApi } from 'src/app/services/benefits.service'

@Injectable({ providedIn: 'root' })
export class VenuesManagementCacheService {
	public venuesCacheSub = new BehaviorSubject<Venue[]>([])
	constructor(private venuesApi: VenuesApi, private benefitApi: BenefitsApi) {}

	getVenueById(venue: Venue, partnerId: string) {
		const currentVenues = this.venuesCacheSub.getValue()
		const venueResult = currentVenues.find(v => v._id === venue._id)
		if (venueResult) return of(venueResult).pipe(delay(50))
		const obs$ = combineLatest([
			this.venuesApi.getVenueByIdWithoutPartners(venue._id),
			this.benefitApi.findAll(partnerId),
		]).pipe(map(([venue, benefits]) => ({ ...venue, benefits })))
		return obs$.pipe(tap(selectedVenue => this.venuesCacheSub.next([...currentVenues, selectedVenue])))
	}

	cleanCache() {
		this.venuesCacheSub.next([])
	}
}
