import { Component, Input, OnInit, TemplateRef } from '@angular/core'

@Component({
	selector: 'app-accordion001',
	templateUrl: './accordion001.component.html',
	styleUrls: ['./accordion001.component.scss'],
})
export class Accordion001Component implements OnInit {
	@Input() name: string = ''
	@Input() icon: string = ''
	@Input() class: string = ''
	@Input() tempAccordion: TemplateRef<any> | null = null

	public openedAccordion: boolean = false

	constructor() {}

	ngOnInit(): void {}

	public togglerAccordion() {
		this.openedAccordion = !this.openedAccordion
	}
}
