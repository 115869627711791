export enum AuthorizationRoles {
	User = 'User',
	Admin = 'Admin',
	VenueAdmin = 'VenueAdmin',
	Concierge = 'BradescoOperator',
}

export type UserResponse = {
	_id: string
	email: string
	username: string
	name: string
	lastName: string
	role: AuthorizationRoles
	venueIds?: Array<string>
	access_token: {
		token: string
	}
}

export type User = Omit<UserResponse, 'access_token'>

export interface ILoginDto {
	username: string
	password: string
}
