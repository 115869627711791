export const PartnersId = {
	BRADESCO: '595166383625c506d4868f28',
	ESFERA: '63600bcb4a49e96746e30f7d',
	LATAM: '643994cb6e7a2ca377e2400c',
	ITAU: '641c6e6ba05e256a0a2e0da8',
	BB: '666735bf20415739cd5aa3bd',
} as const

export function capitalizeFirstLetter(word: string) {
	return word.charAt(0).toUpperCase() + word.slice(1)
}
