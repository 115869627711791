import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ModalService } from '../modalChannels/modal.service'

@Component({
	selector: 'app-calendar-multi',
	templateUrl: './calendar-multi.component.html',
	styleUrls: ['./calendar-multi.component.scss'],
})
export class CalendarMultiComponent {
	today: Date = new Date()
	days: Date[] = []
	@Input() firstDay!: Date
	@Input() lastDay!: Date
	@Input() id!: string
	@Output() firstDaySelected = new EventEmitter<Date>()
	@Output() lastDaySelected = new EventEmitter<Date>()
	count!: number

	constructor(private modalService: ModalService) {}

	ngOnInit() {
		this.buildCalendar()
		this.count = 1
	}

	changeMonth(offsetMonth: number) {
		this.today.setMonth(this.today.getMonth() + offsetMonth)
		this.today = new Date(this.today.getTime())
		this.buildCalendar()
	}

	notMonth(day: Date) {
		return this.today.getMonth() !== day.getMonth()
	}

	greaterThanToday(day: Date) {
		const NOW = new Date()
		return day.getTime() > NOW.getTime()
	}

	selectDay(day: Date) {
		// can't select future
		if (this.greaterThanToday(day)) {
			return
		}

		// If the day selected was in a month before or after, change the month
		if (day.getMonth() < this.today.getMonth()) {
			this.changeMonth(-1)
		} else if (day.getMonth() > this.today.getMonth()) {
			this.changeMonth(+1)
		}

		// FirstDay selected
		if (this.count === 1) {
			const newLastDay = new Date(day.getTime())
			newLastDay.setDate(day.getDate() + 30)
			if (newLastDay.getTime() <= this.lastDay.getTime()) {
				this.lastDay = newLastDay
			}

			this.firstDay = day
			this.firstDaySelected.emit(day)
			this.count++
		}
		// Lastday selected
		else if (this.count === 2) {
			const NOW = new Date()
			const lessThanFirstDay = day.getTime() < this.firstDay.getTime()
			const thirtyDay = new Date(this.firstDay.getTime())
			thirtyDay.setDate(this.firstDay.getDate() + 30)
			const max30days = day.getTime() > thirtyDay.getTime()
			if (this.greaterThanToday(day) || lessThanFirstDay || max30days) {
				return
			}
			this.lastDay = day
			this.lastDaySelected.emit(day)
			this.modalService.close(this.id)
			this.count = 1
		}
	}

	isSelected(day: Date) {
		return (
			day.toDateString() === this.firstDay.toDateString() ||
			day.toDateString() === this.lastDay.toDateString()
		)
	}

	isBetween(day: Date) {
		return this.firstDay.getTime() < day.getTime() && day.getTime() < this.lastDay.getTime()
	}

	isFirstBetween(day: Date) {
		const oneDayAfterFirstDay = new Date()
		oneDayAfterFirstDay.setDate(this.firstDay.getDate() + 1)
		if (oneDayAfterFirstDay.toDateString() === this.lastDay.toDateString()) {
			return false
		}
		return oneDayAfterFirstDay.toDateString() === day.toDateString()
	}

	isLastBetween(day: Date) {
		const oneDayBeforeLastDay = new Date()
		oneDayBeforeLastDay.setDate(this.lastDay.getDate() - 1)
		if (oneDayBeforeLastDay.toDateString() === this.firstDay.toDateString()) {
			return false
		}
		return day.toDateString() === oneDayBeforeLastDay.toDateString()
	}

	buildCalendar() {
		const year = this.today.getFullYear()
		const month = this.today.getMonth()

		const firstDayOfWeek = 1 // segunda
		const lastDayOfWeek = 0 // domingo

		// Vai subtraindo -1 até chegarmos no primeiro dia da semana
		const dateBegin = new Date(year, month, 1)
		while (dateBegin.getDay() !== firstDayOfWeek) {
			dateBegin.setDate(dateBegin.getDate() - 1)
		}

		// Vai somando +1 até chegarmos no último dia da semana
		const dateEnd = new Date(year, month + 1, 0)
		while (dateEnd.getDay() !== lastDayOfWeek) {
			dateEnd.setDate(dateEnd.getDate() + 1)
		}

		this.days = []
		for (let data = new Date(dateBegin.getTime()); data <= dateEnd; data.setDate(data.getDate() + 1)) {
			this.days.push(new Date(data.getTime()))
		}
	}
}
