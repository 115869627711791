<div class="card003">
	<div class="card003__close">
		<i class="card003__close__icon tagmeicon tagmeicon-close"></i>
	</div>
	<div class="card003__box">
		<i class="card003__icon {{ iconCards }}"></i>
	</div>
	<div class="card003__box">
		<p class="card003__subtitle">
			{{ nameCards }}
		</p>
	</div>
	<!-- SWITCH -->
	<div class="card003__box">
		<label class="card003__switch">
			<p class="card003__switch__txt off">off</p>
			<input type="checkbox" class="card003__switch__input" name="enabled" />
			<div class="card003__switch__round"></div>
			<p class="card003__switch__txt on">on</p>
		</label>
	</div>
</div>

<!-- Opções de espera:
    - bar
    - hall
    - deck
    - dar uma volta

    Acomodação:
    - 2º andar
    - varanda
    - mesa redonda
    - próximo a janela

    Prioridades:
    - cadeirante
    - deficiente
    - idoso
    - gestante
    - lactante
    - autismo -->
