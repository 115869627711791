import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment as env } from 'src/environments/environment'
import { PartnersId } from 'src/shared/util'
import { map, of, tap } from 'rxjs'
import { ReservationProgMenuConverted, ReservationProgMenuResponse } from './reports-venue.interfaces'
import { DateUtils } from '../date.util'
import { formatUtil } from '../format.util'

@Injectable({ providedIn: 'root' })
export class ReportsVenueService {
	constructor(private http: HttpClient) {}

	getReportsProgMenuVenueOrders(
		query: { startDate: string; endDate: string; venueId: string },
		format: 'csv' | 'json' = 'json'
	) {
		const responseType = (format === 'csv' ? 'blob' : 'json') as any
		return this.http
			.get<ArrayBuffer | ReservationProgMenuResponse[]>(
				`${env.channelsAPI.baseURL}reports/venue/benefitsCelebrations?startDate=${query.startDate}&endDate=${query.endDate}&venueId=${query.venueId}&partnerId=${PartnersId.BRADESCO}&format=${format}`,
				{ responseType }
			)
			.pipe(
				map(data => {
					if (data instanceof ArrayBuffer) {
						return data
					}
					const totalTransferValue = data.reduce((sum, obj) => {
						if (obj.status === 'VALIDATED') {
							console.log(obj.totalTransferValue)
							return sum + obj.totalTransferValue
						}
						return sum
					}, 0)
					const addTotalTransferValue = [
						...data,
						{
							_id: '',
							reservationDate: '',
							name: '',
							email: '',
							phone: '',
							intent: '',
							status: '',
							validatedAt: '',
							benefits: '',
							code: '',
							totalTransferValue,
						},
					]
					return addTotalTransferValue.map(this.reportsProgMenuAdapter)
				})
			)
	}

	getReportsMenuPersonVenueOrders(
		query: { startDate: string; endDate: string; venueId: string },
		format: 'csv' | 'json' = 'csv'
	) {
		const responseType = (format === 'csv' ? 'blob' : 'json') as any
		/*return this.http.get(
			`${env.channelsAPI.baseURL}reports/signup?startDate=${query.startDate}&endDate=${query.endDate}&venueId=${query.venueId}&partnerId=${PartnersId.ITAU}&format=${format}`,
			{ responseType }
		)*/
		return of([
			{
				name: 'Afonso Silva',
				partySize: 2,
				reservationDay: '01/03/2024',
				reservationTime: '20:30',
				menuQuantity: 2,
				menuPrice: 25.0,
				wineQuantity: 1,
				winePrice: 60.0,
				status: 'Pendente',
				totalTransferValue: 130.0,
				validatedAt: '2024-03-01T15:21:30Z',
			},
			{
				name: 'Bob Smith',
				partySize: 6,
				reservationDay: '10/03/2024',
				reservationTime: '18:00',
				menuQuantity: 6,
				menuPrice: 28.0,
				wineQuantity: 3,
				winePrice: 50.0,
				status: 'Confirmada',
				totalTransferValue: 318.0,
				validatedAt: '2024-03-10T19:30:00Z',
			},
			{
				name: 'Catherine Lee',
				partySize: 3,
				reservationDay: '20/03/2024',
				reservationTime: '11:00',
				menuQuantity: 3,
				menuPrice: 32.0,
				wineQuantity: 2,
				winePrice: 55.0,
				status: 'Cancelada',
				totalTransferValue: 191.0,
				validatedAt: '2024-03-20T12:20:00Z',
			},
		])
	}

	private reportsProgMenuAdapter(data: ReservationProgMenuResponse): ReservationProgMenuConverted {
		const statusMap = {
			CHK_DONE: 'Confirmado',
			VALIDATED: 'Validado',
			CHK_CANCEL: 'Cancelado',
		}
		const intentMap = {
			reservation: 'Reserva',
			checkin: 'Check-In',
		}
		return {
			ID: data._id && data._id,
			Nome: data.name && data.name,
			'E-mail': data.email && data.email,
			'Data da Reserva': data.reservationDate && DateUtils.formatDataHour(data.reservationDate),
			Telefone: data.phone && formatUtil.formatPhone(data.phone),
			Origem: data.intent && intentMap[data.intent],
			Status: data.status && statusMap[data.status],
			Benefícios: data.benefits && data.benefits,
			...(data.cupom && { Cupom: data.cupom }),
			'Data da Validação': data.validatedAt && DateUtils.formatDataHour(data.validatedAt),
			Repasse:
				data.status && data.status === 'CHK_DONE'
					? 'Aguardando validação'
					: formatUtil.formatCurrency(data.status === 'CHK_CANCEL' ? 0 : data.totalTransferValue),
		}
	}
}

/*
mock getReportsProgMenuVenueOrders:
	// return of([
		// 	{
		// 		_id: '124568954',
		// 		reservationDate: '2024-01-01T00:00:00.000Z',
		// 		name: 'João Fernando',
		// 		email: 'joao123@gmail.com',
		// 		phone: '5593899445219',
		// 		intent: 'Reserva',
		// 		status: 'Validado',
		// 		validatedAt: '2024-01-01T00:00:00.000Z',
		// 		benefits: '',
		// 		transferValue: 0,
		// 	},
		// 	{
		// 		_id: '875682458',
		// 		reservationDate: '2024-01-15T00:00:00.000Z',
		// 		name: 'Etevaldo Silva',
		// 		email: 'et.valdo@hotmail.com',
		// 		phone: '5521985672121',
		// 		intent: 'Reserva',
		// 		status: 'Não validado',
		// 		validatedAt: '2024-01-15T00:00:00.000Z',
		// 		benefits: 'Mesa Vip 2 - 2 Taças de espumante, 1 água importada, 2 cafés',
		// 		transferValue: 43.0,
		// 	},
		// 	{
		// 		_id: '985624374',
		// 		reservationDate: '2024-01-20T00:00:00.000Z',
		// 		name: 'Joaquina Mendes',
		// 		email: 'jo.mendes@outlook.com.br',
		// 		phone: '5521985237458',
		// 		intent: 'Check-in',
		// 		status: 'Validado',
		// 		validatedAt: '2024-01-20T00:00:00.000Z',
		// 		benefits: 'Mesa Vip 1 - Entrada',
		// 		transferValue: 0,
		// 	},
		// 	{
		// 		_id: '568792345',
		// 		reservationDate: '2024-01-25T00:00:00.000Z',
		// 		name: 'Maria Alves',
		// 		email: 'maria1234@tagme.com.br',
		// 		phone: '5587985724569',
		// 		intent: 'Reserva',
		// 		status: 'Validado',
		// 		validatedAt: '2024-01-25T00:00:00.000Z',
		// 		benefits: 'Celebration',
		// 		transferValue: 250.0,
		// 	},
		// ])
*/
