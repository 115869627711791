<div class="partner" [ngClass]="{ geral: general }">
	<!-- incluir a class GERAL -->
	<div class="partner__wrapper">
		<div class="partner__client">
			<div class="partner__logo {{ partner }}"></div>
			<!-- incluir as classes ITAU, BRADESCO, SANTANDER ou LATAM -->
			<p class="partner__title">Geral</p>
		</div>
		<div class="partner__totais">
			<ul class="partner__list">
				<li class="partner__item item--pos">
					<p class="partner__text">Total pago</p>
					<p class="partner__sum">R$ 22.000,00</p>
				</li>
				<li class="partner__item item--neg">
					<p class="partner__text">Adquirência</p>
					<p class="partner__sum">R$ 1.100,00</p>
				</li>
				<li class="partner__item item--pos">
					<p class="partner__text">Amenindades</p>
					<p class="partner__sum">R$ 8.700,00</p>
				</li>
			</ul>
		</div>

		<div class="partner__right">
			<div class="partner__receive item--pos">
				<p class="partner__text">Total a receber</p>
				<p class="partner__sum">R$ 89.500,00</p>
			</div>

			<div class="partner__details">
				<button class="button__confirm">Mais detalhes</button>
			</div>
		</div>
	</div>
</div>
