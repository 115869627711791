import { Injectable } from '@angular/core'
import { environment as env } from 'src/environments/environment'
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Injectable({ providedIn: 'root' })
export class PartnersApi {
	private root = 'partners'
	private baseURL = `${env.channelsAPI.baseURL}${this.root}`
	constructor(private readonly http: HttpClient) {}

	findOne(partnerId: string) {
		return this.http.get<any[]>(`${this.baseURL}/${partnerId}`)
	}
}
