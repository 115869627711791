import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core'
import { STATUS_ORDER } from 'src/app/interfaces/status.interface'

@Component({
	selector: 'app-checkin-modal',
	templateUrl: './checkin-modal.component.html',
	styleUrls: ['./checkin-modal.component.scss'],
})
export class CheckinModalComponent implements OnInit {
	@Input() template: STATUS_ORDER = 'CHK_CANCEL'
	@Output() yesClicked = new EventEmitter<STATUS_ORDER>()
	@Output() goBackClicked = new EventEmitter()

	text: Record<STATUS_ORDER, any> = {
		CHK_CANCEL: 'Deseja recusar este Check-In?',
		VALIDATED: 'Deseja aceitar este Check-In?',
	}

	image: Record<STATUS_ORDER, any> = {
		CHK_CANCEL: 'assets/images/ilustra-excluir.svg',
		VALIDATED: 'assets/images/ilustra-voucher.svg',
	}

	constructor() {}

	ngOnInit(): void {}

	handleYes() {
		this.yesClicked.emit(this.template)
	}

	handleGoBack() {
		this.goBackClicked.emit()
	}

	@HostBinding('style')
	get addProps() {
		const config: Record<STATUS_ORDER, any> = {
			CHK_CANCEL: {
				'--color': 'var(--red)',
			},
			VALIDATED: {
				'--color': 'var(--green)',
			},
		}
		return config[this.template]
	}
}
