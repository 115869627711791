import { Injectable } from '@angular/core'
import { HttpService } from 'src/app/services/http.service'
import { Venue, Venues } from '../page/venue-management/venues.interface'

@Injectable({
	providedIn: 'root',
})
export class VenuesApi {
	constructor(private http: HttpService) {}

	getVenues(name: string) {
		return this.http.post<Venues[]>(`venues/admin/filter`, { name })
	}

	getVenueByIdPartners(venueId: string, partnerId: string) {
		return this.http.post<Venue>(`venues/${venueId}`, { partner: partnerId })
	}

	getVenueByIdWithoutPartners(venueId: string) {
		return this.http.get<Venue>(`venues/admin/${venueId}`)
	}

	importToChannels() {}
}
