<div class="page">
	<div class="page__wrapper wrapper">
		<app-back-button></app-back-button>
	</div>

	<div class="page__body">
		<div class="page__wrapper wrapper">
			<div class="edit">
				<!-- FORM -->
				<div class="edit__form">
					<!-- VENUE ID -->
					<label class="edit__label" for="venueId">
						<span>VenueId</span>
					</label>
					<div
						id="venueId"
						name="venueId"
						type="text"
						class="edit__input edit__input--disabled"
						placeholder="Ex.: Uma sobremesa"
					>
						{{ venueId }}
					</div>

					<ol>
						<ng-container *ngFor="let type of types; let index=index">
							<label class="edit__label">Tipo </label>
							<ul class="edit__input">
								{{type.type}}
								<i
									class="edit__icon tagmeicon tagmeicon-close"
									(click)="removeType(index)"
								></i>
							</ul>
						</ng-container>
					</ol>

					<label class="edit__label">Adicionar Tipo</label>
					<form (ngSubmit)="addType()">
						<div style="display: flex; gap: 10px">
							<input [(ngModel)]="newType" class="edit__input" name="newType" />
							<button type="submit" class="add__button button__light" (click)="addType()">
								Adicionar
							</button>
						</div>
						<br />
					</form>
					<button class="edit__button button" (click)="save()">Salvar</button>
					<!-- [ngClass]="buttonDisabled ? ' disabled' : ''" -->
				</div>
			</div>
		</div>
	</div>
</div>

<ch-modal [id]="modal.error" top="15%" [preventOutsideClickClose]="true">
	<app-modal-simple [message]="errorMessage"></app-modal-simple>
</ch-modal>

<ch-modal [id]="modal.success" top="15%" [preventOutsideClickClose]="true">
	<app-modal-simple message="Tipos salvo com sucesso!"></app-modal-simple>
</ch-modal>
