import { Component } from '@angular/core'
import { Venue } from '../../venue-management/venues.interface'
import { NonNullableFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { Subscription, catchError, debounceTime, distinctUntilChanged, filter, of, switchMap } from 'rxjs'
import { VenuesApi } from '../../../services/venues.service'
import { ModalService } from 'src/app/components/modalChannels/modal.service'
import { VenuesConfigApi } from 'src/app/services/venues-config/venues-config.service'
import { HttpStatus } from 'src/app/services/get-async.util'
import { VenuesLocalStorage, VenueLocalStorage } from 'src/app/services/venue-ls.service'
import { ActivatedRoute } from '@angular/router'

@Component({
	templateUrl: './sync-venue.page.html',
	styleUrls: ['sync-venue.page.scss'],
})
export class TagmeSyncVenuePage {
	public venueId: string | null = null
	public selectedVenue: Venue | null = null

	// Venues
	public venues: Venue[] | null = null
	public hasVenues: boolean = false

	public searchForm!: UntypedFormGroup
	public modal = {
		searchId: 'modal-search-venue',
		errorId: 'modal-error',
		areYouSureId: 'modal-are-you-are',
		successId: 'modal-success',
	} as const

	public actions = {
		image: { text: 'imagens', id: 'imagens' },
		users: { text: 'usuários', id: 'users' },
		venueNames: { text: 'endereço, nome, localização', id: 'venueNames' },
	} as const
	public selectedAction: typeof this.actions[keyof typeof this.actions]['id'] | null = null

	public errorMessage = ''
	public successMessage = ''
	public status: HttpStatus = 'idle'
	public areYouSureMessage: string = ''

	// // Observables and Subscriptions
	public lastVenues$ = this.venuesLocalStorage.getVenuesKey('admin')
	public subs = new Subscription()

	constructor(
		private venuesLocalStorage: VenuesLocalStorage,
		private venuesService: VenuesApi,
		public modalService: ModalService,
		private venueConfigApi: VenuesConfigApi,
		private fb: NonNullableFormBuilder,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.searchForm = this.fb.group({ name: ['', Validators.required] })
		const searchControl = this.searchForm.get('name')
		// Suggestions after typing
		this.subs.add(
			searchControl?.valueChanges
				.pipe(
					debounceTime(500),
					filter(name => name.length >= 3),
					distinctUntilChanged(),
					switchMap(name => this.venuesService.getVenues(name))
				)
				.subscribe(venues => {
					this.venues = venues
					this.hasVenues = Boolean(this.venues.length)
				})
		)

		// Erase suggestions after delete content
		this.subs.add(
			searchControl?.valueChanges.pipe(filter(name => name.length < 3)).subscribe(() => {
				this.venues = null
				this.hasVenues = false
			})
		)
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	ngAfterViewInit(): void {
		this.venueId = this.route.snapshot.paramMap.get('venueId')
		if (this.venueId?.length !== 24) {
			this.modalService.open(this.modal.searchId)
			return
		}
		this.getVenue(this.venueId)
	}

	openAreYouSureModal(action: typeof this.actions[keyof typeof this.actions]) {
		this.areYouSureMessage = `Tem certeza que deseja atualizar ${action.text}?`
		this.selectedAction = action.id
		this.modalService.open(this.modal.areYouSureId)
	}

	doAction() {
		if (!this.venueId) return
		if (this.selectedAction === 'users') {
			this.venueConfigApi.importAdminUsers(this.venueId).subscribe({
				next: (res: any) => {
					this.successMessage = res
					this.modalService.close(this.modal.areYouSureId)
					this.modalService.open(this.modal.successId)
				},
				error: error => {
					this.errorMessage = error?.error?.message
					this.modalService.close(this.modal.areYouSureId)
					this.modalService.open(this.modal.errorId)
				},
			})
		} else if (this.selectedAction === 'venueNames') {
			this.venueConfigApi.importVenueNames(this.venueId).subscribe({
				next: (res: any) => {
					this.successMessage = 'Importação com sucesso do endereço, nome e localização!'
					this.modalService.close(this.modal.areYouSureId)
					this.modalService.open(this.modal.successId)
				},
				error: error => {
					this.errorMessage = error?.error?.message
					this.modalService.close(this.modal.areYouSureId)
					this.modalService.open(this.modal.errorId)
				},
			})
		}
	}

	closeModalVenue() {
		if (!this.venueId) {
			alert('É necessário escolher uma venue.')
			return
		}
		this.modalService.close(this.modal.searchId)
	}

	clickVenues(venue: VenueLocalStorage) {
		this.getVenue(venue._id)
		this.searchForm.patchValue({ name: '' })
		this.closeModalVenue()
	}

	getVenue(venueId: string) {
		this.venueId = venueId
		this.status = 'pending'
		this.venuesService
			.getVenueByIdWithoutPartners(venueId)
			.pipe(catchError(this.handleError))
			.subscribe({
				next: venueResponse => {
					if (!venueResponse) {
						return
					}
					this.status = 'resolved'
					this.selectedVenue = venueResponse
				},
			})
	}

	private handleError = err => {
		this.status = 'rejected'
		this.errorMessage = err.error?.message
		this.modalService.open(this.modal.errorId)
		return of(null)
	}

	eraseHistory() {
		this.venuesLocalStorage.removeVenuesLocalStorage('admin')
	}
}
