import { Component, OnInit } from '@angular/core'

@Component({
	selector: 'app-tags001',
	templateUrl: './tags001.component.html',
	styleUrls: ['./tags001.component.scss'],
})
export class Tags001Component implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
