import { Component, OnInit } from '@angular/core'
import { UserService } from '../user/user.service'
import { VenuesManagementCacheService } from '../venue-management/venue-management/venue-management-cache.service'
import { VenuesManagementService } from '../venue-management/venues-management.service'
@Component({
	selector: 'app-projects',
	templateUrl: './projects.component.html',
	styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit {
	cardButtonsBradesco = [
		// { title: 'Gestão de Categorias', icon: 'tagmeicon tagmeicon-cartao-credito-24', id: 'category' },
		// { title: 'Reserva', icon:'tagmeicon tagmeicon-calendario-24', id:'reservation'},
		// { title: 'Reserva (admin)', icon:'tagmeicon tagmeicon-calendario-cog-24', id:'reservation-admin'},
		// { title: 'Lista de espera', icon:'tagmeicon tagmeicon-tempo-24', id:'waitlist'},
		// { title: 'Lista de espera (admin)', icon:'tagmeicon tagmeicon-tempo-cog-24', id:'waitlist-admin'},
		// { title: 'Passante Check in', icon:'tagmeicon tagmeicon-pin-24', id:'checkin'},
		// { title: 'Passante Check in (admin)', icon:'tagmeicon tagmeicon-pin-cog-24', id:'checkin-admin'},
		// { title: 'Concierge', icon: 'tagmeicon tagmeicon-smile-bom-24', id: 'concierge' },
		// {
		// 	title: 'Concierge (admin)',
		// 	icon: 'tagmeicon tagmeicon-smile-bom-cog-24',
		// 	id: 'bradesco/concierge-admin',
		// },
		{ title: 'Relatórios', icon: 'tagmeicon tagmeicon-notes-24', id: 'bradesco/report' },
		{
			title: 'Importação Vouchers BBI',
			icon: 'tagmeicon tagmeicon-notes-24',
			id: 'bradesco/vouchers-bbi',
		},
	]

	cardButtonsTagme = [
		// { title: 'Reserva', icon: 'tagmeicon tagmeicon-calendario-24', id: 'tagme/reservation' },
		// { title: 'Insere Esfera', icon: 'tagmeicon tagmeicon-estrela-vazia-24', id: 'tagme/insert-esfera' },
		// { title: 'Importar Venue', icon: 'tagmeicon tagmeicon-importar', id: 'tagme/import-venue' },
		{
			title: 'Venue (pagamento, checkin)',
			icon: 'tagmeicon tagmeicon-estrela-vazia-24',
			id: 'venue',
		},
		{
			title: 'Gestão de Venues (por parceiro)',
			icon: 'tagmeicon tagmeicon-estrela-vazia-24',
			id: 'tagme/venue-management',
		},
		{
			title: 'Gestão de Benefícios (global)',
			icon: 'tagmeicon tagmeicon-desconto-24',
			id: 'tagme/benefit',
		},

		{
			title: 'Sincronizar Venue (usuários, endereço)',
			icon: 'tagmeicon tagmeicon-estrela-vazia-24',
			id: 'tagme/venue/1',
		},
		// {
		// 	title: 'Criação de Voucher Único',
		// 	icon: 'tagmeicon tagmeicon-estrela-vazia-24',
		// 	id: 'tagme/single-voucher',
		// },
		// { title: '', icon: 'tagmeicon tagmeicon-estrela-vazia-24', id: 'venue' },
	]

	cardButtonsIupp = [
		{
			title: 'Importação de Vouchers',
			icon: 'tagmeicon tagmeicon-estrela-vazia-24',
			id: 'itau/vouchers',
		},
		{
			title: 'Criação de Voucher Único',
			icon: 'tagmeicon tagmeicon-estrela-vazia-24',
			id: 'itau/single-voucher',
		},
		{
			title: 'Relatórios',
			icon: 'tagmeicon tagmeicon-notes-24',
			id: 'itau/report',
		},
	]

	constructor(
		private userService: UserService,
		private venueManagementCache: VenuesManagementCacheService,
		private venueManagement: VenuesManagementService
	) {}

	ngOnInit(): void {
		this.venueManagementCache.cleanCache()
		this.venueManagement.reset()
	}

	get isAdminOrVenueAdmin() {
		return this.userService.isAdmin || this.userService.isVenueAdmin
	}

	get isAdminOrConcierge() {
		return this.userService.isAdmin || this.userService.isConcierge
	}

	get isAdmin() {
		return this.userService.isAdmin
	}
}
