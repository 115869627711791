<div class="page">
	<div class="page__wrapper wrapper">
		<app-back-button [page]="'concierge-admin'"></app-back-button>
	</div>
	<div class="page__body">
		<div class="page__wrapper wrapper">
			<div class="create">
				<p class="create__title">
					{{ titleHeader }}
				</p>

				<form class="create__form" #createConciergeAdminForm="ngForm">
					<label class="create__label"> Nome do concierge </label>
					<!-- vê as infos que vem do Back e altera aqui  \/ -->
					<input
						(keyup)="infosConciergeAdmin(createConciergeAdminForm.value)"
						[ngModel]="newConcierge ? dataEditConcierge.name : ''"
						name="name"
						type="text"
						class="create__input"
						value=""
						placeholder="Ex.: João Paulo Alves"
					/>

					<label class="create__label"> Telefone </label>
					<!-- vê as infos que vem do Back e altera aqui  \/ -->
					<input
						(keyup)="infosConciergeAdmin(createConciergeAdminForm.value)"
						[ngModel]="newConcierge ? dataEditConcierge.value : ''"
						name="tel"
						type="text"
						maxlength="13"
						value=""
						class="create__input"
						placeholder="Ex.: (21) 99315-7878"
					/>

					<label class="create__label"> Email </label>
					<!-- vê as infos que vem do Back e altera aqui  \/ -->
					<input
						(keyup)="infosConciergeAdmin(createConciergeAdminForm.value)"
						[ngModel]="newConcierge ? dataEditConcierge.description : ''"
						name="email"
						type="email"
						class="create__input"
						value=""
						placeholder="Ex.: joao@tagme.com.br"
					/>

					<label class="create__label"> Senha </label>
					<!-- vê as infos que vem do Back e altera aqui  \/ -->
					<input
						(keyup)="infosConciergeAdmin(createConciergeAdminForm.value)"
						[ngModel]="newConcierge ? dataEditConcierge.type : ''"
						name="password"
						type="password"
						class="create__input"
						value=""
						placeholder="Ex.: t@6m3"
					/>

					<label class="create__label"> Repetir senha </label>
					<!-- vê as infos que vem do Back e altera aqui  \/ -->
					<input
						(keyup)="infosConciergeAdmin(createConciergeAdminForm.value)"
						[ngModel]="newConcierge ? dataEditConcierge.type : ''"
						name="passwordAgain"
						type="password"
						class="create__input"
						value=""
						placeholder="Ex.: t@6m3"
					/>

					<div class="create__actions">
						<button
							type="submit"
							class="create__button button"
							[ngClass]="buttonDisabled ? 'disabled' : ''"
							[disabled]="buttonDisabled"
							(click)="save(createConciergeAdminForm.value)"
						>
							Salvar
						</button>
						<button *ngIf="newConcierge" class="create__button button__del" (click)="remove()">
							Excluir concierge
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
