<div class="page">
	<div class="page__body">
		<div class="page__wrapper wrapper">
			<app-order-box type="success" *ngIf="type === 'success'"></app-order-box>
			<app-order-box type="validated" *ngIf="type === 'validated'"></app-order-box>
			<app-order-box type="attention" *ngIf="type === 'attention'"></app-order-box>
			<app-order-box type="futureReservation" *ngIf="type === 'futureReservation'"></app-order-box>
			<app-order-box type="fail" *ngIf="type === 'fail'"></app-order-box>
		</div>
	</div>
</div>
