<div class="card-description">
	<div class="card-description__logo" (click)="clickIcon()">
		<i *ngIf="!img" class="card-description__icon tagmeicon {{ icon }}"></i>
		<img *ngIf="img" src="https://tagmepub.azureedge.net/pubimg/{{ img }}" />
	</div>
	<div class="card-description__content">
		<p class="card-description__venueId" *ngIf="venueId">venueId: {{ venueId }}</p>
		<p class="card-description__name">{{ name }}</p>
		<p class="card-description__local">{{ description }}</p>
	</div>
</div>
