import { Injectable } from '@angular/core'

@Injectable({ providedIn: 'root' })
export class DownloadCsvFromJson {
	downloadCSV(data: Record<string, any>[], filename: string) {
		let csvData = this.convertToCSV(data)
		let blob = new Blob([csvData], { type: 'text/csv' })
		let url = window.URL.createObjectURL(blob)
		let a = document.createElement('a')

		a.setAttribute('style', 'display:none;')
		a.href = url
		a.download = filename + '.csv'
		document.body.appendChild(a)
		a.click()
		window.URL.revokeObjectURL(url)
		a.remove()
	}

	private convertToCSV(data: Record<string, any>[]): string {
		const replacer = (key, value) => (value === null ? '' : value)
		const header = Object.keys(data[0])
		let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
		csv.unshift(header.join(','))
		return csv.join('\r\n')
	}
}
