import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core'
import { Order, OrderPayment } from 'src/app/interfaces/order.interface'
import { STATUS_PAYMENT } from 'src/app/interfaces/status.interface'
import { getTime } from 'src/app/services/date.util'

@Component({
	selector: 'app-payment-card',
	templateUrl: './payment-card.component.html',
	styleUrls: ['payment-card.component.scss'],
})
export class PaymentCardComponent {
	@Input() order!: OrderPayment
	template!: STATUS_PAYMENT | undefined
	selected = false

	@Output() refundClicked = new EventEmitter<{ orderId: string }>()
	@Output() selectionChanged = new EventEmitter<boolean>()
	@Output() printClicked = new EventEmitter<{ order: OrderPayment }>()
	@Output() gratuityClicked = new EventEmitter<OrderPayment>()

	ngOnInit(): void {
		this.template = this.order.paymentStatus
	}

	onSelectionChange() {
		this.selectionChanged.emit(this.selected)
	}

	clickRefund() {
		this.refundClicked.emit({ orderId: this.order._id })
	}

	/*clickPrint() {
		this.printClicked.emit({ order: this.order })
	}*/

	@HostBinding('style')
	get addProps() {
		if (!this.template) {
			return
		}

		if (this.template) {
			const config: Record<STATUS_PAYMENT, any> = {
				FAILED: { '--color': 'var(--red)', '--bg-color': 'var(--pink6)', '--opacity': '20%' },
				CANCELED: { '--color': 'var(--red)', '--bg-color': 'var(--pink6)', '--opacity': '20%' },
				DONE: { '--color': 'var(--green)', '--bg-color': 'var(--greenE)', '--opacity': '20%' },
				READY: '',
				NOT_DONE: '',
			}
			return config[this.template]
		}
	}
	get text() {
		if (!this.template || !this.order) {
			return ''
		}

		const paidAt = getTime(this.lastPayment?.paidAt)
		const text: Record<STATUS_PAYMENT, any> = {
			FAILED: 'Recusado',
			DONE: `Pago às ${paidAt}`,
			READY: 'Em aberto',
			CANCELED: 'Estornado',
			NOT_DONE: 'Não pagou',
		}
		return text[this.template]
	}

	get lastPayment(): any {
		const payment = this.order?.payment
		if (Array.isArray(payment)) {
			return payment[payment.length - 1]
		}
		return payment
	}

	get taxes() {
		return this.lastPayment?.taxes + this.lastPayment?.retentionValue
	}
	get partySize() {
		return this.order.partySize === 1 ? '1 pessoa' : `${this.order.partySize} pessoas`
	}

	clickSelection() {
		this.selected = !this.selected
		this.onSelectionChange()
	}
}
