import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject, Subject, tap } from 'rxjs'
import { PartnersApi } from 'src/app/services/partner.service'
import { VenuesConfigApi } from 'src/app/services/venues-config/venues-config.service'

@Injectable({ providedIn: 'root' })
export class VenuesManagementService {
	partnerId: string | null = null
	venueId: string | null = null
	benefitId: string | null = null

	public partner = new BehaviorSubject<any>(null)
	public venueConfig = new BehaviorSubject<any>(null)
	public venueConfig$ = this.venueConfig.asObservable()
	public partner$ = this.partner.asObservable()

	constructor(
		private router: Router,
		private venueConfigApi: VenuesConfigApi,
		private partnerApi: PartnersApi
	) {}

	/*getVenueConfig() {
		const venueConfig = this.venueConfig.value
		if (!venueConfig) return this.updateVenueConfig()
		return this.venueConfig$
	}*/

	updateVenueConfig() {
		return this.venueConfigApi
			.findOne(this.primaryKey)
			.pipe(tap(venueConfig => this.setVenueConfig(venueConfig)))
	}

	getPartner() {
		const partner = this.partner.value
		if (!partner)
			return this.partnerApi
				.findOne(this.primaryKey.partnerId)
				.pipe(tap(partner => this.setPartner(partner)))
		return this.partner$
	}

	setVenueConfig(venueConfig: any) {
		this.venueConfig.next(venueConfig)
	}

	setPartner(partner: any) {
		this.partner.next(partner)
	}

	get primaryKey() {
		if (!this.partnerId) throw new Error('partnerId null')
		if (!this.venueId) throw new Error('venueId null')
		return {
			partnerId: this.partnerId,
			venueId: this.venueId,
		}
	}

	ensurePrimaryKey() {
		if (!this.venueId || !this.partnerId) {
			this.router.navigate(['/'])
			return
		}
	}

	reset() {
		this.partnerId = null
		this.venueId = null
		this.benefitId = null
	}
}
