import { Component } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { EMPTY, Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'
import { DateUtils } from 'src/app/services/date.util'
import { VenuesCacheService } from '../../payment/venue-cache.service'
import { ReportsVenueService } from 'src/app/services/reports-venue-service/reports-venue.service'
import { formatUtil } from 'src/app/services/format.util'
import { ReservationMenuPersonResponse } from 'src/app/services/reports-venue-service/reports-venue.interfaces'

@Component({
	templateUrl: './report-orders.page.html',
	styleUrls: ['./report-orders.page.scss'],
})
export class VenueReportsOrdersMpPage {
	public venue$!: Observable<any>
	public venueId!: string
	selectedStartDate?: string | Date
	selectedEndDate?: string | Date
	reservations$!: Observable<ReservationMenuPersonResponse[]>
	reservationsConverted$!: Observable<{ reservationConverted: Date }[]>

	reservations: ReservationMenuPersonResponse[] = []

	form = this.fb.nonNullable.group({
		startDate: [
			DateUtils.getPreviousMonthDates().startDate.toISOString().substring(0, 10),
			[Validators.required],
		],
		endDate: [
			DateUtils.getPreviousMonthDates().endDate.toISOString().substring(0, 10),
			[Validators.required],
		],
		filter: [],
	})

	constructor(
		private activatedRoute: ActivatedRoute,
		private venuesCacheService: VenuesCacheService,
		private fb: FormBuilder,
		private reportsVenueService: ReportsVenueService
	) {
		this.venueId = this.activatedRoute.snapshot.paramMap.get('venueId') ?? ''
		this.venue$ = this.venuesCacheService.getVenueById(this.venueId)

		this.reservations$ = EMPTY
	}

	private reportsMenuPersonAdapter(data: ReservationMenuPersonResponse) {
		return {
			...data,
			validatedAt: DateUtils.formatDataHour(data.validatedAt),
			menuPrice: formatUtil.formatCurrency(data.menuPrice),
			winePrice: formatUtil.formatCurrency(data.winePrice),
			totalTransferValue: formatUtil.formatCurrency(data.totalTransferValue),
		}
	}

	filter() {
		const startDate = this.form.controls.startDate.value
		const endDate = DateUtils.addDay(this.form.controls.endDate.value, 1)

		this.reservations$ = this.reportsVenueService
			.getReportsMenuPersonVenueOrders({
				startDate,
				endDate,
				venueId: this.venueId,
			})
			.pipe(map(reservations => reservations.map(this.reportsMenuPersonAdapter)))
	}

	downloadCSV() {
		const startDate = new Date(this.form.value.startDate ?? '')
		const endDate = new Date(this.form.value.endDate ?? '')

		const adjustedStartDate = new Date(startDate)
		const adjustedEndDate = new Date(endDate)

		//FILTER
		const filteredReservations = this.reservations.filter(reservation => {
			const reservationDate = new Date(reservation.reservationDay.split('/').reverse().join('-'))
			return reservationDate >= adjustedStartDate && reservationDate <= adjustedEndDate
		})

		let csvData = this.convertToCSV(filteredReservations)
		let blob = new Blob([csvData], { type: 'text/csv' })
		let url = window.URL.createObjectURL(blob)
		let a = document.createElement('a')

		a.setAttribute('style', 'display:none;')
		a.href = url
		a.download = 'reservations-report.csv'
		document.body.appendChild(a)
		a.click()
		window.URL.revokeObjectURL(url)
		a.remove()
	}

	convertToCSV(reservations: ReservationMenuPersonResponse[]): string {
		const replacer = (key, value) => (value === null ? '' : value)
		const header = Object.keys(reservations[0])
		let csv = reservations.map(row =>
			header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(',')
		)
		csv.unshift(header.join(','))
		return csv.join('\r\n')
	}
}
