<!-- GESTÃO DE BENFÍCIOS -->
<div *ngIf="nameCardBenefit" class="card002" [ngClass]="activeCardBenefit ? 'active' : ''">
	<div class="card002__box">
		<img src="{{ imgCardBenefit }}" />
	</div>
	<div class="card002__box">
		<p class="card002__subtitle">
			{{ nameCardBenefit }}
		</p>
	</div>
</div>

<!-- GESTÃO DE CATEGORIAS -->
<div
	*ngIf="nameCardCategory"
	class="card002"
	[ngClass]="activeCardCategory ? 'active' : ''"
	(click)="editCategory(idCategory)"
>
	<div class="card002__box">
		<i class="card002__icon {{ imgCardCategory }}"></i>
	</div>
	<div class="card002__box">
		<p class="card002__subtitle">
			{{ nameCardCategory }}
		</p>
	</div>
</div>

<!-- servirá para benefícios e categorias,
quando for benefícios aparecerão os ícones,
já em categorias, as bandeiras dos cartões
e a legenda de acordo com cada um -->
