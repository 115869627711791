import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'absolutePath',
})
export class AbsolutePipe implements PipeTransform {
	private PUB_IMG = 'https://tagmepub.azureedge.net/pubimg/'

	transform(relativePath: string | null | undefined) {
		return `${this.PUB_IMG}${relativePath}`.trim()
	}
}
