import { Component, Input, OnChanges } from '@angular/core'

@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnChanges {
	@Input() benefitsReport: any[] = []
	tableData: any[] = []
	benefitTypes: string[] = []

	ngOnChanges(): void {
		if (this.benefitsReport) {
			this.processBenefitsData()
		}
	}

	private processBenefitsData(): void {
		const benefitTypesSet = new Set<string>()
		const dessertOrValue = (benefit: any) => (benefit.type === 'dessert' ? 'Sobremesa' : benefit.value)

		this.benefitsReport.forEach(venue => {
			venue.benefits.forEach(benefit => {
				benefitTypesSet.add(dessertOrValue(benefit))
			})
		})

		this.benefitTypes = Array.from(benefitTypesSet)

		this.tableData = this.benefitsReport.map(venue => {
			const rowData = {
				'Venue Name': venue.venueName,
				'Total Transfer Unit Value': 0,
			}

			this.benefitTypes.forEach(type => (rowData[type] = 0))

			venue.benefits.forEach(benefit => {
				rowData[dessertOrValue(benefit)] += benefit.qtd
				rowData['Total Transfer Unit Value'] += benefit.transferUnitValue * benefit.qtd
			})

			return rowData
		})
	}
}
