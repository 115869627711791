<div class="page">
	<div class="page__wrapper wrapper">
		<app-back-button [page]="'report'"></app-back-button>
	</div>
	<div class="page__body">
		<div class="page__wrapper wrapper">
			<div class="report-export" *ngIf="!searchVoucher">
				<div class="report-export__title">{{ selectedOption.name }}</div>
				<div class="spacer"></div>
				<form class="report-export__form" [formGroup]="form" (ngSubmit)="handleDownloadCsv()">
					<!-- <ng-container *ngIf="filterMonth">
						<div class="report-export__form__filter">
							<label class="report-export__form__filter__label" *ngFor="let item of filter">
								<input
									formControlName="filter"
									name="filter"
									type="radio"
									value="{{ item.title }}"
									class="report-export__form__filter__input"
								/>
								<div class="report-export__form__filter__box">
									<p class="report-export__form__filter__text">{{ item.title }}</p>
								</div>
							</label>
						</div>
					</ng-container> -->
					<div class="report-export__form__filter" *ngIf="filterCardAndRest">
						<label *ngFor="let item of tabRestAndCard" class="report-export__form__filter__label">
							<input
								ngModel
								type="radio"
								id="action"
								value="{{ item.id }}"
								class="report-export__form__filter__input"
								name="venue"
							/>
							<div class="report-export__form__filter__box w151">
								<p class="report-export__form__filter__text">{{ item.title }}</p>
							</div>
						</label>
					</div>
					<ng-container *ngIf="datePeriod">
						<div class="field__label">
							Período Inicial
							<div class="field__wrapper">
								<i class="field__icon left tagmeicon tagmeicon-calendario-24"></i>
								<input
									class="field__input"
									type="date"
									formControlName="startDate"
									name="startDate"
									placeholder="dd/mm/YYYY"
								/>
							</div>
						</div>
						<span class="field__feedback"></span>
						<div class="field__label">
							Período Final
							<div class="field__wrapper">
								<i class="field__icon left tagmeicon tagmeicon-calendario-24"></i>
								<input
									class="field__input"
									type="date"
									placeholder="dd/mm/YYYY"
									formControlName="endDate"
									name="endDate"
								/>
							</div>
						</div>
						<span class="field__feedback"></span>

						<div class="field__label" *ngIf="selectedOption.slug === 'vouchers-bbi'">
							Status
							<select [formControl]="selectVoucherBBIStatus">
								<option value="active" selected>Ativo (Vouchers)</option>
								<option value="used">Outros (Reservas)</option>
							</select>
						</div>
						<div style="height: 30px"></div>

						<div style="display: flex; gap: 16px">
							<button
								*ngIf="selectedOption.preview"
								class="report-export__form__button button"
								type="button"
								(click)="handlePreviewJson()"
								[disabled]="status === 'pending'"
								[ngClass]="status === 'pending' ? 'disabled' : ''"
							>
								Pré-visualizar relatório
							</button>

							<button
								class="report-export__form__button button"
								type="submit"
								[disabled]="status === 'pending'"
								[ngClass]="status === 'pending' ? 'disabled' : ''"
							>
								Download relatório
							</button>
						</div>
					</ng-container>
				</form>
			</div>

			<button
				class="report-export__form__button button"
				style="margin: 25px 0"
				*ngIf="sendEmailButton && report.length > 0"
				(click)="openModalEmail()"
			>
				Enviar e-mails
			</button>

			<div style="border: 1px solid black; border-radius: 8px; padding: 20px" *ngIf="emailSendResult">
				<div *ngIf="emailSendResult">
					<div *ngIf="emailSendResult.failures?.length">
						<h1>Falhas no envio({{ emailSendResult.failures.length }}):</h1>
						<br />
						<ul>
							<li *ngFor="let failure of emailSendResult.failures">
								{{ failure.venueName }}: {{ failure.message }}
							</li>
						</ul>
					</div>
					<br />
				</div>
				<br />
				<div *ngIf="emailSendResult.success?.length">
					<h1>Emails enviados com sucesso({{ emailSendResult.success.length }}):</h1>
					<br />
					<ul>
						<li *ngFor="let success of emailSendResult.success">
							{{ success.venueName }}: {{ success.message }}
						</li>
					</ul>
				</div>

				<div *ngIf="shouldShowRetry()">
					<button
						class="report-export__form__button button"
						style="margin: 25px 0"
						(click)="openRetryModal()"
					>
						Tentar novamente para emails com falha
					</button>
				</div>
			</div>

			<div style="padding: 26px 0" *ngIf="selectedOption.preview">
				<!-- Because we dont have time to fix this, I'm doing two *ngIf instead of one-->
				<app-table
					[benefitsReport]="report"
					*ngIf="selectedOption.slug !== 'vouchers-bbi'"
				></app-table>
				<app-general-table
					[info]="report"
					*ngIf="selectedOption.slug === 'vouchers-bbi'"
				></app-general-table>
				<div *ngIf="report.length === 0 && status === 'resolved'">Zero dados.</div>
			</div>

			<div class="report-export" *ngIf="searchVoucher">
				<div class="report-export__title">Status de voucher</div>
				<form class="report-export__form" #statusVoucher="ngForm">
					<div class="report-export__form__tab">
						<label for="voucher" class="report-export__form__tab__label">
							<input
								type="radio"
								id="voucher"
								class="report-export__form__tab__input"
								name="search"
							/>
							<div
								class="report-export__form__tab__box"
								[ngClass]="searchTab ? 'selected' : ''"
								(click)="searchVoucherTab()"
							>
								<p class="report-export__form__tab__text">Busca por voucher</p>
							</div>
						</label>
						<label for="local" class="report-export__form__tab__label">
							<input
								type="radio"
								id="local"
								class="report-export__form__tab__input"
								name="search"
							/>
							<div
								class="report-export__form__tab__box"
								[ngClass]="!searchTab ? 'selected' : ''"
								(click)="searchLocalTab()"
							>
								<p class="report-export__form__tab__text">Busca por local</p>
							</div>
						</label>
					</div>
					<!-- Aqui são os conteúdos de cada aba, que aparecerá apenas quando selecionado  -->
					<!-- BUSCA POR VOUCHER -->
					<div class="field" *ngIf="searchTab">
						<div class="field__label">
							Código do voucher
							<div class="field__wrapper">
								<i class="field__icon left tagmeicon tagmeicon-voucher"></i>
								<input
									ngModel
									name="codeVoucher"
									class="field__input"
									placeholder="Ex.: V45C0"
									mask="00000"
								/>
							</div>
						</div>
						<span class="field__feedback"></span>
					</div>

					<!-- BUSCA POR LOCAL -->
					<div class="field" *ngIf="!searchTab">
						<div class="field__label">
							ID do restaurante
							<div class="field__wrapper">
								<i class="field__icon left tagmeicon tagmeicon-home"></i>
								<input
									ngModel
									name="idRestaurant"
									class="field__input"
									placeholder="Ex.: S40J4NU"
									mask="0000000"
								/>
							</div>
						</div>
						<span class="field__feedback"></span>
					</div>
					<ng-container *ngIf="searchTab">
						<div class="field" *ngFor="let item of periods">
							<div class="field__label">
								{{ item.title }}
								<div class="field__wrapper">
									<i class="field__icon left {{ item.icon }}"></i>
									<input
										ngModel
										class="field__input"
										name="{{ item.function }}"
										type="date"
										placeholder="25/10/2022"
										mask="00/00/0000"
									/>
								</div>
							</div>
							<span class="field__feedback"></span>
						</div>
					</ng-container>
					<button
						type="submit"
						class="report-export__form__button button"
						(click)="getDataVocuher(statusVoucher.value)"
					>
						Pesquisar
					</button>
				</form>
			</div>
		</div>
	</div>
</div>

<ch-modal [id]="modal.success" top="15%" [preventOutsideClickClose]="true">
	<section class="modal__wrapper">
		<div *ngIf="!isSendingEmail">
			<h1 class="titleModal">Confirma o envio de email com essas informações:</h1>
			<p style="margin-top: 20px">
				<strong>Mês:</strong>{{ monthYear }} <br /><br />
				<strong>Data limite para envio Nota:</strong> {{ dateLimit | date : 'dd/MM/yyyy' }}<br /><br />
				<strong>Data repasse:</strong> {{ endDate }}<br /><br />
				<strong>Total Venues:</strong> {{ report.length }}<br /><br />
			</p>
			<button class="modal__btn" (click)="sendEmails()">Confirmar Envio</button>
			<button class="modal__btn modal__btn--ghost" (click)="closeModal()">Não</button>
		</div>
		<div *ngIf="isSendingEmail">
			<app-loading class="loading" slug="001"></app-loading>
		</div>
	</section>
</ch-modal>

<ch-modal [id]="modal.retry" top="15%" [preventOutsideClickClose]="true">
	<section class="modal__wrapper">
		<div *ngIf="!isSendingEmail">
			<h1 class="titleModal">Confirma o reenvio :</h1>
			<br /><br />
			<div *ngFor="let failure of emailSendResult?.failures">
				<p style="text-align: center" *ngIf="failure?.retry">{{ failure.venueName }}</p>
			</div>
			<button class="modal__btn" (click)="retryFailedEmails()">Confirmar Reenvio</button>
			<button class="modal__btn modal__btn--ghost" (click)="closeModal()">Não</button>
		</div>
		<div *ngIf="isSendingEmail">
			<app-loading class="loading" slug="001"></app-loading>
		</div>
	</section>
</ch-modal>
