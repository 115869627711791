import { Component } from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { HttpStatus } from 'src/app/services/get-async.util'
import { VouchersApi, CreateIuppVoucherDto } from 'src/app/services/vouchers.service'

@Component({
	templateUrl: `single-voucher.page.html`,
	styleUrls: [`single-voucher.page.scss`],
})
export class TagmeSingleVoucherPage {
	form: FormGroup
	status: HttpStatus = 'idle'
	msg = false
	msgError = ''
	vouchers: any = []

	constructor(private vouchersApi: VouchersApi) {
		this.form = new FormGroup({
			name: new FormControl(''),
			email: new FormControl(''),
			phone: new FormControl(''),
			venueId: new FormControl('', Validators.required),
			price: new FormControl('', Validators.required),
			note: new FormControl(''),
		})
	}

	onSubmit() {
		this.msg = true
		this.status = 'pending'
		if (this.form.valid) {
			const createIuppVoucher: CreateIuppVoucherDto = {
				name: this.form.value.name,
				email: this.form.value.email,
				phone: this.form.value.phone.toString(),
				venueId: this.form.value.venueId,
				price: this.form.value.price,
				note: this.form.value.note,
			}

			this.vouchersApi.createIuppVoucher(createIuppVoucher).subscribe({
				next: res => {
					this.status = 'resolved'
					this.vouchers.push(res.voucher)
				},
				error: error => {
					this.status = 'rejected'
					this.msgError = error.error.message
				},
			})
		}
	}

	close() {
		this.msg = false
	}
}
