import { Location } from '@angular/common'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormGroupDirective, UntypedFormGroup } from '@angular/forms'

@Component({
	selector: 'app-modal-partner',
	template: `
		<form class="wrapper" [formGroup]="partnerForm">
			<section class="field">
				<label for="partner"><strong>Selecione o programa: </strong></label>
				<select id="partner" formControlName="partnerId" style="font-size: 16px">
					<option value="595166383625c506d4868f28">Programa Menu</option>
					<option value="63600bcb4a49e96746e30f7d">Esfera</option>
					<option value="643994cb6e7a2ca377e2400c">Latam</option>
					<option value="641c6e6ba05e256a0a2e0da8">Menu Personnalité</option>
					<option value="650ded0e0d58e49d57af264a">Unicred</option>
					<option value="666735bf20415739cd5aa3bd">BB Gastronomia</option>
				</select>
			</section>
			<button class="btn button" (click)="confirmClicked.emit()">Confirma</button>
			<br />
			<button class="btn button__light" (click)="location.back()">Voltar</button>
		</form>
	`,
	styles: [
		`
			.wrapper {
				background-color: white;
				padding: 12px;
				border-radius: 3px;
			}

			.btn {
				width: 100%;
				padding: 8px 0;
				margin-top: 12px;
			}
		`,
	],
})
export class ModalPartnerComponent implements OnInit {
	partnerForm!: UntypedFormGroup
	@Output() confirmClicked = new EventEmitter()
	constructor(public location: Location, private rootFormGroup: FormGroupDirective) {}
	ngOnInit(): void {
		this.partnerForm = this.rootFormGroup.control
	}
}
