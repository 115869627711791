import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-card003',
	templateUrl: './card003.component.html',
	styleUrls: ['./card003.component.scss'],
})
export class Card003Component implements OnInit {
	@Input() nameCards: string = ''
	@Input() iconCards: string = ''

	constructor() {}

	ngOnInit(): void {}
}
