<div class="page reservation">
	<app-header-master *ngIf="isAdmin"></app-header-master>
	<app-header-venue
		*ngIf="(venue$ | async) as venue"
		[name]="venue.name"
		(onClick)="selectVenue()"
	></app-header-venue>
	<div class="page__body">
		<div class="wrapper reservation__wrapper" (submit)="handleSubmit()">
			<h2 class="reservation__title">Filtro</h2>
			<div class="spacer"></div>
			<form [formGroup]="orderForm" class="reservation__form">
				<div class="reservation__column">
					<section class="reservation__field">
						<label for="order_status"><strong>Status da order: </strong></label>
						<select id="order_status" formControlName="status">
							<option value="CHK_DONE">CHK_DONE</option>
							<option value="VALIDATED">VALIDATED</option>
						</select>
					</section>
					<section class="reservation__field">
						<label for="order_intent"><strong>Intenção da order: </strong></label>
						<select id="order_intent" formControlName="intent">
							<option value="reservation">Reserva</option>
							<option value="checkin">Check-in</option>
						</select>
					</section>
				</div>
				<div class="reservation__column">
					<section class="reservation__field">
						<label for="date"><strong>Data início: </strong></label>
						<input
							id="date"
							class="reservation__field__input"
							type="date"
							name="data"
							formControlName="startDate"
						/>
					</section>
					<section class="reservation__field">
						<label for="date"><strong>Data fim: </strong></label>
						<input
							id="date"
							class="reservation__field__input"
							type="date"
							name="data"
							formControlName="endDate"
						/>
					</section>
				</div>
				<div class="reservation__column">
					<section class="reservation__field">
						<label for="channels"><strong>Parceiro: </strong></label>
						<select id="channels" formControlName="partnerId">
							<option value="595166383625c506d4868f28">Bradesco</option>
							<option value="63600bcb4a49e96746e30f7d">Esfera</option>
						</select>
					</section>
					<section class="reservation__field">
						<label for="cpf"><strong>CPF: </strong></label>
						<input
							id="cpf"
							type="text"
							name="cpf"
							formControlName="cpf"
							class="reservation__field__input"
							[ngStyle]="{width: '150px'}"
						/>
					</section>
				</div>
				<div class="reservation__column">
					<button type="submit" class="reservation__submit">Pesquisar</button>
				</div>
			</form>

			<div class="spacer"></div>

			<h2 class="reservation__title">Orders</h2>
			<article class="reservation__orders">
				<div class="" *ngIf="orders.length"></div>
			</article>
		</div>
	</div>
</div>

<ch-modal [id]="modalSearchId" top="150px" [preventOutsideClickClose]="true">
	<ng-container [formGroup]="searchForm">
		<app-modal-venue
			[hasVenues]="hasVenues"
			[venues]="venues"
			[lastVenues]="lastVenues$ | async"
			(onCloseModal)="closeModal()"
			(onClickVenue)="clickVenues($event)"
			(onEraseHistory)="eraseHistory()"
		></app-modal-venue>
	</ng-container>
</ch-modal>
