<div class="page">
	<app-header-master></app-header-master>

	<div class="page__body">
		<div class="page__wrapper wrapper">
			<app-back-button [addClass]="'element'"></app-back-button>

			<div class="concierge-admin">
				<h2 class="concierge-admin__title">Programa Menu Bradesco</h2>

				<div class="concierge-admin__box">
					<app-accordion slug="002" [name]="'Gestão de Concierge'" [tempAccordion]="tempAccordion">
						<ng-template #tempAccordion>
							<div class="concierge-admin__grid">
								<div class="concierge-admin__column">
									<app-concierge001
										slug="001"
										[userName]="item.name"
										[userTel]="item.value"
										[userEmail]="item.description"
										[userPass]="item.type"
										[userId]="item._id"
										*ngFor="let item of userInfosConcierge"
									></app-concierge001>
								</div>
							</div>
							<app-loading *ngIf="loading" class="loading" slug="001"></app-loading>
							<button
								class="concierge-admin__button button__light"
								(click)="createConciergeAdmin()"
							>
								Adicionar novo concierge
							</button>
						</ng-template>
					</app-accordion>
				</div>
			</div>
		</div>
	</div>
</div>
