<div class="payment-refund-modal">
	<strong>Deseja estornar este pagamento?</strong>
	<div class="payment-refund-modal__warn">Essa operação não poderá ser desfeita.</div>
	<div style="display: flex; flex-direction: column; gap: 10px; margin-top: 35px">
		<button
			class="payment-refund-modal__btn payment-refund-modal__btn--yes"
			(click)="yes()"
			[disabled]="status === 'pending'"
		>
			Sim
		</button>

		<button class="payment-refund-modal__btn" (click)="goBack()" [disabled]="status === 'pending'">
			Voltar
		</button>
	</div>
</div>
