<div class="page">
	<app-header-master *ngIf="isAdminUser"></app-header-master>
	<app-header-venue
		*ngIf="venue$ | async as venue"
		[name]="venue.name"
		[showIcon]="false"
	></app-header-venue>
	<div class="page__body">
		<div class="wrapper">
			<div class="venue-payment-body__breadcrumb">
				<app-breadcrumb></app-breadcrumb>
			</div>
			<div class="venue-payment-body">
				<app-payment-filter-details
					[channels]="channels"
					(daysChanged)="setOrder($event)"
					(filterChanged)="setFilter($event)"
					[isAnyCardSelected]="isAnyCardSelected"
					[totalSelectedValue]="totalSelectedValue"
					(onPrintClick)="openPrintModalForSelectedOrders()"
					(onDetailsClick)="openDetailsModalForSelectedOrders()"
				></app-payment-filter-details>
				<hr class="venue-payment-body__separator venue-payment-body__separator--full-width" />
				<ng-container *ngIf="statusOrder === 'resolved'">
					<div style="display: flex; flex-direction: column; gap: 10px">
						<app-payment-card
							*ngFor="let order of filteredOrders"
							(refundClicked)="openModalRefund($event)"
							(printClicked)="openModalPrint($event)"
							(gratuityClicked)="openModalGratuityValue($event)"
							[order]="order"
							(selectionChanged)="onCardSelectionChanged(order._id, $event)"
						></app-payment-card>
					</div>
					<div *ngIf="!filteredOrders?.length">Nenhum resultado encontrado.</div>
				</ng-container>
				<div *ngIf="statusOrder === 'pending'">Carregando...</div>
				<div *ngIf="statusOrder === 'rejected'">{{ errorOrder }}</div>
			</div>
		</div>
	</div>
</div>

<ch-modal [id]="modalGratuityId" top="15%" #modalGratuity>
	<div class="payment-gratuity-modal">
		<strong>
			Tem certeza que deseja {{selectedOrderToUpdateGratuityValue?.removeGratuityValue ? 'restaurar' :
			'remover'}} a taxa de serviço?
		</strong>
		<div style="display: flex; flex-direction: column; gap: 10px; margin-top: 35px">
			<button
				class="payment-gratuity-modal__btn payment-gratuity-modal__btn--yes"
				(click)="updateGratuityValue()"
				[disabled]="statusGratuity === 'pending'"
			>
				Sim
			</button>
			<button
				class="payment-gratuity-modal__btn"
				(click)="modalGratuity.close()"
				[disabled]="statusGratuity === 'pending'"
			>
				Voltar
			</button>
		</div>
	</div>
</ch-modal>

<ch-modal [id]="modalRefundId" top="15%">
	<app-payment-refund-modal
		[status]="statusRefund"
		(yesClicked)="refundPayemnt()"
		(goBackClicked)="closeModalRefund()"
	></app-payment-refund-modal>
</ch-modal>

<ch-modal [id]="modalPrintId" top="70px">
	<app-payment-print
		(closeClicked)="closeModalPrint()"
		[orders]="selectedOrdersToPrint"
	></app-payment-print>
</ch-modal>

<ch-modal [id]="modalDetailsId" top="70px">
	<app-payment-details
		(closeClicked)="closeModalDetails()"
		[orders]="selectedOrdersToPrint"
	></app-payment-details>
</ch-modal>
