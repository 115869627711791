import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { OrderPayment } from 'src/app/interfaces/order.interface' // Ajuste o caminho conforme necessário
import { getTime } from 'src/app/services/date.util'

@Component({
	selector: 'app-payment-print',
	templateUrl: './payment-print.component.html',
	styleUrls: ['./payment-print.component.scss'],
})
export class PaymentPrintComponent implements OnInit {
	@Input() orders: OrderPayment[] = []

	@Output() closeClicked = new EventEmitter()

	constructor() {}

	ngOnInit(): void {}

	print() {
		window.print()
	}

	close() {
		this.closeClicked.emit()
	}

	getTaxes(payment: any) {
		return (payment?.taxes + payment?.retentionValue) / 100
	}

	getLastPayment(order: OrderPayment) {
		const payment = order.payment
		return Array.isArray(payment) ? payment[payment.length - 1] : payment
	}

	getPaidAt(order: OrderPayment) {
		const lastPayment = this.getLastPayment(order)
		return getTime(lastPayment?.paidAt ?? '')
	}
}
